import { Formik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Grid, Loader } from 'semantic-ui-react';
import CustomContainer from '../../components/CustomContainers/customContainer';
import { CustomTextArea } from '../../components/forms/inputs/customTextArea';
import { changePassword } from '../../store/modules/auth';
import { getAccountInformation, patchUserData } from '../../store/modules/user';
import {
  settingsInitialUserData,
  userSettingsValidationSchema,
} from '../../validations/users/user-settings-Validation';
import './settings.scss';
import PasswordChange from './passwordChange';
import { CustomToggle } from '../../components/customToggle';
import { CustomInput } from '../../components/forms/inputs/customInput';

export const Account = (props) => {
  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.auth.user);
  const patchLoading = useSelector((state) => state.user.userPatchLoading);

  useEffect(() => {
    if (userDetails !==undefined && userDetails.id) {
      dispatch(getAccountInformation(userDetails.id));
    }
  }, [dispatch, userDetails]);

  const accountInformation = useSelector(
    (state) => state.user.getAccountInformation
  );

  const onHandleValidAccountUpdate = (values) => {
    try {
      const newValues = {
        address: values.address,
        isUnsubscribed:values.isUnsubscribed
      };
      
      dispatch(patchUserData(accountInformation.id, newValues));
    } catch (err) {
      console.error('Error in updating settings', err);
    }
  };

  const handleValidSubmit = (values) => {
    try {
      const form = {
        oldPassword: values.currentPassword,
        newPassword: values.password,
      };
      dispatch(changePassword(form));
    } catch (error) {
      console.error.log('PassChangeForm ~ handleValidSubmit ~ error', error);
    }
  };

 

  if(accountInformation ===undefined) return <Loader/>
  return (
    <Grid className="auth" stackable>
      <Grid.Column mobile={16} tablet={16} computer={8} className="auth-column">
        <CustomContainer className="left-cont">
          <h3
            className="acc-sett-header"
            style={{ marginTop: '15px', marginLeft: '10px' }}
          >
            Account Information
          </h3>

          <Formik
            onSubmit={(values) => {
              onHandleValidAccountUpdate(values);
            }}
            initialValues={settingsInitialUserData(accountInformation)}
            validationSchema={userSettingsValidationSchema}
          >
            {({ handleSubmit, errors, isSubmitting }) => (
              <Form onSubmit={handleSubmit}>
                <div className="settings-accounts">
                  <Grid>
                    <Grid.Row>
                      <Grid.Column mobile={16} tablet={8} computer={8}>
                        <div className="existing-user-partner">
                          <div className="formik-custom-field act-inputs-new">
                          <CustomInput
                        id="name"
                        name="name"
                        labelText="Name"
                        disabled
                      />
                          </div>
                        </div>
                      </Grid.Column>

                      <Grid.Column mobile={16} tablet={8} computer={8}>
                        <div className="existing-user-partner">
                          <div className="formik-custom-field act-inputs-new">
                          <CustomInput
                        id="email"
                        name="email"
                        labelText="Email Address"
                        disabled
                      />
                          </div>
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="account-row">
                      <Grid.Column mobile={16} tablet={8} computer={8}>
                        <div className="existing-user-partner">
                          <div className="formik-custom-field act-inputs-new">
                          <CustomInput
                        id="contactNo"
                        name="contactNo"
                        labelText="Contact Number"
                        disabled
                      />
                          </div>
                        </div>
                      </Grid.Column>
                      <Grid.Column mobile={16} tablet={8} computer={8}>
                        <div className="existing-user-partner">
                          <div className="formik-custom-field act-inputs-new">
                            <CustomInput
                        id="abn"
                        name="abn"
                        labelText="ABN"
                        disabled
                      />
                          
                          </div>
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="account-row">
                      <Grid.Column mobile={16} tablet={16} computer={16}>
                        <div className="custom-text-area">
                          <div className="formik-custom-field act-inputs-new">
                            <CustomTextArea
                              id="address"
                              name="address"
                              className="txt-description"
                              placeholder="Enter Address"
                              labelText="Address"
                            />
                          </div>
                        </div>
                      </Grid.Column>
                      
                      
                    </Grid.Row>
                    <Grid.Row>
                      <Grid.Column width={16}>
                        <div className="dropdown-customer-custom">
                        <CustomToggle 
                            id='isUnsubscribed'
                            name='isUnsubscribed'
                            rearLabelText="Unsubscribe email service"
                        />
                        </div>
                      </Grid.Column>
                      <Grid.Column width={16}>
                      <div className="button-down">
                        <Button
                          disabled={patchLoading}
                          loading={patchLoading}
                          type="submit"
                          className="acc-update-button"
                        >
                          Update
                        </Button>
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                   
                  </Grid>
                </div>
              </Form>
            )}
          </Formik>
        </CustomContainer>
      </Grid.Column>
      <Grid.Column mobile={16} tablet={16} computer={8} className="auth-column">
        <CustomContainer>
          <h3
            className="acc-sett-header"
            style={{ marginTop: '15px', marginLeft: '10px' }}
          >
            Change Password
          </h3>

          <div className="settings-accounts">
            <PasswordChange handleValidSubmit={handleValidSubmit} />
          </div>
        </CustomContainer>
      </Grid.Column>
    </Grid>
  );
};

export default Account;
