import * as Yup from 'yup';
import { find, get, isEmpty } from '../../utils/lodash';

export const complianceInitialValues = {
  chassisNo: '',
  vehicleArrivedDate: '',
  engineNo: '',
  transmissionType: '',
  make: '',
  model: '',
  odometerReading: '',
  noOfDoors: '',
  totalNoOfSeats: '',
  notes: '',
  frontTyreSize: '',
  frontTyreLoad: '',
  rearTyreSize: '',
  rearTyreLoad: '',
  approvalDocument: '',
};

export const confirmComplianceValue = (responses) => {
  if (!responses) {
    return {
      chassisNo: '',
      vehicleArrivedDate: '',
      engineNo: '',
      transmissionType: '',
      make: '',
      model: '',
      odometerReading: '',
      noOfDoors: '',
      totalNoOfSeats: '',
      notes: '',
      frontTyreSize: '',
      frontTyreLoad: '',
      rearTyreSize: '',
      rearTyreLoad: '',
      approvalDocument: '',
    };
  }

  return {
    chassisNo: responses.chassisNo || '',
    vehicleArrivedDate: responses.vehicleArrivedDate || '',
    engineNo: responses.engineNo || '',
    transmissionType: responses.transmissionType || '',
    make: responses.make || '',
    model: responses.model || '',
    odometerReading: responses.odometerReading || '',
    noOfDoors: responses.noOfDoors || '',
    totalNoOfSeats: responses.totalNoOfSeats || '',
    notes: responses.notes || '',
    frontTyreSize: responses.frontTyreSize || '',
    frontTyreLoad: responses.frontTyreLoad || '',
    rearTyreSize: responses.rearTyreSize || '',
    rearTyreLoad: responses.rearTyreLoad || '',
    approvalDocument: responses.approvalDocument || '',
  };
};

export const complianceValidationSchema = Yup.object({
  chassisNo: Yup.string().required('Chassis No is required'),
  vehicleArrivedDate: Yup.date().required('Vehicle Arrived Date is required'),
  engineNo: Yup.string().required('Engine No is required'),
  transmissionType: Yup.string().required('Transmission Type is required'),
  make: Yup.string().required('Make is required'),
  model: Yup.string().required('Model is required'),
  odometerReading: Yup.string().required('Odometer Reading is required'),
  noOfDoors: Yup.string().required('No Of Doors is required'),
  totalNoOfSeats: Yup.string().required('Total No Of Seats is required'),
  notes: Yup.string().max(100, 'Notes must be at most 100 words'),
  frontTyreSize: Yup.string().required('Front Tyre Size is required'),
  frontTyreLoad: Yup.string().required('Front Tyre Load is required'),
  rearTyreSize: Yup.string().required('Rear Tyre Size is required'),
  rearTyreLoad: Yup.string().required('Rear Tyre Load is required'),
  approvalDocument: Yup.mixed().required('Approval document is required'),
});

export const updateComplianceValidationSchema = (responses,comments=[]) => {
  const chassisNo = find(responses, (item) => item.attributeId === 16);
  const vehicleArrivedDate = find(responses, (item) => item.attributeId === 17);
  const engineNo = find(responses, (item) => item.attributeId === 18);
  const transmissionType = find(responses, (item) => item.attributeId === 19);
  const make = find(responses, (item) => item.attributeId === 20);
  const model = find(responses, (item) => item.attributeId === 21);
  const odometerReading = find(responses, (item) => item.attributeId === 22);
  const noOfDoors = find(responses, (item) => item.attributeId === 23);
  const totalNoOfSeats = find(responses, (item) => item.attributeId === 24);
  const notes = find(responses, (item) => item.attributeId === 25);
  const frontTyreSize = find(responses, (item) => item.attributeId === 26);
  const frontTyreLoad = find(responses, (item) => item.attributeId === 27);
  const rearTyreSize = find(responses, (item) => item.attributeId === 28);
  const rearTyreLoad = find(responses, (item) => item.attributeId === 29);
  const approvalDocument = find(responses, (item) => item.attributeId === 30);
  return {
    chassisNo: get(chassisNo, 'attributeValue', ''),
    vehicleArrivedDate: get(vehicleArrivedDate, 'attributeValue', ''),
    engineNo: get(engineNo, 'attributeValue', ''),
    transmissionType: get(transmissionType, 'attributeValue', ''),
    make: get(make, 'attributeValue', ''),
    model: get(model, 'attributeValue', ''),
    odometerReading: get(odometerReading, 'attributeValue', ''),
    noOfDoors: get(noOfDoors, 'attributeValue', ''),
    totalNoOfSeats: get(totalNoOfSeats, 'attributeValue', ''),
    notes: get(notes, 'attributeValue', ''),
    frontTyreSize: get(frontTyreSize, 'attributeValue', ''),
    frontTyreLoad: get(frontTyreLoad, 'attributeValue', ''),
    rearTyreSize: get(rearTyreSize, 'attributeValue', ''),
    rearTyreLoad: get(rearTyreLoad, 'attributeValue', ''),
    approvalDocument: get(approvalDocument, 'multipleValues', ''),
    comments: !isEmpty(comments) ? comments[0].commentText : ''
  };
};
