import React from 'react';
import { Grid, GridColumn, Loader } from 'semantic-ui-react';
import RecentApplicationList from '../containers/applications/recentApplicationList';
import { isMobile } from 'react-device-detect';
import ViewApplications from '../containers/applications/viewApplications';
import { SelectApplicationContainer } from './emptyContainer';
import { isEmpty } from '../utils/lodash';

export const RenderDashboardApplicationsView = (
  loading = false,
  applications = [],
  onApplicationClicked = null,
  getApplicationsRedux,
  mobileClick = false,
  selectedApp = null,
  applicationViewLoading=false,
  ) => {
  return (
    <>
        <GridColumn className="dashboard-application-list-css" computer={5} tablet={16} mobile={16}>
          <RecentApplicationList
            loading={loading}
            applicationData={applications}
            onApplicationClicked={onApplicationClicked}
            getApplicationsFunctionProp={getApplicationsRedux}
            dashboard
          />
        </GridColumn>
      {(!isMobile || mobileClick) && (
        <GridColumn className="dashboard-application-view-css" computer={11} tablet={16} mobile={16}>
          {isEmpty(selectedApp) ? (
            <SelectApplicationContainer
              classes={`no-data`}
              msg={'Select a latest application in the left panel to view it.'}
            />
          ) : (
            <>
              {applicationViewLoading ?<Grid centered><Loader  active/></Grid>:
            <ViewApplications selectedApp={selectedApp} />
          }
            </>
          )}
        </GridColumn>
      )}
    </>
  );
};

export const RenderApplicationPageApplications = (
  onApplicationClicked = null,
  filterObject = {},
  applications = [],
  loading,
  getAllApplicationsRedux,
  mobileClick = false,
  selectedApp = false,
  applicationViewLoading=false
) => {
  return (
    <>
        <GridColumn className="dashboard-application-list-css" computer={5} tablet={16} mobile={16}>
          <RecentApplicationList
            onApplicationClicked={onApplicationClicked}
            applicationLayout
            status={filterObject.status}
            type={filterObject.type}
            applicationData={applications}
            loading={loading}
            page={filterObject.page}
            pageChange={filterObject.pageChange}
            getApplicationsFunctionProp={getAllApplicationsRedux}
          />
        </GridColumn>

      {(!isMobile || mobileClick) && (
        <GridColumn className="dashboard-application-view-css" computer={11} tablet={16} mobile={16}>
          {selectedApp === null ? (
            <SelectApplicationContainer
              classes={`no-data`}
              msg={'Select a latest application in the left panel to view it.'}
            />
          ) : (
            <>
            {applicationViewLoading ?<Grid centered><Loader  active/></Grid>:
           <ViewApplications selectedApp={selectedApp} />
        }
          </>
          )}
        </GridColumn>
      )}
    </>
  );
};
