import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import {
  Grid,
  GridRow,
  GridColumn,
  Label,
  Icon,
  Button,
} from 'semantic-ui-react';
import DeletionModal from '../modals/deletionModal';

const FileUploadComponent = ({
  handleFileUpload = null,
  document,
  uploadDocumentInputRef = null,
  handleDocumentDelete,
  error = '',
  requiredStar = false,
  label,
  ...props
}) => {
  const [, meta, helpers] = useField(props);
  const { setValue } = helpers;

  const [deletionOpen, setDeletionOpen] = useState(false);

  useEffect(() => {
    if (document) {
      setValue(document);
    } else {
      setValue('');
    }
  }, [document, setValue]);

  const handleUploadDocumentButtonClick = () => {
    if (uploadDocumentInputRef.current) {
      uploadDocumentInputRef.current.click();
    }
  };

  const handleReplaceButtonClick = () => {
    if (uploadDocumentInputRef.current) {
      uploadDocumentInputRef.current.click();
    }
  };

  const handelDeletionClose = () => {
    setDeletionOpen(false);
  };

  const handelDeletion = () => {
    setDeletionOpen(false);
    handleDocumentDelete();
  };

  const handelDeletionModel = () => {
    setDeletionOpen(true);
  };

  const renderDocumentPreview = () => {
    if (document && document.file) {
      const objectUrl = URL.createObjectURL(document.file);
      return (
        <div className="document-preview-container" key={objectUrl}>
          <embed
            src={objectUrl}
            type="application/pdf"
            width="180"
            height="180"
            key={objectUrl}
          />
          <div className="document-actions">
            <Label
              className="document-delete-button"
              onClick={handelDeletionModel}
            >
              <Icon name="trash alternate outline" />
            </Label>
          </div>
        </div>
      );
    }
    return null;
  };

  // Determine the container class based on whether a document has been uploaded
  const containerClass = document
    ? 'vehicle-approval-container has-document'
    : 'vehicle-approval-container';

  return (
    <>
      <DeletionModal
        open={deletionOpen}
        header={`Delete ${label} Vehicle document`}
        content={`Are you sure want to delete this ${label} document?`}
        close={handelDeletionClose}
        confirmation={handelDeletion}
      />

      <GridColumn>
        <Grid>
          <GridRow
            columns={1}
            style={{ paddingTop: '35px', paddingBottom: '0px' }}
          >
            <GridColumn>
              <label className={requiredStar ? 'required-stars' : ''}>
                Existing Vehicle import approval? -
                <span className="small-label-gray">
                  {' '}
                  (Only add if obtained outside of Import revolution){' '}
                </span>
              </label>
            </GridColumn>
          </GridRow>
          <GridRow columns={1}>
            <GridColumn width={16}>
              <input
                type="file"
                accept=".pdf"
                style={{ display: 'none' }}
                ref={uploadDocumentInputRef}
                onChange={handleFileUpload}
              />
              <div className="import-approval-layout">
                <div className={containerClass}>
                  {document ? (
                    renderDocumentPreview()
                  ) : (
                    <div className="choose-button">
                      <Grid>
                        <GridColumn>
                          <GridRow className="upload-icon">
                            <Icon name="upload" />
                          </GridRow>
                          <GridRow
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <Button
                              onClick={handleUploadDocumentButtonClick}
                              secondary
                            >
                              Choose File
                            </Button>
                          </GridRow>
                          <GridRow
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <label className="small-label-gray">
                              Max Size 5MB.
                            </label>
                          </GridRow>
                          <GridRow
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                            }}
                          >
                            <label className="small-label-gray">
                              Supports JPG, JPEG, PNG and PDF
                            </label>
                          </GridRow>
                        </GridColumn>
                      </Grid>
                    </div>
                  )}
                </div>
              </div>
            </GridColumn>
          </GridRow>
          {error && (
            <GridRow>
              <GridColumn>
                <GridRow columns={1}>
                  <GridColumn>
                    <div style={{ color: 'red', marginTop: '10px' }}>
                      {error}
                    </div>
                  </GridColumn>
                </GridRow>

                {meta.touched && meta.error && (
                  <div className="invalid-feedback">{meta.error}</div>
                )}
              </GridColumn>
            </GridRow>
          )}
          {document && (
            <GridRow columns={1} style={{ paddingTop: '0px' }}>
              <GridColumn >
                <div className="replace-button">
                  <label
                    className="label-blue"
                    onClick={handleReplaceButtonClick}
                  >
                    <i className="sync icon"></i>
                    Replace
                  </label>
                </div>
              </GridColumn>
            </GridRow>
          )}
        </Grid>
      </GridColumn>
    </>
  );
};

export default FileUploadComponent;
