import React from 'react';
import ForgotPassRightContent from '../../assets/images/ForgotPassRight.png';
import SignInRightContent from '../../assets/images/SignInRight.png';
import '../styles.scss';
const RightSideContent = ({ signIn = false }) => {
  return (
    <div className="right-image-container">
      <img
        src={signIn ? SignInRightContent : ForgotPassRightContent}
        alt="Profile"
        className="right-image"
      />
    </div>
  );
};

export default RightSideContent;
