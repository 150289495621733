import React from 'react';
import { Button, Container, Grid, Icon } from 'semantic-ui-react';
import { Link, useParams } from 'react-router-dom';
import { CustomInput } from '../../components/forms/inputs/customInput';
import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import './auth.scss';
import { resetPasswordValidationSchema } from '../../validations/password/resetPasswordValidation';
import { useDispatch, useSelector } from 'react-redux';
import { resetPassword } from '../../store/modules/auth';
const ResetPassword = () => {
  const navigate = useNavigate();
  
  const [isShownPass, setIsShownPass] = React.useState(false);
  const [isShownConfirmPass, setIsShownConfirmPass] = React.useState(false);

  const loading= useSelector((state)=>state.auth.resetPassLoading);
  const dispatch=useDispatch()
  const {id}=useParams();
const handleSubmit=async(val)=>{
  try {
    const newValues={
      newPassword:val.password,
      resetToken:id
    };
    await dispatch(resetPassword(newValues));
    navigate(`/`);
  } catch (err) {
    
  }
}

  return (
    <Grid.Row className="auth">
      <Grid.Column mobile={16} tablet={16} computer={8} />
      <Grid.Column mobile={16} tablet={16} computer={8}>
        <Container className="right-heading-container">
          {/* <div className="heading-row"> */}
          <label className="headerText">Reset Password</label>
          <label className="headerDescription">Set your new password so you can login and access your account</label>
          <br></br>
          <Formik
          initialValues={{ password: '',confirmPassword:'' }}
          validationSchema={resetPasswordValidationSchema}
          onSubmit={handleSubmit}
          >
            {({handleSubmit }) => (
            <Form onSubmit={handleSubmit} className={'form-group-conatiner'}>
                  <div className="formik-custom-field-reset act-inputs-new">
                    <CustomInput
                      id="password"
                      name="password"
                      placeholder="New Password"
                      labelText="New Password"
                      type={isShownPass ? 'text' : 'password'}
                      icon={
                        <Icon
                          name={isShownPass ? 'eye' : 'eye slash'}
                          onClick={() => {
                            setIsShownPass(!isShownPass);
                          }}
                          link
                        />
                      }
                    />
                  </div>

                  <div className="formik-custom-field-reset act-inputs-new">
                    <CustomInput
                      id="confirmPassword"
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      labelText="Confirm Password"
                      type={isShownConfirmPass ? 'text' : 'password'}
                      icon={
                        <Icon
                          name={isShownConfirmPass ? 'eye' : 'eye slash'}
                          onClick={() => {
                            setIsShownConfirmPass(!isShownConfirmPass);
                          }}
                          link
                        />
                      }
                    />
                  </div>
              <Button
                content={'  Reset Password'}
                type="submit"
                className="btn btn-primary signin-button"
                loading={loading}
                disabled={loading}
              />
              <Link to={'/'}>
                <label className={'goBackSignIn'}>Go back to Sign In</label>
              </Link>
            </Form>
           )}
          </Formik>
        </Container>
      </Grid.Column>
      <Grid.Column mobile={16} tablet={5} computer={5} />
    </Grid.Row>
  );
};

export default ResetPassword;
