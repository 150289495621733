import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import {
    Button,
    Form,

  } from 'semantic-ui-react';
import { customerUpdateInitialValues, updateCustomerValidation } from '../../../validations/customers/updateCustomerValidation';
import { createCustomerInitialValues, createCustomerValidation } from '../../../validations/customers/createCustomerValidation';
import CreateCustomer from '../../sideBar/sideBarItems/customerCreateForm';
import UpdateCustomer from '../../sideBar/sideBarItems/customerUpdateForm';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from '../../../utils/lodash';
import { postCustomer, updateCustomer } from '../../../store/actions';
import { isMobile } from 'react-device-detect';

export const CustomerForm=({
    sideBar=false,
    updateSelectedCustomer=false,
    closeSideBar=null,
    shouldValidate=false,
    setSideBarFunction=null,

})=>{
    const dispatch = useDispatch();
    const [customerData, setSelectedCustomer] = useState('');
    const createCustomer = useSelector(
      (state) => state.clientSide.isOpenCustomerSidebar
    );
    const createCustomerDashboard = useSelector(
      (state) => state.clientSide.isOpenDashboardSidebar
    );

    const customerPutLoading = useSelector(
      (state) => state.customers.customerPutLoading
    );
  
    const selectedCustomer = useSelector(
        (state) => state.clientSide.selectedCustomer
      );
    
      useEffect(() => {
        if (!isEmpty(selectedCustomer)) {
          setSelectedCustomer(selectedCustomer);
        }
      }, [selectedCustomer]);


      useEffect(() => {
        if (createCustomer || createCustomerDashboard) {
          setSelectedCustomer('');
        }
        if(setSideBarFunction!==null){
         setSideBarFunction(createCustomer||createCustomerDashboard);

        }

      }, [createCustomer,createCustomerDashboard,setSideBarFunction]);
    
      useEffect(() => {
        if (!isEmpty(updateSelectedCustomer.payload) && updateSelectedCustomer) {
          setSelectedCustomer(updateSelectedCustomer.payload);
        }
        if(updateSelectedCustomer){
          if(setSideBarFunction!==null){
            setSideBarFunction(updateSelectedCustomer.payload);

          }
        }
      }, [updateSelectedCustomer,setSideBarFunction]);


      const onSubmit = async (values) => {
        try {
          if (sideBar || isMobile) {
            if (updateSelectedCustomer) {
              await dispatch(updateCustomer(customerData.id, values));
              closeSideBar();
            } else {
              delete values.abn;
              await dispatch(postCustomer(values));
              closeSideBar();
            }
          }
        } catch (err) {
          console.error('Error in posting/updating, ', err);
        }
      };

      const initialValues = createCustomer
      ? createCustomerInitialValues()
      : customerUpdateInitialValues(customerData);

return(
    <Formik
    key={JSON.stringify(customerData) + createCustomer}
    onSubmit={(values) => {
      onSubmit(values);
    }}
    initialValues={initialValues}
    validationSchema={
      shouldValidate
        ? updateSelectedCustomer
          ? updateCustomerValidation
          : createCustomerValidation
        : null
    }
  >
    {({ handleSubmit }) => {
      return (
        <Form onSubmit={handleSubmit}>
          <div className="filter-body">
            {updateSelectedCustomer ? (
             <UpdateCustomer/>
            ) : (
             <CreateCustomer/>
            )}
          </div>
          <div className={isMobile ? "filter-footer-mobile" : "filter-footer"}>
            <Button
              content={'Cancel'}
              type="close"
              className="btn btn-primary cancel-button"
              onClick={closeSideBar}
            />
            <Button
              content={
                updateSelectedCustomer
                  ? 'Update Customer'
                  : 'Create Customer'
              }
              type="submit"
              className="btn btn-primary create-customer-button"
              loading={customerPutLoading}
            />
          </div>
        </Form>
      );
    }}
  </Formik>
)
}