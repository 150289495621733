import React, { useState } from 'react';
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  Dropdown,
  Grid,
  GridColumn,
  GridRow,
  Icon,
  Image,
  Segment,
} from 'semantic-ui-react';
import './styles.scss';
import '../containers/admin/dashboard/dashboard.scss';
import { useDispatch } from 'react-redux';
import { openDashboardSideBar } from '../store/modules/clientSide';
import { get } from '../utils/lodash';
import { getDates, getMsg } from '../utils';
import moment from 'moment';

const CardBoxComponent = ({
  isFilter = false,
  widgetData,
  title,
  setVisible = null,
  isAddNewButton = false,
  logo = '',
  userDetails,
  getDateValues,
  loading = false,
}) => {
  const dispatch = useDispatch();
  const [value, setValue] = useState('thisWeek');
  const [, setStartDate] = useState(
    moment().startOf('isoWeek').format('YYYY-MM-DD HH:mm:ss')
  );
  const [, setEndDate] = useState(
    moment().endOf('isoWeek').format('YYYY-MM-DD HH:mm:ss')
  );

  const handleAddNewClick = () => {
    dispatch(openDashboardSideBar(true));
  };

  const durationOption = [
    {
      key: 'thisWeek',
      value: 'thisWeek',
      text: 'This Week',
    },
    {
      key: 'lastMonth',
      value: 'lastMonth',
      text: 'Last Month',
    },
    {
      key: 'today',
      value: 'today',
      text: 'Today',
    },
  ];

  const onChange = (e, data) => {
    setValue(data.value);
    const date = getDates(data.value);
    const updateStartDate = date.formattedStartDate;
    const updateEndDate = date.formattedEndDate;

    // Use functional form of setState to ensure you are working with the latest state values
    setStartDate((prevStartDate) => {
      setEndDate((prevEndDate) => {
        getDateValues(data.value, {
          startDate: updateStartDate,
          endDate: updateEndDate,
        });
        return updateEndDate;
      });
      return updateStartDate;
    });
  };


  return (
    <div data-testid="card-box-component" className="override-semantic-card">
      <Card className="dashboard-card-content">
        <CardContent>
          <CardHeader>
            <Grid>
              <GridRow columns={2} width={16}>
                <GridColumn
                  width={2}
                  style={{
                    paddingLeft: '5px',
                    paddingRight: '5px',
                    paddingTop: '1px',
                  }}
                >
                  <div className="card-image-icon">
                    <Image
                      rounded
                      size="mini"
                      src={logo}
                      //className="fixed-image-size"
                    />
                  </div>
                </GridColumn>

                {isFilter ? (
                  <GridColumn width={14}>
                    <Grid>
                      <GridRow>
                        <GridColumn
                          width={4}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                            paddingLeft: '0px',
                          }}
                        >
                          <label className="title-card">{title}</label>
                        </GridColumn>
                        <GridColumn
                          computer={12}
                          mobile={12}
                          style={{ display: 'flex', justifyContent: 'end' }}
                        >
                          {/* {!isMobile ? ( */}
                            <div className="duration-drop-down">
                              <Dropdown
                                options={durationOption}
                                value={value}
                                onChange={(e, data) => {
                                  onChange(e, data);
                                }}
                              />
                            </div>
                          {/* ) : null} */}
                        </GridColumn>
                      </GridRow>
                    </Grid>
                  </GridColumn>
                ) : (
                  <GridColumn width={14} style={{ paddingLeft: '0px' }}>
                    <div className="column-based-text">
                      <label className="card-header-text">{getMsg()}</label>
                      <label className="card-header-sub-text">
                        Welcome back {get(userDetails, 'name', '')}!
                      </label>
                    </div>
                  </GridColumn>
                )}
              </GridRow>
            </Grid>
          </CardHeader>
          <CardDescription>
            <Segment className="content-segment-custom" loading={loading}>
              <div className="description-box">
                {widgetData.map((item, index) => {
                  return (
                    <div key={index} className="column-date">
                      <label className="card-header-sub-text">
                        {' '}
                        {item.title}{' '}
                      </label>
                      <label className="date-time">
                        {' '}
                        {item.value}{' '}
                        {item.value2 ? (
                          <label className="average-value">{item.value2}</label>
                        ) : null}
                      </label>
                    </div>
                  );
                })}

                {isAddNewButton && (
                  <div className="add-new-button" onClick={handleAddNewClick}>
                    <Icon name="add circle" />
                    Add New
                  </div>
                )}
              </div>
            </Segment>
          </CardDescription>
        </CardContent>
      </Card>
    </div>
  );
};

export default CardBoxComponent;
