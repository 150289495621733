import _ from 'lodash';
import React, { useEffect } from 'react';
import { GridColumn, Search } from 'semantic-ui-react';
import '../application.scss';
import { useField } from 'formik';

const initialState = {
  loadingModel: false,
  modelResults: [],
  model: '',
  modelInputValue: '',
};

function modelReducer(state, action) {
  switch (action.type) {
    case 'CLEAN_QUERY':
      return initialState;
    case 'START_MODEL_SEARCH':
      return { ...state, loadingModel: true, modelInputValue: action.query };
    case 'FINISH_MODEL_SEARCH':
      return { ...state, loadingModel: false, modelResults: action.results };
    case 'UPDATE_MODEL_SELECTION':
      return {
        ...state,
        model: action.selection,
      };
    case 'UPDATE_MODEL_INPUT':
      return { ...state, modelInputValue: action.value };
    default:
      throw new Error();
  }
}

const ModelSelection = ({ id, selectedMake }) => {
  const [, meta, helpers] = useField({ name: id });
  const { error, touched } = meta;
  const { setValue } = helpers;

  const [state, dispatch] = React.useReducer(modelReducer, initialState);
  const { loadingModel, modelResults, modelInputValue } = state;

  useEffect(() => {
    dispatch({ type: 'CLEAN_QUERY' });
  }, [selectedMake]);

  const handleModelSearchChange = React.useCallback(
    async (e, data) => {
      dispatch({ type: 'START_MODEL_SEARCH', query: data.value });
      const inputValue = _.escapeRegExp(data.value.trim());
      const re = new RegExp(inputValue, 'i');

      try {
        const response = await fetch(
          `https://vpic.nhtsa.dot.gov/api/vehicles/GetModelsForMake/${selectedMake}?format=json`
        );
        const { Results } = await response.json();
        const filteredResults = Results.filter((model) =>
          re.test(model.Model_Name)
        ).map(({ Model_Name }) => ({
          title: Model_Name,
          make: selectedMake,
        }));
        dispatch({ type: 'FINISH_MODEL_SEARCH', results: filteredResults });
      } catch (error) {
        console.error('Error fetching data:', error);
        dispatch({ type: 'FINISH_MODEL_SEARCH', results: [] });
      }
    },
    [selectedMake]
  );

  const handleModelSelection = (e, data) => {
    dispatch({ type: 'UPDATE_MODEL_SELECTION', selection: data.result.title });
    dispatch({ type: 'UPDATE_MODEL_INPUT', value: data.result.title });
  };

  return (
    <GridColumn width={4}>
      <div className="make-model-segment">
        <div className="custom-label">
          <label className="required-stars">Model</label>
        </div>
        <Search
          id="model"
          name="model"
          className="cus-search-bar"
          loading={loadingModel}
          placeholder="Eg - Raptor"
          onResultSelect={(e, data) => {
            handleModelSelection(e, data);
            setValue(data.result.title);
          }}
          onSearchChange={(e, data) => {
            handleModelSearchChange(e, data);
            setValue(data.value);
          }}
          results={modelResults}
          value={modelInputValue}
          disabled={!selectedMake}
        />
        {error && touched && <div className="invalid-feedback">{error}</div>}
      </div>
    </GridColumn>
  );
};

export default ModelSelection;
