import React from 'react';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Grid,
  GridColumn,
  GridRow,
  Header,
  Radio,
  Segment,
} from 'semantic-ui-react';
import CustomSearchComponent from './customSearchComponent';
import CustomButton from './customButton';
import {
  APPLICATION_FORM_TYPES,
  COMPLIANCE_ID,
  StatusOptions,
  VEHICLE_IMPORT_ID,
} from '../utils/constant';
import { get } from '../utils/lodash';
import moment from 'moment';

const CustomTopSegment = ({
  title,
  isApplication = false,
  buttonText = '',
  onSearchOnchange = null,
  onCustomTypeDropdownChange = null,
  onCustomStatusDropdownChange = null,
  onChangeToggle = null,
  status = 'all',
  type = 'all',
  isInActive = false,
  placeholder = '',
  dashboard = false,
  userDetails = {},
  isFromAdmin = false,
  isCustomer = false,
}) => {
  const navigate = useNavigate();

  const onDropDownClick = (e, data) => {
    const path =
      data.value === COMPLIANCE_ID
        ? '/application/compliance'
        : '/application/vehicle-import';
    navigate(path);
  };

  const renderTypeDropdown = () => (
    <div className="dropdown-customer-custom">
      <Dropdown
        className="drop-down-custom"
        placeholder="Type"
        selection
        value={type}
        options={APPLICATION_FORM_TYPES}
        onChange={(e, { value }) => onCustomTypeDropdownChange(value)}
      />
    </div>
  );

  return (
    <div className="over-ride-segment-custom">
      <Segment className="custom-segment-content">
        <div className="flex-row">
          <div className="label-search-gap">
            <label className="segment-title-custom">{title}</label>
          </div>
          {isMobile &&
          <div>
          <label className="welcome-note">
            Welcome Back, {get(userDetails, 'name', '')}!
          </label>
          <br />
          <br />
        </div>
          }
          
          <div>
            <CustomSearchComponent
              placeholder={
                placeholder || 'Search by VIN, Reference, Customer...'
              }
              onSearchOnchange={onSearchOnchange}
            />
          </div>
          {dashboard && renderTypeDropdown()}
          {isApplication && (
            <>
              {renderTypeDropdown()}
              <div className="dropdown-customer-custom">
                <Dropdown
                  className="drop-down-custom"
                  placeholder="Status"
                  selection
                  options={StatusOptions}
                  value={status}
                  onChange={(e, { value }) =>
                    onCustomStatusDropdownChange(value)
                  }
                />
              </div>
              <div className={`dropdown-customer-custom ${isMobile ? 'mobile-view' : ''}`}> 
              <Radio toggle onChange={onChangeToggle} value={isInActive} />
                 <label className="toggle-text">Inactive only</label> 
              </div>
             
            </>
          )}
          <div>
            {isApplication && !isFromAdmin && (
              <div className="custom-dropdown-button">
                <Dropdown
                  text="Create Application"
                  icon="plus"
                  labeled
                  button
                  className="icon"
                >
                  <DropdownMenu>
                    <DropdownItem
                      text="Vehicle Import Application"
                      value={VEHICLE_IMPORT_ID}
                      onClick={onDropDownClick}
                    />
                    <DropdownItem
                      text="Compliance Application"
                      value={COMPLIANCE_ID}
                      onClick={onDropDownClick}
                    />
                  </DropdownMenu>
                </Dropdown>
              </div>
            )}
            {dashboard && !isMobile && (
              <div>
                <label className="welcome-note">
                  Welcome Back, {get(userDetails, 'name', '')}!
                </label>
                <br />
                <label className="welcome-box-date">
                  {moment().format('Do MMMM YYYY')}
                </label>
              </div>
            )}
            {isMobile ? <br/> : null}
            {isCustomer && <CustomButton text={buttonText}  isCustomer={isCustomer}/>}
          </div>
        </div>
      </Segment>
    </div>
  );
};

export const SearchAndDropDownComponent = ({
  onSearchOnchange = null,
  noData = {},
  onDashboardDropdown = null,
}) => {
  return (
    <Header className="application-list-header" attached="top">
      <Grid>
        <GridRow columns={2} width={16}>
          <GridColumn width={8}>
            <CustomSearchComponent
              className="search-bar-custom"
              placeholder="Search Applications"
              onSearchOnchange={onSearchOnchange}
              disabled={noData}
            />
          </GridColumn>

          <GridColumn className="drop-down-element" width={8}>
            <Dropdown
              className="drop-down-custom"
              placeholder="Type"
              selection
              options={APPLICATION_FORM_TYPES}
              disabled={noData}
              onChange={(e, { value }) => onDashboardDropdown(value)}
            />
          </GridColumn>
        </GridRow>
      </Grid>
    </Header>
  );
};

export default CustomTopSegment;
