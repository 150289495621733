import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, GridColumn, GridRow } from 'semantic-ui-react';
import { getOneApplication } from '../../store/modules/applications';
import { useParams } from 'react-router-dom';
import ViewApplications from './viewApplications';
import { isEmpty } from 'lodash';

const OneApplicationView = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const userDetails = useSelector(state => state.auth.user);
  const application = useSelector(state => state.applications.oneAppData);

  useEffect(() => {
    if (id) {
      dispatch(getOneApplication(id));
    }
  }, [id, dispatch]);

  if (isEmpty(userDetails) || isEmpty(application)) {
    return null;
  }

  return (
    <div>
      <Grid stackable style={isMobile ? { marginTop: '50px' } : {}}>
        <GridRow  className="custom-row-app-home-one-view">
          <GridColumn width={15}>
          <ViewApplications selectedApp={application} />
          </GridColumn>
        </GridRow>
      </Grid>
    </div>
  );
};

export default OneApplicationView;
