import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Image,
  Menu,
  MenuItem,
  Segment,
  Sidebar,
  SidebarPushable,
  SidebarPusher,
} from 'semantic-ui-react';
import companyLogo from '../../assets/icons/irevLogo.svg';
import { logoutUser } from '../../store/actions';

import './sideBar.scss';

import { openMobileSideBar } from '../../store/actions';
import { get } from '../../utils/lodash';

const MobileSideBar = ({isAdmin=false, component: Component }) => {
  const [isNavigationVisible, setNavigationVisible] = useState(false);
  const [activeItem, setActiveItem] = useState('dashboard');
  const navigate = useNavigate();

  const isOpenMobileSidebar = useSelector(
    (state) => state.clientSide.isOpenMobileSidebar
  );
  const user = useSelector(
    (state) => state.auth.user
    );
  const handleItemClick = (e, name) => {
    setActiveItem(name);
    navigate(`/${name}`);
    dispatch(openMobileSideBar(false));
  };

  const onLogoutFunction = async () => {
    try {
      await dispatch(logoutUser());
      navigate('/');
    } catch (err) {
      console.error('Error in logout', err);
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    setNavigationVisible(isOpenMobileSidebar);
  }, [isOpenMobileSidebar]);

  const setMobileSideBarHide = () => {
    dispatch(openMobileSideBar(false));
  };

  const renderAdminMenuItems = () => {
    return (
      <>
        <MenuItem
          icon="home"
          name="Home"
          active={activeItem === 'dashboard'}
          onClick={(e, data) => handleItemClick(e, 'dashboard')}
        />
        <MenuItem
          icon="file alternate"
          name="applications"
          active={activeItem === 'applications'}
          onClick={(e, data) => handleItemClick(e, data.name)}
        />
        <MenuItem
          icon="users"
          name="customers"
          active={activeItem === 'customers'}
          onClick={(e, data) => handleItemClick(e, data.name)}
        />
        <MenuItem icon="setting" 
        name="settings" 
           active={activeItem === 'settings'}
           onClick={(e, data) => handleItemClick(e, data.name)}
        />
        <MenuItem icon="sign-out" name="sign out" onClick={onLogoutFunction} />
      </>
    );
  };

  const renderUserMenuItems = () => {
    return (
      <>
        <MenuItem
          icon="home"
          name="Home"
          active={activeItem === 'dashboard'}
          onClick={(e, data) => handleItemClick(e, 'dashboard')}
        />
        <MenuItem
          icon="file alternate"
          name="applications"
          active={activeItem === 'applications'}
          onClick={(e, data) => handleItemClick(e, data.name)}
        />
        <MenuItem icon="setting"
           name="settings" 
           active={activeItem === 'settings'}
           onClick={(e, data) => handleItemClick(e, data.name)}
        
        />
        <MenuItem icon="sign-out" name="sign out" onClick={onLogoutFunction} />
      </>
    );
  };

  return (
    <SidebarPushable>
      <Sidebar
        as={Menu}
        animation="overlay"
        icon="labeled"
        inverted
        onHide={() => setMobileSideBarHide()}
        vertical
        visible={isOpenMobileSidebar}
        width="thin"
      >
         <Menu.Item>
          <div className="logo-image">
            <label className="company-title-label">Hi {get(user,'name','')}</label>
          </div>
        </Menu.Item>
        <Menu.Item>
          <div className="logo-image">
            <Image size="tiny" src={companyLogo} />
            <label className="company-title-label">STRALIS SPORTS CARS</label>
          </div>
        </Menu.Item>
        {isAdmin ? renderAdminMenuItems() :renderUserMenuItems()}
      </Sidebar>
      <SidebarPusher dimmed={isNavigationVisible}>
          <Segment basic>

          {Component}

          </Segment>
      </SidebarPusher>
    </SidebarPushable>
  );
};

export default MobileSideBar;
