/*** TYPES ***/

const OPEN_SIDE_BAR_DASHBOARD = 'OPEN_SIDE_BAR_DASHBOARD';
const OPEN_CUSTOMER_SIDE_BAR_DASHBOARD = 'OPEN_SIDE_BAR_CUSTOMER';
const OPEN_MOBILE_SIDE_BAR_DASHBOARD = 'OPEN_MOBILE_SIDE_BAR_DASHBOARD';
const GET_SELECTED_CUSTOMER_DATA = 'GET_SELECTED_CUSTOMER_DATA';
const OPEN_CUSTOMER_UPDATE_SIDE_BAR_DASHBOARD =
  'OPEN_CUSTOMER_UPDATE_SIDE_BAR_DASHBOARD';

const SELECT_APPLICATION_LOADING = 'SELECT_APPLICATION_LOADING'; 

/*** REDUCERS ***/
const initialState = {
  isOpenDashboardSidebar: false,
  isOpenCustomerUpdateDashboardSidebar: false,
  isOpenCustomerSidebar: false,
  isOpenMobileSidebar: false,
  selectedCustomer: {},
  applicationSelectionLoading:false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case OPEN_SIDE_BAR_DASHBOARD:
      return { ...state, isOpenDashboardSidebar: action.payload };
    case OPEN_CUSTOMER_UPDATE_SIDE_BAR_DASHBOARD:
      return { ...state, isOpenCustomerUpdateDashboardSidebar: action.payload };
    case OPEN_CUSTOMER_SIDE_BAR_DASHBOARD:
      return { ...state, isOpenCustomerSidebar: action.payload };
    case OPEN_MOBILE_SIDE_BAR_DASHBOARD:
      return { ...state, isOpenMobileSidebar: action.payload };
    case GET_SELECTED_CUSTOMER_DATA:
      return { ...state, selectedCustomer: action.payload };
    case SELECT_APPLICATION_LOADING:
      return { ...state, applicationSelectionLoading: action.payload };
      
    default:
      return state;
  }
}

export function openDashboardSideBar(value) {
  return (dispatch) => {
    dispatch({ type: OPEN_SIDE_BAR_DASHBOARD, payload: value });
  };
}

export function openMobileSideBar(value) {
  return (dispatch) => {
    dispatch({ type: OPEN_MOBILE_SIDE_BAR_DASHBOARD, payload: value });
  };
}

export function getCustomerData(customerData) {
  return (dispatch) => {
    dispatch({ type: GET_SELECTED_CUSTOMER_DATA, payload: customerData });
  };
}

export function openCustomerSideBar(value) {
  return (dispatch) => {
    dispatch({ type: OPEN_CUSTOMER_SIDE_BAR_DASHBOARD, payload: value });
  };
}

export function applicationSelectionLoading() {
  return (dispatch) => {
    dispatch({ type: SELECT_APPLICATION_LOADING, payload: true });
    setTimeout(() => {
      dispatch({ type: SELECT_APPLICATION_LOADING, payload: false });
    }, 500);
  };
}

export function openCustomerUpdateDashboardSidebar(value, customerId) {
  let payload = value;
  if (customerId) {
    payload = { ...value, payload };
  }
  return (dispatch) => {
    dispatch({
      type: OPEN_CUSTOMER_UPDATE_SIDE_BAR_DASHBOARD,
      payload,
    });
  };
}
