import React from 'react';
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
} from 'react-router-dom';
import { NotFound } from './components/errors/errorStatus';
import ComplianceApplicationForm from './containers/applications/compliance/complianceApplicationForm';
import Applications from './containers/applications/index';
import VehicleImportApplicationForm from './containers/applications/vehicleImport/vehicleImportApp';
import Customers from './containers/admin/customers/index';
import DashboardView from './containers/admin/dashboard/dashboardView';
import ForgotPassword from './containers/auth/forgotPassword';
import { SignIn } from './containers/auth/signIn';
import {
  AdminLayout,
  AuthLayout,
  CommonLayout,
} from './containers/mainLayouts/index';
import { isMobile } from 'react-device-detect';
import Settings from './containers/settings/index';
import { NotificationContainer } from 'react-notifications';
import { userAuth } from './utils';
import CustomerApplications from './containers/applications/customers/customerApplication';
import ResetPassword from './containers/auth/resetPassword';
import UnsubscribeEmail from './containers/auth/unsubscribeEmail';
import RightSideContent from './components/rightSideComponents/content';
import OneApplicationView from './containers/applications/oneApplicationView';
import { CustomMobileForm } from './containers/admin/customers/customerMobileForm';

const AdminRoute = ({component, sideBar = false}) => {
  return userAuth.isAuthenticated ? (
    <>
      <AdminLayout children={component} sideBar={sideBar} />
      <NotificationContainer />
    </>
  ) : (
    <Navigate to="/" />
  );
};

//add common route
const CommonRoute = ({ component, sideBar = false }) => {
  return userAuth.isAuthenticated ? (
    <>
      <CommonLayout children={component} sideBar={sideBar} />
      <NotificationContainer />
    </>
  ) : (
    <Navigate to="/" />
  );
};

//add auth route
const AuthRoute = ({ component,rightComponent }) => {
  let location = useLocation();
  const WrappedComponent = userAuth.isAuthenticated ? (
    <Navigate
      to={{
        pathname: '/dashboard',
        state: { from: location },
      }}
    />
  ) : (
    <>
      <AuthLayout
        children={component}
        rightComponent={rightComponent}
      />
      <NotificationContainer />
    </>
  );

  // return WrappedComponent;
  return WrappedComponent;
};

//app routings
const AppRouting = () => {
  return (
    <Router>
      <Routes>
        {/* SIGN IN */}
        <Route
          exact
          path="/"
          element={
            <AuthRoute
              component={<SignIn />}
              exact
              path="/"
              rightComponent={isMobile ? null : <RightSideContent signIn/>}
            />
          }
        />
        <Route
          path="/forgot-password"
          element={
            <AuthRoute
              exact
              path="/forgot-password"
              component={<ForgotPassword />}
              rightComponent={isMobile ? null : <RightSideContent signIn/>}
            />
          }
        />
        <Route
          path="/resetPassword/:id"
          element={
            <AuthRoute
              exact
              path="/resetPassword/:id"
              component={<ResetPassword />}
              rightComponent={isMobile ? null : <RightSideContent />}
            />
          }
        />
          
        {/* ADMIN ROUTES */}
        <Route
          path="/dashboard"
          element={
            <CommonRoute
              exact
              path="/dashboard"
              sideBar={true}
              component={<DashboardView />}
            />
          }
        />
       
        <Route
          path="/applications/customer"
          element={
            <AdminRoute
              exact
              path="/applications/customer"
              sideBar={false}
              component={<CustomerApplications />}
            />
          }
        />
        <Route
          path="/applications/customer/:id"
          element={
            <AdminRoute
              exact
              path="/applications/customer/:id"
              sideBar={false}
              component={<CustomerApplications />}
            />
          }
        />
        <Route
          path="/customers"
          element={
            <AdminRoute
              exact
              path="/customers"
              sideBar={true}
              component={<Customers />}
            />
          }
        />

        <Route
          path="/customer-form"
          element={
            <AdminRoute
              exact
              path="/customer-form"
              sideBar={true}
              component={<CustomMobileForm />}
            />
          }
        />
     
        {/* CUSTOMERS ROUTE */}
        {/* Make changes inside the dashboard view */}
        {/* COMMON ROUTES */}
        <Route
          path="/applications"
          element={
            <CommonRoute
              exact
              path="/applications"
              sideBar={true}
              component={<Applications />}
            />
          }
        />
        <Route
          path="/notification-app/:id"
          element={
            <CommonRoute
              exact
              path="/notification-app/:id"
              sideBar={true}
              component={<OneApplicationView />}
            />
          }
        />

        <Route
          path="/application/:id"
          element={
            <CommonRoute
              exact
              path="/application/:id"
              sideBar={true}
              component={<Applications />}
            />
          }
        />
         <Route
          path="/application/vehicle-import"
          element={
            <CommonRoute
              exact
              path="/application/vehicle-import"
              sideBar={true}
              component={<VehicleImportApplicationForm />}
            />
          }
        />
        <Route
          path="/application/compliance"
          element={
            <CommonRoute
              exact
              path="/application/compliance"
              sideBar={true}
              component={<ComplianceApplicationForm />}
            />
          }
        />
        <Route
          path="/settings"
          element={
            <CommonRoute
              exact
              path="/settings"
              sideBar={true}
              component={<Settings />}
            />
          }
        />
 <Route
         path="/unsubscribe-service"
          element={
            <CommonRoute
              exact
             path="/unsubscribe-service"
             sideBar={false}
              component={<UnsubscribeEmail/>}
            />
          }
        />

        {/* ERROR */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};
export default AppRouting;
