import { io } from 'socket.io-client';
import { SOCKET_BASE_URL } from './constant';

export const SocketConnection = (token) => {
  return io(SOCKET_BASE_URL, {
    reconnectionDelayMax: 10000,
    auth: {
      token: token,
    },
  });
};

