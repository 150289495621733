/*** TYPES ***/
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { getCommonError, userAuth } from '../../utils';
import {
  getAuthenticatedToken,
  removePersist,
  setAuthenticatedToken,
  setRefreshToken,
} from '../../utils/cacheStore';
const LOGIN_USER_LOADING = 'USER_LOGIN_LOADING';
const USER_LOGIN_INCORRECT = 'USER_LOGIN_INCORRECT';
const GET_USER = 'GET_USER';
const RESET_USER_LOADING = 'RESET_USER_LOADING';
const CHANGE_USER_LOADING = 'CHANGE_USER_LOADING';
const GET_USER_LOADING = 'GET_USER_LOADING';
const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';

/*** REDUCERS ***/
const initialState = {
  user: {},
  userLoading: false,
  userLoginIncorrect: false,
  resetPassLoading: false,
  changePassLoading: false,
  userDataLoading: false,
  resetPasswordSuccess: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_USER_LOADING:
      return { ...state, userLoading: action.payload };
    case USER_LOGIN_INCORRECT:
      return { ...state, userLoginIncorrect: action.payload };

    case GET_USER_LOADING:
      return { ...state, userDataLoading: action.payload };
    case GET_USER:
      return { ...state, user: action.payload };
    case RESET_USER_LOADING:
      return { ...state, resetPassLoading: action.payload };
    case CHANGE_USER_LOADING:
      return { ...state, changePassLoading: action.payload };
    case RESET_PASSWORD_SUCCESS:
      return { ...state, resetPasswordSuccess: action.payload };

    default:
      return state;
  }
}

export function loginUser(form) {
  return async (dispatch) => {
    dispatch({ type: LOGIN_USER_LOADING, payload: true });
    return new Promise(async (resolve, reject) => {
      try {
        let { data } = await axios.post(`/auth/login`, form);
        await dispatch({ type: USER_LOGIN_INCORRECT, payload: false });
        await dispatch({ type: LOGIN_USER_LOADING, payload: false });
        axios.defaults.headers.common['Authorization'] = `Bearer ${data.token}`;
        await setAuthenticatedToken(data.token);
        await setRefreshToken(data.refreshToken);
        await setRefreshToken(data.refreshToken);
        await dispatch(initializedApp());
        resolve(data);
      } catch (error) {
        dispatch({ type: LOGIN_USER_LOADING, payload: false });
        dispatch({ type: GET_USER, payload: {} });
        // dispatch({ type: ERROR_HANDLING, payload: getCommonError(error) });
        NotificationManager.error(getCommonError(error));
        reject(getCommonError(error));
      }
    });
  };
}

export function logoutUser() {
  return async (dispatch) => {
    try {
      // await axios.get('/auth/logout');
      await userAuth.signout();

      removePersist();
      dispatch({ type: GET_USER, payload: {} });
    } catch (error) {
      await userAuth.signout();
      removePersist();
      dispatch({ type: GET_USER, payload: {} });
    }
  };
}

export function initializedApp() {
  return (dispatch) => {
    return new Promise(async (resolve, reject) => {
      try {
        const authToken = await getAuthenticatedToken();
        axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`;
        resolve();
      } catch (error) {
        reject();
      }
    });
  };
}

export function getUserProfile() {
  return async (dispatch) => {
    dispatch({ type: GET_USER_LOADING, payload: true });
    const res = await axios.get(`/user/current`);
    try {
      if (userAuth.isAuthenticated) {
        dispatch({ type: GET_USER, payload: res.data });
        dispatch({ type: GET_USER_LOADING, payload: false });
      }
    } catch (error) {
      dispatch({ type: GET_USER_LOADING, payload: false });
      dispatch(logoutUser());
    }
  };
}

export function resetPassword(form) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
      dispatch({ type: RESET_USER_LOADING, payload: true });
      try {
        const response = await axios.post(`/auth/resetPassword`, form);
        dispatch({ type: RESET_PASSWORD_SUCCESS, payload: true });
        resolve(response.data);
        dispatch({ type: RESET_USER_LOADING, payload: false });
        NotificationManager.success('Password reset success', 'Success');
      } catch (error) {
        NotificationManager.error(getCommonError(error));
        dispatch({ type: RESET_USER_LOADING, payload: false });
        reject(getCommonError(error));
      }
    });
  };
}

export function changePassword(form) {
  return async (dispatch) => {
    dispatch({ type: CHANGE_USER_LOADING, payload: true });
    try {
      await axios.post(`/auth/changePassword`, form);
      dispatch({ type: CHANGE_USER_LOADING, payload: false });
      NotificationManager.success('Password reset success', 'Success');
    } catch (error) {
      dispatch({ type: CHANGE_USER_LOADING, payload: false });
      NotificationManager.error(getCommonError(error));
    }
  };
}
