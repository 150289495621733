import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  Divider,
  Grid,
  GridColumn,
  GridRow,
  Header,
  Icon,
  Segment,
} from 'semantic-ui-react';
import { CustomInput } from '../../../components/forms/inputs/customInput';
import PdfUploadComponent from '../../../components/fileUpload/pdfUploadComponent';
import '../../../components/styles.scss';


import { sourceType } from '../../../utils';
import { find, get, isEmpty } from '../../../utils/lodash';
import { ADMIN_ROLE, COMPLIANCE_ID, STATUS } from '../../../utils/constant';
import { ComplianceDocumentTypeId } from '../../../utils/vehicleImportConstant';
import {
  complianceValidationSchema,
  updateComplianceValidationSchema,
} from '../../../validations/complianceForm/complianceValidation';
import {
  downloadAllTheFiles,
  patchApplicationActiveStatus,
  patchComplianceApplication,
} from '../../../store/modules/applications';
import { useDispatch, useSelector } from 'react-redux';
import CustomDateComponent from '../../../components/customDateComponent';
import dayjs from 'dayjs';
import { FooterAdmin, FooterCustomer } from '../common/footer';
import { CustomDropdown } from '../../../components/customDropdown';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import CustomTextAreaContainer from '../../../components/CustomContainers/customTextAreaContainer';
import { CustomComment } from '../../../components/customComment';
import { patchApplicationComment, postApplicationComments } from '../../../store/modules/comments';

const UpdateComplianceApplication = ({ selectedApp = '' ,...props}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [commentText, setCommentText] = useState('');
  const [approvalDocument, setApprovalDocument] = useState(null);
  const [applicationStatus, setApplicationStatus] = useState(
    selectedApp.status
  );
  const [,setVehicleArriveDate] = useState(null);

  const uploadApprovalDocInputRef = useRef(null);


  const onBlur = (e) => {
    setCommentText(e.target.value);
  };


  const onSaveComment = () => {
    const data = {
      userId: user.id,
      applicationId: selectedApp.id,
      commentText: commentText,
    };
    if (isEmpty(selectedApp.commentsData)) {
      dispatch(postApplicationComments(data));
    } else {
      const commentId = selectedApp.commentsData[0].id;
      if (commentId) {
        dispatch(patchApplicationComment(commentId, data));
      }
    }
  };

  const onSubmit = async (values) => {
    try {
    } catch (error) {
      console.error('Form is not submitted');
    }
  };

  const [initialValues, setInitialValues] = useState({});

  const [isDownloading, setDownloading] = useState(false);

  const user = useSelector(
    (state) => state.auth.user
    );

  const patchCallObj = useSelector(
    (state) => state.applications.applicationPatch
  );
  const downloadLoading = useSelector(
    (state) => state.applications.downloadLoading
  );
  const removeLoading = useSelector(
    (state) => state.applications.disableApplicationLoading
  );
  const addCommentLoading = useSelector(
    (state) => state.comments.addCommentLoading
  );

  const updateCommentLoading = useSelector(
    (state) => state.comments.updateCommentLoading
  );
  
  const loading = useMemo(() => {
    return patchCallObj.vehicleImportStatus || patchCallObj.complianceStatus || downloadLoading || removeLoading;
  }, [patchCallObj, downloadLoading, removeLoading]);

  useEffect(() => {
    setInitialValues(
      updateComplianceValidationSchema(selectedApp.formResponses, selectedApp.commentsData)
    );
    const document = find(
      selectedApp.formResponses,
      (item) => item.attributeId === ComplianceDocumentTypeId.approvalDocument
    );

    if (document) {
      setApprovalDocument({
        file: { url: document.multipleValues[0].url },
      });
    }

    setIsEdit(selectedApp.status === STATUS.DRAFTED);

    setApplicationStatus(selectedApp.status);

  }, [selectedApp.formTypeId, selectedApp.formResponses, selectedApp.status,selectedApp.commentsData]);

  if (isEmpty(initialValues)) return;

  const handleFileUpload = (event, setStateData) => {
    const file = event.target.files[0];
    if (file) {
      setStateData({ file });
    }
  };

  const handleDocumentDelete = (updateState, inputRef) => {
    updateState(null);
    if (inputRef.current) {
      inputRef.current.value = null; // Clear the file input
    }
  };

  const handleRemoveClick = () => {
    if (selectedApp.status !== STATUS.DRAFTED) {
      dispatch(patchApplicationActiveStatus(selectedApp.id));
    }
  };

  const onClickStatusUpdate = async (status) => {
    const data = {
      status,
    };
    await dispatch(
      patchComplianceApplication(selectedApp.id, data, COMPLIANCE_ID)
    );
    setApplicationStatus(status);
  };

  const onDownloadAllFiles = () => {
    if (!isDownloading) {
      setDownloading(true);
      dispatch(downloadAllTheFiles(selectedApp.id,selectedApp.formTypeId));
      setTimeout(() => {
        setDownloading(false);
      }, 2000);
    }
  };
  const onDateChange = (value) => {
    setVehicleArriveDate(value);
  };




  return (
    <div className="update-application">
      {/* Header Section */}
      <Header className="update-application-header" attached="top">
        <Grid stackable>
          <GridRow columns={2}>
            <GridColumn>
              <Grid>
                <GridRow >
                <GridColumn
              className="update-application-title"
              mobile={16}
              tablet={14}
              computer={10}
              largeScreen={10}
              widescreen={10}
            >
                    {isMobile && <Icon name='arrow left' onClick={()=>navigate(-1)} /> }
                    Updating Application {get(selectedApp, 'referenceNo', '')}
                  </GridColumn>
                  <GridColumn
                    mobile={16}
                    tablet={2}
                    computer={8}
                    largeScreen={6}
                    widescreen={6}
            >
                    <Button
                      className={
                        applicationStatus === 'LODGED'
                          ? 'lodged-button'
                          : 'submitted-button'
                      }
                    >
                      {applicationStatus}
                    </Button>
                  </GridColumn>
                </GridRow>
              </Grid>
            </GridColumn>
          </GridRow>
        </Grid>
      </Header>
      <Formik
        onSubmit={(values) => onSubmit(values)}
        initialValues={initialValues}
        validationSchema={complianceValidationSchema}
      >
        {({ handleSubmit, values, setFieldValue }) => (
          <>
            <Form onSubmit={handleSubmit}>
              <Segment
                className="update-application-form-layout"
                attached
                loading={loading}
              >
                <Grid className="update-application-form">
                  {/* custom input row 1 */}
                  <GridRow columns={3} style={{ paddingBottom: '0px' }}>
                    <GridColumn>
                      <CustomInput
                        id="chassisNo"
                        name="chassisNo"
                        labelText="Chassis Number"
                        disabled={!isEdit}
                        // requiredStar
                      />
                    </GridColumn>
                    <GridColumn>
                      <div className="date-of-arrival">
                        <div className="custom-label">
                          <label className="required-stars">
                            Date your vehicle arrived in Australia
                          </label>
                        </div>
                        <div>
                            <CustomDateComponent
                              value={dayjs(values.vehicleArrivedDate)}
                              onChange={(e) => onDateChange(e)}
                              name="vehicleArrivedDate"
                              id="vehicleArrivedDate"
                              disabled={!isEdit}
                            />
                        </div>
                      </div>
                    </GridColumn>
                    <GridColumn>
                      <CustomInput
                        id="engineNo"
                        name="engineNo"
                        labelText="Engine number"
                        disabled={!isEdit}
                        // requiredStar
                      />
                    </GridColumn>
                  </GridRow>
                  {/* custom input row 2 */}
                  <GridRow columns={3} style={{ paddingBottom: '0px' }}>
                    <GridColumn>
                    <div className="report-type">
                        <label className="required-stars">
                        Transmission Type
                        </label>
                        <div>
                          <CustomDropdown
                            className="custom-drop-down"
                            id="transmissionType"
                            name="transmissionType"
                            options={sourceType}
                            placeholder="Choose an option"
                            selection
                            onChange={(e, { value }) =>
                                  setFieldValue('transmissionType', value)
                                }
                            value={values.transmissionType}
                            disabled={!isEdit}
                          />
                        </div>
                      </div>
                    </GridColumn>
                    <GridColumn>
                      <CustomInput
                        id="make"
                        name="make"
                        labelText="Make"
                        disabled={!isEdit}
                        // requiredStar
                      />
                    </GridColumn>
                    <GridColumn>
                      <CustomInput
                        id="model"
                        name="model"
                        labelText="Model"
                        disabled={!isEdit}
                        // requiredStar
                      />
                    </GridColumn>
                  </GridRow>
                  {/* custom input row 3 */}
                  <GridRow columns={3} style={{ display: 'flex' }}>
                    <GridColumn>
                      <CustomInput
                        id="odometerReading"
                        name="odometerReading"
                        labelText="Odometer reading"
                        disabled={!isEdit}
                        // requiredStar
                      />
                    </GridColumn>

                    <GridColumn>
                      <CustomInput
                        id="noOfDoors"
                        name="noOfDoors"
                        labelText="Number of doors (gate/rear boot)"
                        disabled={!isEdit}
                        // requiredStar
                      />
                    </GridColumn>

                    <GridColumn>
                      <CustomInput
                        id="totalNoOfSeats"
                        name="totalNoOfSeats"
                        labelText="Total No of Seats"
                        disabled={!isEdit}
                        // requiredStar
                      />
                    </GridColumn>
                  </GridRow>
                  {/* custom input row 4 */}
                  <GridRow columns={2} style={{ paddingBottom: '0px' }}>
                    <GridColumn width={8}>
                      <CustomInput
                        id="notes"
                        name="notes"
                        labelText="Notes"
                        placeholder="Any comments for the reviewer"
                        disabled={!isEdit}
                        //requiredStar
                      />
                    </GridColumn>
                  </GridRow>
                  {/* custom input row 5 */}
                  <GridRow columns={2} style={{ paddingBottom: '0px' }}>
                    <GridColumn
                      width={8}
                      style={{ paddingTop: '15px', fontWeight: '600' }}
                    >
                      <label>
                        Enter Factory Tyre placard Specs details below
                      </label>
                    </GridColumn>
                  </GridRow>
                  {/* custom input row 6 */}
                  <GridRow columns={3} style={{ paddingBottom: '0px' }}>
                    <GridColumn>
                      <CustomInput
                        id="frontTyreSize"
                        name="frontTyreSize"
                        labelText="Front tyre size"
                        disabled={!isEdit}
                        // requiredStar
                      />
                    </GridColumn>
                    <GridColumn>
                      <CustomInput
                        id="frontTyreLoad"
                        name="frontTyreLoad"
                        labelText="Front tyre load & speed rating"
                        disabled={!isEdit}
                        // requiredStar
                      />
                    </GridColumn>
                    <GridColumn>
                      <CustomInput
                        id="rearTyreSize"
                        name="rearTyreSize"
                        labelText="Rear tyre size"
                        disabled={!isEdit}
                        // requiredStar
                      />
                    </GridColumn>
                  </GridRow>
                  {/* custom input row 7 */}
                  <GridRow columns={3} style={{ paddingBottom: '0px' }}>
                    <GridColumn>
                      <CustomInput
                        id="rearTyreLoad"
                        name="rearTyreLoad"
                        labelText="Rear tyre load & speed rating"
                        disabled={!isEdit}
                        // requiredStar
                      />
                    </GridColumn>
                  </GridRow>
                  <GridRow columns={1} style={{ paddingBottom: '0px' }}>
                    {/* Export certificate */}
                    <PdfUploadComponent
                      name="approvalDocument"
                      label="Existing Vehicle import approval?"
                      type="import_approval"
                      handleFileUpload={(e) =>
                        handleFileUpload(e, setApprovalDocument)
                      }
                      document={approvalDocument}
                      uploadDocumentInputRef={uploadApprovalDocInputRef}
                      handleDocumentDelete={() =>
                        handleDocumentDelete(
                          setApprovalDocument,
                          uploadApprovalDocInputRef
                        )
                      }
                      disabled={!isEdit}
                    />
                  </GridRow>
                  
                  {get(user, 'roleId', '') === ADMIN_ROLE ? (
                    <>
                  <Divider />
                  
                  <GridRow style={{ padding: '10px' }}>
                    <GridColumn width={12}>
                      <CustomTextAreaContainer>
                        <Grid>
                          <GridRow>
                            <GridColumn width={16}>
                              <CustomComment
                                id="comments"
                                name="comments"
                                onBlur={(value) => onBlur(value)}
                              />
                            </GridColumn>
                          </GridRow>
                          <GridRow>
                            <GridColumn floated="right" width={4}>
                              <Button
                                className="save-button"
                                onClick={() => onSaveComment()}
                                loading={addCommentLoading || updateCommentLoading}
                                disabled={addCommentLoading || updateCommentLoading}
                              >
                                Save Comment
                              </Button>
                            </GridColumn>
                          </GridRow>
                        </Grid>
                     </CustomTextAreaContainer>
                    </GridColumn>
                  </GridRow>
                  </>
                  ) : null}

                </Grid>
              </Segment>
              {/* Footer Section */}
              <Segment
                className="update-application-footer"
                attached="bottom"
                disabled={loading}
              >
                {get(user,'roleId','') ===ADMIN_ROLE ?
                    <FooterAdmin
                    user={user}
                    selectedApp={selectedApp}
                    handleRemoveClick={handleRemoveClick}
                    removeLoading={removeLoading}
                    onDownloadAllFiles={onDownloadAllFiles}
                    isDownloading={isDownloading}
                    onClickStatusUpdate={onClickStatusUpdate}
                    
                    />
               :
                   <FooterCustomer
                   onDownloadAllFiles= {onDownloadAllFiles}
                   isDownloading={isDownloading}
                   />
                    }
              </Segment>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

UpdateComplianceApplication.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
};

export default UpdateComplianceApplication;
