

export const VehicleDocumentTypes={
    interiorImages:'interiorImages',
    exteriorImages:'exteriorImages',
    vinImages:'vinImages',
    engineBayImages:'engineBayImages',

    exportCertificate:'exportCertificate',
    auctionReport:'auctionReport',
    vehicleImport:'vehicleImport',

}

export const VehicleDocumentTypeIds={
    vinImages:9,
    engineBayImages:10,
    interiorImages:11,
    exteriorImages:12,

    exportCertificate:13,
    auctionReport:14,
    vehicleImport:15,

}

export const ComplianceDocumentTypes={
    approvalDocument:'approvalDocument',
}

export const ComplianceDocumentTypeId={
    approvalDocument:30,
}


