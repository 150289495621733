import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import MobileNavbar from '../navBar/MobileNavBar';
import NavBar from '../navBar/navBar';
import MobileSideBar from '../sideBar/mobileSideBar';
import SideBar from '../sideBar/sideBar';
import './layout.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getUserProfile, logoutUser } from '../../store/actions';

import { Navigate, useLocation } from 'react-router-dom';
import { ADMIN_ROLE, ROUTES } from '../../utils/constant';
import { isEmpty } from '../../utils/lodash';
import { Grid } from 'semantic-ui-react';

const pushToLoginPage=(dispatch)=>{
  dispatch(logoutUser());
  return <Navigate to="/"/>
}

const BaseLayout = ({
  children: Component,
  sideBar = false,
  isAdminLayout = false,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  const userDetails = useSelector((state) => state.auth.user);
  const isAdmin =
    userDetails !== undefined && userDetails.roleId === 1 ? true : false;

  const allRouteValues = [
    ...ROUTES[isAdmin ? 'adminRoutes' : 'userRoutes'],
    ...ROUTES.commonRoutes,
  ].map((route) => route.route);
  const location = useLocation();

  const pathSegments = location.pathname.split('/');
  const lastSegment = pathSegments[pathSegments.length - 1];

  let isMatchedPath = false;

  if (!isNaN(parseInt(lastSegment, 10))) {
    const customerApplicationRegex = /^\/applications\/customer\/\d+$/;
    isMatchedPath = customerApplicationRegex.test(location.pathname);
  } else {
    isMatchedPath = allRouteValues.includes(location.pathname);
  }

  if (!isMatchedPath && !isEmpty(userDetails)) {
    return <Navigate to="/access-denied" />;
  }

  if (isAdminLayout && !isAdmin && !isEmpty(userDetails))
    return <Navigate to="/dashboard" />;

  if (isAdminLayout && !isAdmin && !isEmpty(userDetails))
    return <Navigate to="/dashboard" />;

    if(userDetails.isDisabled) pushToLoginPage(dispatch);

  return (
    <div className="main-layout">
      {isMobile ? (
        <MobileNavbar isAdmin={isAdmin} />
      ) : (
        <NavBar isAdmin={isAdmin} userDetails={userDetails} />
      )}
      {sideBar ? (
        <div className="background-layout-sidebar">
          {isMobile ? (
            <>
            <MobileSideBar  isAdmin={isAdmin} component={Component} />
            </>
          ) : (
            <SideBar component={Component} />
          )}
        </div>
      ) : (
        <div className="background-layout">{Component}</div>
      )}
    </div>
  );
};

const AuthenticationLayout = ({
  children: Component,
  sideBar = false,
  isAdminLayout = false,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  const userDetails = useSelector((state) => state.auth.user);
  const isAdmin =
    userDetails !== undefined && userDetails.roleId === ADMIN_ROLE ? true : false;

  if(userDetails.isDisabled) pushToLoginPage(dispatch) ;

  return (
    <div className="main-layout">
      {isMobile ? (
        <MobileNavbar isAdmin={isAdmin} />
      ) : (
        <NavBar isAdmin={isAdmin} userDetails={userDetails} />
      )}
      {sideBar ? (
        <div className="background-layout-sidebar">
          {isMobile ? (
            <MobileSideBar  isAdmin={isAdmin} component={Component} />
          ) : (
            <SideBar component={Component} />
          )}
        </div>
      ) : (
        <div className="background-layout">{Component}</div>
      )}
    </div>
  );
};

export const PrivateLayout = ({ children, sideBar }) => (
  <BaseLayout children={children} sideBar={sideBar} />
);

export const AdminLayout = ({ children, sideBar }) => (
  <BaseLayout children={children} sideBar={sideBar} isAdminLayout={true} />
);

export const CommonLayout = ({ children, sideBar }) => (
  <AuthenticationLayout children={children} sideBar={sideBar} />
);

export const MobileLayout = ({ children, mobileSideBar }) => (
  <BaseLayout children={children} sideBar={mobileSideBar} />
);
export const AuthLayout = ({ children, fullPage, rightComponent = null }) => {
  return (
    <div>
      {!fullPage ? (
        <Grid columns={2} stackable relaxed="very" className="auth">
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={8}
            className={'signInFormCol'}
          >
            {children}
          </Grid.Column>
          <Grid.Column
            mobile={16}
            tablet={16}
            computer={8}
            className={'fixedSideDetails'}
          >
            {rightComponent ? rightComponent : null}
          </Grid.Column>
        </Grid>
      ) : (
        <Grid className={'auth'}>
          {children}
        </Grid>
      )}
    </div>
  );
};

