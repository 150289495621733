import React from 'react';
import { useDispatch } from 'react-redux';
import { Image, Menu } from 'semantic-ui-react';
import companyLogo from '../../assets/icons/irevLogo.svg';
import sidebarmenuicon from '../../assets/icons/menu/sidebar-menu-icon.svg';
import { openMobileSideBar } from '../../store/actions';
import './navBar.scss';

const MobileNavbar = ({ setOpen = null }) => {
  // const [activeItem, setActiveItem] = useState('admin');
  const dispatch = useDispatch();

  const handleClick = () => {
    if (setOpen) {
      setOpen(true);
    }
    dispatch(openMobileSideBar(true));
  };

  // const renderUserMenuItems=()=>{
  //   return(
  //     <>
  //       <MenuItem
  //         name="Home"
  //         active={activeItem === 'Home'}
  //         icon={  <img src={formLogo} size='tiny' className='menu-icons' alt='menu icon'/>}
  //         onClick={(e,data)=>handleItemClick(e,data.name)}
  //       />
  //       <MenuItem
  //         name="applications"
  //         active={activeItem === 'applications'}
  //         icon={  <img src={applicationLogo} size='tiny' className='menu-icons' alt='menu icon'/>}
  //         onClick={(e,data)=>handleItemClick(e,data.name)}
  //       />

  //     </>
  //   )
  // }

  return (
    <div className="override-menu-height">
      <Menu inverted secondary>
        
        <Menu.Item onClick={() => handleClick()}>
          <div className="menu-image">
            <Image size="tiny" src={sidebarmenuicon} />
          </div>
        </Menu.Item>
        <Menu.Menu position="right">
          <Menu.Item>
            <div className="logo-image">
              <Image size="tiny" src={companyLogo} />
              <label className="company-title-label">STRALIS SPORTS CARS</label>
            </div>
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    </div>
  );
};

export default MobileNavbar;
