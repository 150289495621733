import React, { useState, useEffect } from 'react';

const SentryBoundary = ({ children }) => {
  const [error, setError] = useState(null);

  useEffect(() => {
    const handleCatch = (error, errorInfo) => {
      setError(error);
      // You can include Sentry logic here if needed
      // Sentry.withScope(scope => {
      //   Object.keys(errorInfo).forEach(key => {
      //     scope.setExtra(key, errorInfo[key]);
      //   });
      //   Sentry.captureException(error);
      // });
    };

    // Set up componentDidCatch as a side effect
    window.addEventListener('error', handleCatch);

    return () => {
      // Clean up the event listener when the component unmounts
      window.removeEventListener('error', handleCatch);
    };
  }, []);

  if (error) {
    // Render fallback UI
    return (
      <div className="not-found">
        <div>
          <label className="nfheader">We're Sorry</label>
          <label className="nfdetails">
            Something went wrong! We're working on the problem. Please try again later.
          </label>
        </div>
      </div>
    );
  } else {
    // When there's not an error, render children untouched
    return children;
  }
};

export default SentryBoundary;
