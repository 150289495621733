import * as Yup from 'yup';

export const signInInitialValues = {
  email: '',
  password: '',
};

export const createErrorObject = (errors) => {
  let formErrors = {};
  formErrors.email = errors.email ? 'email' : '';
  formErrors.password = errors.password ? 'password' : '';
};

export const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter valid email address')
    .required('Please Enter your email address.'),
  password: Yup.string().required('Please enter your password'),
});
