import React from 'react';
import { Tab } from 'semantic-ui-react';
import Account from './account';
// import Billing from './billing';
import './settings.scss';
// import Team from './team';

const Settings = () => {
  // const [currentTab, setCurrentTab] = useState(0);
  // const navigate = useNavigate();
  const panes = [
    {
      menuItem: 'Account',
      render: () => <Account />,
    },
  ];

  return (
    <div>
      <div className="sett-margin-top">
        <label className="main-title-settings">Settings</label>
      </div>
      <Tab menu={{ secondary: true }} panes={panes} />
    </div>
  );
};

export default Settings;
