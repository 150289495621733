import React from 'react';
import './styles.scss';
import { CustomTextArea } from './forms/inputs/customTextArea';
export const CustomComment = (props) => {
  return (
    <div className="custom-text-area-comment">
      <div className="formik-custom-field act-inputs-new">
        <CustomTextArea
          {...props}
          className="txt-description"
          placeholder="Any comments for the reviewer"
          labelText="Comment"
          onBlur={props.onBlur}
        />
      </div>
    </div>
  );
};
