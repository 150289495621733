import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Grid, GridRow } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { SelectApplicationContainer } from '../../../components/emptyContainer';
import { getCustomerApplications } from '../../../store/modules/applications';
import { isEmpty } from '../../../utils/lodash';
import CustomTopSegment from '../../../components/customTopSegment';
import { RenderDashboardApplicationsView } from '../../../components/ApplicationView';

const HomeLayout = (props) => {
  const customerApplications = useSelector(
    (state) => state.applications.cxApplications
  );
  const customerLoading = useSelector(
    (state) => state.applications.cxApplicationLoading
  );
  const [type, setType] = useState('');
  const [searchKeyword, setSearchKeyword] = useState('');
  const dispatch = useDispatch();

  const onSearchOnchange = (e, { value }) => {
    setSearchKeyword(value);
    dispatch(getCustomerApplications(value, type));
  };

  const onCustomApplicationTypeDropdownChange = (data) => {
    setType(data);
    dispatch(getCustomerApplications(searchKeyword, data));
  };


  const renderEmptyLayout = () => {
    return (
      <SelectApplicationContainer
        classes={`no-data`}
        msg={'At the moment, No applications to display!'}
      />
    );
  };

  return (
    <div>
      <CustomTopSegment
        title="Dashboard"
        onSearchOnchange={onSearchOnchange}
        onCustomTypeDropdownChange={onCustomApplicationTypeDropdownChange}
        dashboard
        userDetails={props.userDetails}
        type={type}
      />
      <Grid stackable style={isMobile ? { marginTop: '50px' } : {}}>
        <GridRow className="custom-row-app-home">
          {isEmpty(customerApplications.data)
            ? renderEmptyLayout()
            : RenderDashboardApplicationsView(
                customerLoading,
                customerApplications,
                props.onApplicationClicked,
                getCustomerApplications,
                props.mobileClick,
                props.selectedApp,
                props.applicationViewLoading
              )}
        </GridRow>
      </Grid>
    </div>
  );
};

export default HomeLayout;
