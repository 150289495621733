import { Formik } from 'formik';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { Button, Form, Grid, Icon } from 'semantic-ui-react';
import '../auth/auth.scss';
import './settings.scss';
import {
  changePasswordInitialValues,
  changePasswordValidationSchema,
} from '../../validations/password/changePasswordValidation';
import { CustomInput } from '../../components/forms/inputs/customInput';
import { useSelector } from 'react-redux';
const PasswordChange = ({ handleValidSubmit, loading = false }) => {
  const [isShownPass, setIsShownPass] = React.useState(false);

  const passwordLoading = useSelector((state) => state.auth.changePassLoading);

  return (
    <Formik
      initialValues={changePasswordInitialValues}
      onSubmit={(values, { resetForm }) => {
        handleValidSubmit(values, resetForm);
      }}
      validationSchema={changePasswordValidationSchema}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit} autoComplete="off">
          <Grid stackable>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={8} computer={8}>
                <div className="existing-user-partner">
                  <div className="formik-custom-field act-inputs-new">
                    <CustomInput
                      id="currentPassword"
                      name="currentPassword"
                      placeholder="Current Password"
                      labelText="Current Password"
                    />
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="account-row">
              <Grid.Column mobile={16} tablet={8} computer={8}>
                <div className="existing-user-partner">
                  <div className="formik-custom-field act-inputs-new">
                    <CustomInput
                      id="password"
                      name="password"
                      placeholder="New Password"
                      labelText="New Password"
                      type={isShownPass ? 'text' : 'password'}
                      icon={
                        <Icon
                          name={isShownPass ? 'eye' : 'eye slash'}
                          onClick={() => {
                            setIsShownPass(!isShownPass);
                          }}
                          link
                        />
                      }
                    />
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={8} computer={8}>
                <div className="existing-user-partner">
                  <div className="formik-custom-field act-inputs-new">
                    <CustomInput
                      id="confirmPassword"
                      name="confirmPassword"
                      placeholder="Confirm Password"
                      labelText="Confirm Password"
                      type={isShownPass ? 'text' : 'password'}
                      icon={
                        <Icon
                          name={isShownPass ? 'eye' : 'eye slash'}
                          onClick={() => {
                            setIsShownPass(!isShownPass);
                          }}
                          link
                        />
                      }
                    />
                  </div>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row className="account-row-reset-pass">
              <Grid.Column mobile={16}>
                <div className="header-context-lbl">
                  <label className="green-lbl">
                    Password security starts with creating a strong password.
                  </label>
                </div>
              </Grid.Column>
              <Grid.Column mobile={16}>
                <div className="ud-div">
                  <div>
                    <label className="ud-lbl">
                      At least 12 characters long but 14 or more is better.
                    </label>
                  </div>
                  <div>
                    <label className="ud-lbl">
                      A combination of uppercase letters, lowercase letters,
                      numbers, and symbols.
                    </label>
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column mobile={16} 
              style={{ paddingTop: '110px' }}
              >
                <div className={isMobile ? 'button-area' : ''}>
                  <Button
                    disabled={passwordLoading}
                    loading={passwordLoading}
                    type="submit"
                    className="acc-update-button"
                  >
                    Update
                  </Button>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default PasswordChange;
