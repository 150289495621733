/*** TYPES ***/
import axios from 'axios';
import { NotificationManager } from 'react-notifications';

import { authMsg, getCommonError } from '../../utils';
import { PAGE_LIMIT } from '../../utils/constant';
const GET_ALL_CUSTOMERS = 'GET_ALL_CUSTOMERS';
const LOADING_GET_CUSTOMERS = 'LOADING_GET_CUSTOMERS';
const LOADING_CUSTOMERS_STATS = 'LOADING_CUSTOMERS_STATS';
const CUSTOMERS_STATS = 'CUSTOMERS_STATS';
const GET_CUSTOMERS_LOADING = 'GET_CUSTOMERS_LOADING';
const SET_CUSTOMERS_LOADING = 'SET_CUSTOMERS_LOADING';
const GET_CUSTOMER_APPLICATION_LOADING='GET_CUSTOMER_APPLICATION_LOADING';
const GET_CUS_APP='GET_CUS_APP';

const UPDATE_CUSTOMER_LOADING = 'UPDATE_CUSTOMER_LOADING';
/*** REDUCERS ***/
const initialState = {
  customers: [],
  customersLoading: false,
  customerStats: {},
  customerStatsLoading: false,
  customerPostLoading: false,
  customerPutLoading: false,
  cxApps:{},
  cxAppLoading:false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOADING_GET_CUSTOMERS:
      return { ...state, customersLoading: action.payload };
    case LOADING_CUSTOMERS_STATS:
      return { ...state, customerStatsLoading: action.payload };
    case CUSTOMERS_STATS:
      return { ...state, customerStats: action.payload };
    case GET_CUSTOMERS_LOADING:
      return { ...state, customersLoading: action.payload };
    case GET_ALL_CUSTOMERS:
      return { ...state, customers: action.payload };
    case SET_CUSTOMERS_LOADING:
      return { ...state, customerPostLoading: action.payload };
      case GET_CUS_APP:
      return { ...state, cxApps: action.payload };  
      case GET_CUSTOMER_APPLICATION_LOADING:
      return { ...state, cxAppLoading: action.payload };

    case UPDATE_CUSTOMER_LOADING:
      return { ...state, customerPutLoading: action.payload };

    default:
      return state;
  }
}

export function getCustomerStats(fromDate, toDate) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: LOADING_CUSTOMERS_STATS, payload: true });
      const response = await axios.get(`/customer-stats`, {
        params: {
          fromDate,
          toDate,
        },
      });
      dispatch({ type: CUSTOMERS_STATS, payload: response.data });
      dispatch({ type: LOADING_CUSTOMERS_STATS, payload: false });
    } catch (error) {
      dispatch({ type: LOADING_CUSTOMERS_STATS, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function getAllCustomers(page = 1, searchQuery = '') {
  return async (dispatch) => {
    dispatch({ type: GET_CUSTOMERS_LOADING, payload: true });
    try {
      let params = {
        page,
        limit: 10,
      };
      if (searchQuery !== '') {
        params.searchQuery = searchQuery;
      }
      const res = await axios.get(`/customers`, {
        params,
      });
      dispatch({ type: GET_ALL_CUSTOMERS, payload: res.data });
      dispatch({ type: GET_CUSTOMERS_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: GET_CUSTOMERS_LOADING, payload: false });
    }
  };
}

export function postCustomer(values) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
    try {
      dispatch({ type: SET_CUSTOMERS_LOADING, payload: true });
      const response=await axios.post(`/customer`, values);
      dispatch({ type: SET_CUSTOMERS_LOADING, payload: false });
      resolve(response.data);
      NotificationManager.success('Customer Added success', 'Success');
      dispatch(getAllCustomers());
    } catch (error) {
      dispatch({ type: SET_CUSTOMERS_LOADING, payload: false });
      NotificationManager.error(getCommonError(error));
      reject(getCommonError(error));
      }
    });
  };
}

export function updateCustomer(id, values) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
    try {
      dispatch({ type: UPDATE_CUSTOMER_LOADING, payload: true });
      const response=await axios.patch(`/customer/${id}`, values);
      dispatch({ type: UPDATE_CUSTOMER_LOADING, payload: false });
      NotificationManager.success('Customer Update Success', 'Success');
      dispatch(getAllCustomers());
      resolve(response.data);
    } catch (error) {
      dispatch({ type: UPDATE_CUSTOMER_LOADING, payload: false });
      NotificationManager.error(getCommonError(error));
      reject(getCommonError(error));
    }
  });
};
}

export function getOneCustomerApplication(
  userId,
  values = {
    searchKeyword: '',
    type: '',
    status: '',
    isInActive: false,
    page: 1,
    limit: PAGE_LIMIT,
  }
) {
  return async (dispatch) => {
    dispatch({ type: GET_CUSTOMER_APPLICATION_LOADING, payload: true });
    try {
      const res = await axios.get(`/application/user/${userId}`, {
        params: values,
      });
      dispatch({ type: GET_CUS_APP, payload: res.data });
      dispatch({ type: GET_CUSTOMER_APPLICATION_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: GET_CUSTOMER_APPLICATION_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}