import React, { useState } from 'react';
import { CustomerForm } from './customerForm';
import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { NotFound } from '../../../components/errors/errorStatus';
import { useNavigate } from 'react-router-dom';
export const CustomMobileForm = () => {
    const navigate=useNavigate();
  const [shouldValidate] = useState(true);
  const updateSelectedCustomer = useSelector(
    (state) => state.clientSide.isOpenCustomerUpdateDashboardSidebar
  );

    const closeSideBar=()=>{
        navigate('/customers')
    }

  if(!isMobile) return <NotFound/>
  return (
    <CustomerForm
      shouldValidate={shouldValidate}
      updateSelectedCustomer={updateSelectedCustomer}
      closeSideBar={closeSideBar}
    />
  );
};
