import * as Yup from 'yup';
import { LOWERCASE_REG_EX, NUMERIC_REG_EX, SPECIAL_CHARACTER_REG_EX, UPPERCASE_REG_EX } from '../../utils/regex';

export const resetPasswordValidationSchema = Yup.object().shape({
  password: Yup.string()
  .min(8, 'Length must be more than 8 characters')
  .required('New Password is required!')
  .matches(LOWERCASE_REG_EX, 'At least one character must be lowercased')
  .matches(UPPERCASE_REG_EX, 'At least one character must be upperCased')
  .matches(NUMERIC_REG_EX, 'At least one number is required')
  .matches(SPECIAL_CHARACTER_REG_EX, 'At least one special character is required'),
confirmPassword: Yup.string()
  .required('Confirm password is required')
  .oneOf([Yup.ref('password'), null], "Doesn't match the new password"),
  });
  