import React, {  useState } from 'react';
import { MenuItem, Menu, Image, Icon, Dropdown } from 'semantic-ui-react';
import './navBar.scss';
import '../notification/notificationPanel.scss';
import { Link, useNavigate } from 'react-router-dom';
import homeLogo from '../../assets/icons/menu/home.svg';
import applicationLogo from '../../assets/icons/menu/application.svg';
// import searchIcon from '../../assets/icons/menu/searchIcon.svg';
import settings from '../../assets/icons/menu/settings.svg';
import companyLogo from '../../assets/icons/irevLogo.svg';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../store/actions';
import Avatar from 'react-avatar';
import { get } from '../../utils/lodash';
import NotificationPanel from '../notification/notificationPanel';

const NavBar = ({ isAdmin = false, userDetails = {} }) => {
  const [activeItem, setActiveItem] = useState('dashboard');
  const [notificationVisible, setNotificationVisible] = useState(false);
  const [notificationIndicator, setNotificationIndicator] = useState(false);


  const dispatch = useDispatch();
  let navigate = useNavigate();

  const handleItemClick = (e, name) => {
    setActiveItem(name);
    navigate(`/${name}`);
  };

  const onLogoutFunction = () => {
    try {
      dispatch(logoutUser());
      navigate('/');
    } catch (err) {
      console.error('Error in logout', err);
    }
  };

  const renderAdminMenuItems = () => {
    return (
      <>
        <Menu.Item
          name="Home"
          active={activeItem === 'dashboard'}
          onClick={(e, data) => handleItemClick(e, 'dashboard')}
          icon={
            <img
              src={homeLogo}
              size="tiny"
              className="menu-icons"
              alt="menu icon"
            />
          }
        >
          <Link to="/dashboard">Home</Link>
        </Menu.Item>
        <MenuItem
          icon={
            <img
              src={applicationLogo}
              size="tiny"
              className="menu-icons"
              alt="menu icon"
            />
          }
          name="applications"
          active={activeItem === 'applications'}
          onClick={(e, data) => handleItemClick(e, data.name)}
        >
            <Link to="/applications">Applications</Link>
          </MenuItem>
        <MenuItem
          icon="users"
          name="customers"
          active={activeItem === 'customers'}
          onClick={(e, data) => handleItemClick(e, data.name)}
        >
            <Link to="/customers">Customers</Link>
          </MenuItem>
      </>
    );
  };

  const renderUserMenuItems = () => {
    return (
      <>
      <Menu.Item
        name="Home"
        active={activeItem === 'dashboard'}
        icon={<img src={homeLogo} size="tiny" className="menu-icons" alt="menu icon" />}
        onClick={(e, data) => handleItemClick(e, 'dashboard')}
      >
        <Link to="/dashboard">Home</Link>
      </Menu.Item>
      <Menu.Item
        name="applications"
        active={activeItem === 'applications'}
        icon={<img src={applicationLogo} size="tiny" className="menu-icons" alt="menu icon" />}
        onClick={(e, data) => handleItemClick(e, data.name)}
      >
        <Link to="/applications">Applications</Link>
      </Menu.Item>
    </>
    
    );
  };

  const trigger = (
    <span>
      <Avatar name={get(userDetails, 'name', '')} size="35" round={true} />
    </span>
  );

  const notificationPanel = () => {
    setNotificationVisible(!notificationVisible);
    setNotificationIndicator(false);
  };

  const closeNotificationPanel = () => {
    setNotificationVisible(false);
  };

  const handelIndicator = () => {
    setNotificationIndicator(true);
  };

  const notifyBadgeClassName = notificationIndicator
    ? 'notification-badge red'
    : 'notification-badge';

  return (
    <div className="override-menu-height">
      <Menu inverted secondary>
        <Menu.Item>
          <div className="logo-image">
            <Image size="tiny" src={companyLogo} />
            <label className="company-title-label">STRALIS SPORTS CARS</label>
          </div>
        </Menu.Item>
        {isAdmin ? renderAdminMenuItems() : renderUserMenuItems()}
        <Menu.Menu position="right">
          {/* ADMIN Notification PANEL */}
          {isAdmin ? (
            <>
              <MenuItem
                name="notification"
                active={activeItem === '/notification'}
                onClick={notificationPanel}
              >
                <Icon name="bell" />
                <div className={notifyBadgeClassName}></div>
              </MenuItem>
              <NotificationPanel
                open={notificationVisible}
                onClose={closeNotificationPanel}
                indicator={handelIndicator}
              />
            </>
          ) : null}

          <MenuItem
            name="settings"
            icon={
              <img
                src={settings}
                size="tiny"
                className="menu-icons"
                alt="menu icon"
              />
            }
            active={activeItem === 'settings'}
            onClick={(e, data) => handleItemClick(e, data.name)}
          
          >
             <Link to="/settings"><Icon name="setting" /></Link>
          </MenuItem>

          <Menu.Item>
            <Dropdown
              trigger={trigger}
              direction="left"
              inline
              selectOnBlur={false}
            >
              <Dropdown.Menu>
                <Dropdown.Item onClick={onLogoutFunction}>
                  <div>
                    <label className="profile-pic">Logout</label>
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    </div>
  );
};

export default NavBar;
