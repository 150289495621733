import { combineReducers } from 'redux';
import applications from './modules/applications';
import auth from './modules/auth';
import clientSide from './modules/clientSide';
import customers from './modules/customers';
import user from './modules/user';
import notifications from './modules/notifications';
import comments from './modules/comments';

const reducers = combineReducers({
  user,
  auth,
  clientSide,
  applications,
  customers,
  notifications,
  comments,
});

export default reducers;
