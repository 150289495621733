import { compact } from './lodash';

export const PAGE_LIMIT = 10;
export const APP_NAME = 'I-Rev';
export const ADMIN_ROLE = 1;

export const STATUS = {
  SUBMITTED: 'SUBMITTED',
  LODGED: 'LODGED',
};

export const EMAIL_BLOCKING = {
  UNSUBSCRIBE: 'UNSUBSCRIBE',
  BLACKLISTED: 'BLACKLISTED',
};

export const VEHICLE_IMPORT_ID = 1;
export const COMPLIANCE_ID = 2;

export const BASE_URL =
  process.env.REACT_APP_BASE_URL || 'http://localhost:10010/api';

export const SOCKET_BASE_URL =
  process.env.REACT_APP_SOCKET_BASE_URL || 'http://localhost:10010/';

export const SETTINGS_TABS = [
  {
    id: 0,
    title: 'Accounts',
    route: 'accounts',
  },
  {
    id: 1,
    title: 'Billings',
    route: 'billings',
  },
  {
    id: 2,
    title: 'Teams',
    route: 'teams',
  },
];
export const ROUTES = {
  userRoutes: [
    {
      id: 'dash-panel',
      title: 'Dashboard',
      route: '/home',
    },
  ],
  commonRoutes: [
    {
      id: 'application-panel',
      title: 'Application',
      route: '/applications',
    },
  ],
  adminRoutes: compact([
    {
      id: 'dash-panel',
      title: 'Dashboard',
      route: '/dashboard',
    },
    {
      id: 'customers-panel',
      title: 'Customers',
      route: '/customers',
    },
    {
      id: 'customers-panel-form',
      title: 'Customers',
      route: '/customer-form',
    },
    {
      id: 'compliance',
      title: 'Compliance',
      route: '/application/compliance',
    },
    {
      id: 'vehicle-import',
      title: 'Vehicle Import',
      route: '/application/vehicle-import',
    },
    {
      id: 'customer-app',
      title: 'Customer Applications',
      route: '/applications/customer',
    },
    {
      id: 'customer-app',
      title: 'Customer Applications',
      route: '/applications/customer/:id',
    },
  ]),
};

export const APPLICATION_FORM_TYPES = [
  {
    key: 'all',
    value: 'all',
    text: 'All',
  },
  {
    key: 'import',
    value: '1',
    text: 'Import',
  },
  {
    key: 'compliance',
    value: '2',
    text: 'Compliance',
  },
];

export const StatusOptions = [
  {
    key: 'all',
    value: 'all',
    text: 'All',
  },
  {
    key: 'submitted',
    value: STATUS.SUBMITTED,
    text: 'Submitted',
  },
  {
    key: 'LODGED',
    value: STATUS.LODGED,
    text: 'Lodged',
  },
];
