import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllApplications,
  getCustomerApplications,
} from '../../../store/modules/applications';
import { ADMIN_ROLE } from '../../../utils/constant';
import { isEmpty } from '../../../utils/lodash';
import AdminLayout from './adminLayout';
import HomeLayout from '../../customer/dashboard/homeLayout';
import { applicationSelectionLoading } from '../../../store/actions';

const DashboardView = (props) => {
  const userDetails = useSelector((state) => state.auth.user);
  const [selectedApp, setSelectedApplication] = useState(null);
  const [mobileClick, setMobileClick] = useState(false);
  const dispatch = useDispatch();

  const selectedApplication = useSelector(
    (state) => state.applications.getApplicationById
  );


  const onApplicationClicked = useCallback((applicationData, mobileClickVal) => {
    setMobileClick(mobileClickVal);
    setSelectedApplication(applicationData);
    dispatch(applicationSelectionLoading());
  }, [dispatch, setSelectedApplication, setMobileClick]);

  const applicationViewLoading=useSelector((state)=>state.clientSide.applicationSelectionLoading);

  const isAdmin =
    userDetails !== undefined && userDetails.roleId === ADMIN_ROLE
      ? true
      : false;

  useEffect(() => {
    if (!isEmpty(userDetails)) {
      if (isAdmin) {
        if (selectedApplication) {
          onApplicationClicked(selectedApplication, '');
        }
        dispatch(getAllApplications());
      } else {
        dispatch(getCustomerApplications());
      }
    }
  }, [dispatch, isAdmin, userDetails, selectedApplication,onApplicationClicked]);

  return isAdmin ? (
    <AdminLayout
      userDetails={userDetails}
      onApplicationClicked={onApplicationClicked}
      selectedApp={selectedApp}
      mobileClick={mobileClick}
      applicationViewLoading={applicationViewLoading}
    />
  ) : (
    <HomeLayout
      userDetails={userDetails}
      mobileClick={mobileClick}
      onApplicationClicked={onApplicationClicked}
      selectedApp={selectedApp}
      applicationViewLoading={applicationViewLoading}
    />
  );
};

export default DashboardView;
