import React from 'react';
import {
  ModalHeader,
  ModalContent,
  ModalActions,
  Button,
  Modal,
} from 'semantic-ui-react';

import './modal.scss';
import '../../components/styles.scss';
import '../../containers/applications/application.scss';

const DeletionModal = ({ open, header, content, close, confirmation }) => {
  return (
    <>
      <Modal closeIcon size="tiny" open={open} onClose={close}>
        <ModalHeader style={{ fontSize: '16px' }}>{header}</ModalHeader>
        <ModalContent>
          <label>{content}</label>
        </ModalContent>
        <ModalActions>
          <Button className="btn btn-primary cancel-button" onClick={close}>
            Cancel
          </Button>

          <Button className="delete-button" onClick={confirmation}>
            Yes
          </Button>
        </ModalActions>
      </Modal>
    </>
  );
};

export default DeletionModal;
