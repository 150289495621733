import { Formik } from 'formik';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Checkbox,
  Form,
  Grid,
  GridColumn,
  GridRow,
  Icon,
  Segment,
} from 'semantic-ui-react';
import { CustomSegment } from '../../../components/customSegment';
import { CustomInput } from '../../../components/forms/inputs/customInput';
import '../../../components/styles.scss';
// date picker
import { useNavigate } from 'react-router-dom';
import { CustomDropdown } from '../../../components/customDropdown';
import { postComplianceApplication } from '../../../store/modules/applications';
import { STATUS } from '../../../utils/constant';
import {
  complianceValidationSchema,
  complianceInitialValues,
} from '../../../validations/complianceForm/complianceValidation';
import {
  createWithFormData,
  sourceType,
  termsConditions,
} from '../../../utils';
import FileUploadComponent from '../../../components/fileUpload/fileUploadComponent';
import CustomDateComponent from '../../../components/customDateComponent';
import { isEmpty } from '../../../utils/lodash';
import CustomModalInfo from '../../../components/modals/customModalInfo';
import '../application.scss';
import ComplianceConfirmationModal from '../../../components/modals/complianceConfirmationModal';
import MakeSelection from '../common/makeSelection';
import ModelSelection from '../common/modelSelection';
const AddForm = () => {
  const uploadImportApprovalInputRef = useRef(null);
  const [importApproval, setImportApproval] = useState(null);
  const [fileError, setFileError] = useState('');

  const handleFileUpload = (event, setStateData) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 5242880) {
        // 5MB in bytes
        setFileError('File is too large. Maximum size is 5MB.');
      } else {
        setFileError('');
        setStateData({ file });
      }
    }
  };

  const handleDocumentDelete = (updateState, inputRef) => {
    updateState(null);
    if (inputRef.current) {
      inputRef.current.value = null; // Clear the file input
    }
  };

  const dispatch = useDispatch();
  const loader = useSelector(
    (state) => state.applications.compliancePostLoading
  );
  const [isTermsAgreed, setTermsTicked] = useState(false);
  const [status, setStatus] = useState('');
  const [vehicleArrivedDate, setVehicleArriveDate] = useState(null);
  const [openTermsModal, setOpenTermsModal] = useState(false);

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [tempFormValues, setTempFormValues] = useState(null);

  const [make, setMake] = useState('');

  const navigate = useNavigate();

  const onDateChange = (value) => {
    setVehicleArriveDate(value);
  };

  const handleMakeChange = (value) => {
    setMake(value);
  };

  const handleValidSubmit = async (values) => {
    try {
      const errors = [];
      if (!isTermsAgreed)
        throw new Error('You must agree to the terms and conditions');

      if (isEmpty(importApproval)) {
        errors.push({ msg: 'Please upload Import Approval document' });
      }

      const newValues = {
        ...values,
        vehicleArrivedDate: vehicleArrivedDate,
        status: status,
      };
      delete newValues.approvalDocument;

      const formData = new FormData();
      createWithFormData(formData, newValues);
      formData.append('approvalDocument', importApproval.file);

      if (isEmpty(errors)) {
        await dispatch(postComplianceApplication(formData));
        navigate('/applications');
      }

      navigate('/applications');
    } catch (err) {
      console.error('Error in compliance form');
    }
  };

  const onToggle = () => {
    setOpenTermsModal(!openTermsModal);
  };

  const handelConfirmationClose = () => {
    setConfirmationOpen(false);
  };

  const handelConfirmation = () => {
    setConfirmationOpen(false);
    if (tempFormValues) {
      handleValidSubmit(tempFormValues);
      setTempFormValues(null);
    }
  };

  const handelConfirmationModel = (values) => {
    setTempFormValues(values);
    setConfirmationOpen(true);
  };

  const renderFooter=()=>{
    return(
      <Segment
      className="update-application-footer"
      attached="bottom"
      disabled={loader}
    >
      <Grid stackable>
        <GridRow>
          <GridColumn width={11}>
            <div className="download-button">
              {/* <Button
                basic
                loading={status === STATUS.DRAFTED && loader}
                disabled={status === STATUS.DRAFTED && loader}
                onClick={() => setStatus(STATUS.DRAFTED)}
              >
                Save as draft
              </Button> */}
            </div>
          </GridColumn>
          <GridColumn width={5}>
            <Grid stackable>
              <GridRow>
                <GridColumn width={8}>
                  <div className="custom-check-box">
                    <Checkbox
                      name="termsConditions"
                      label=""
                      value={isTermsAgreed}
                      onChange={() => setTermsTicked(!isTermsAgreed)}
                    />
                    <label
                      className="terms"
                      onClick={() => setOpenTermsModal(true)}
                    >
                      Terms & Conditions
                    </label>
                  </div>
                </GridColumn>
                <GridColumn
                  width={8}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <div className="submit-button">
                    <Button
                      secondary
                      loading={status === STATUS.SUBMITTED && loader}
                      disabled={
                        (status === STATUS.SUBMITTED && loader) ||
                        !isTermsAgreed
                      }
                      onClick={() => setStatus(STATUS.SUBMITTED)}
                    >
                      Submit Application
                    </Button>
                  </div>
                </GridColumn>
              </GridRow>
            </Grid>
          </GridColumn>
        </GridRow>
      </Grid>
    </Segment>
    )
  }

  return (
    <div className="compliance-application">
      <div className="update-application">
        <CustomModalInfo
          open={openTermsModal}
          header="Terms & Conditions"
          content={termsConditions()}
          onToggle={onToggle}
        />

        <ComplianceConfirmationModal
          open={confirmationOpen}
          header="Confirmation"
          values={tempFormValues}
          close={handelConfirmationClose}
          confirmation={handelConfirmation}
        />

        <Formik
          initialValues={complianceInitialValues}
          validationSchema={complianceValidationSchema}
          onSubmit={(values) => {
            if (values.approvalDocument) {
              handelConfirmationModel(values);
            }
          }}
        >
          {({ handleSubmit, errors, touched, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              {/* Compliance Application Form */}
              <Segment
                className="update-application-form-layout"
                attached
                loading={loader}
              >
                <Grid className="update-application-form" stackable>
                  {/* custom input row 1 */}
                  <GridRow columns={4} style={{ paddingBottom: '0px' }}>
                    <GridColumn width={4}>
                      <CustomInput
                        id="chassisNo"
                        name="chassisNo"
                        labelText="Chassis Number"
                        placeholder="Enter chassis number / Search"
                        //disabled={true}
                        requiredStar
                      />
                    </GridColumn>
                    <GridColumn width={4}>
                      <div className="date-of-arrival">
                        <div className="custom-label">
                          <label className="required-stars">
                            Date your vehicle arrived in Australia
                          </label>
                        </div>
                        <div>
                          <CustomDateComponent
                            value={vehicleArrivedDate}
                            onChange={(e) => onDateChange(e)}
                            name="vehicleArrivedDate"
                            id="vehicleArrivedDate"
                          />
                        </div>
                      </div>
                    </GridColumn>
                    <GridColumn width={4}>
                      <CustomInput
                        id="engineNo"
                        name="engineNo"
                        labelText="Engine number"
                        placeholder="Enter your vehicle’s engine number"
                        //disabled={true}
                        requiredStar
                      />
                    </GridColumn>
                    <GridColumn width={4}>
                      <div>
                        <label className="required-stars">
                          Transmission Type
                        </label>
                        <div>
                          <CustomDropdown
                            className="custom-drop-down"
                            name="transmissionType"
                            options={sourceType}
                            placeholder="Select"
                            // getValue={(e) => onChange(e)}
                          />
                        </div>
                      </div>
                    </GridColumn>
                  </GridRow>
                  {/* custom input row 2 */}
                  <GridRow columns={4} style={{ paddingBottom: '0px' }}>
                    <MakeSelection
                      id="make"
                      handleMakeChange={(e) => handleMakeChange(e)}
                    />
                    <ModelSelection id="model" selectedMake={make} />
                    <GridColumn width={4}>
                      <CustomInput
                        id="odometerReading"
                        name="odometerReading"
                        labelText="Odometer reading"
                        placeholder="Current Odometer reading"
                        //disabled={true}
                        requiredStar
                      />
                    </GridColumn>
                    <GridColumn width={4}>
                      <CustomInput
                        id="noOfDoors"
                        name="noOfDoors"
                        labelText="Number of doors (Including gate/rear boot)"
                        placeholder="Enter your vehicle’s door count"
                        //disabled={true}
                        requiredStar
                      />
                    </GridColumn>
                  </GridRow>
                  {/* custom input row 3 */}
                  <GridRow columns={2} style={{ paddingBottom: '0px' }}>
                    <GridColumn width={4}>
                      <CustomInput
                        id="totalNoOfSeats"
                        name="totalNoOfSeats"
                        labelText="Total No of Seats"
                        placeholder="Enter total no of seats"
                        //disabled={true}
                        requiredStar
                      />
                    </GridColumn>
                    <GridColumn width={4}>
                      <CustomInput
                        id="notes"
                        name="notes"
                        labelText="Notes"
                        placeholder="Any comments for the reviewer"
                        //disabled={true}
                        //requiredStar
                      />
                    </GridColumn>
                  </GridRow>
                  {/* custom input row 4 */}
                  <GridRow columns={1} style={{ paddingBottom: '0px' }}>
                    <GridColumn width={16}>
                      <div className="sub-topic">
                        <label>
                          Enter Factory Tyre placard Specs details below
                        </label>
                      </div>
                    </GridColumn>
                  </GridRow>
                  {/* custom input row 5 */}
                  <GridRow columns={4} style={{ paddingBottom: '0px' }}>
                    <GridColumn width={4}>
                      <CustomInput
                        id="frontTyreSize"
                        name="frontTyreSize"
                        labelText="Front tyre size"
                        placeholder="Enter front tyre size"
                        //disabled={true}
                        requiredStar
                      />
                    </GridColumn>
                    <GridColumn width={4}>
                      <CustomInput
                        id="frontTyreLoad"
                        name="frontTyreLoad"
                        labelText="Front tyre load & speed rating"
                        placeholder="Enter front tyre load & speed rating"
                        //disabled={true}
                        requiredStar
                      />
                    </GridColumn>
                    <GridColumn width={4}>
                      <CustomInput
                        id="rearTyreSize"
                        name="rearTyreSize"
                        labelText="Rear tyre size"
                        placeholder="Enter rear tyre size"
                        //disabled={true}
                        requiredStar
                      />
                    </GridColumn>
                    <GridColumn width={4}>
                      <CustomInput
                        id="rearTyreLoad"
                        name="rearTyreLoad"
                        labelText="Rear tyre load & speed rating"
                        placeholder="Enter rear tyre load & speed rating"
                        //disabled={true}
                        requiredStar
                      />
                    </GridColumn>
                  </GridRow>
                  {/* custom input row 6 */}
                  <GridRow columns={1} style={{ paddingBottom: '0px' }}>
                    <GridColumn width={8}>
                      <FileUploadComponent
                        name="approvalDocument"
                        label="Approval Document"
                        handleFileUpload={(e) =>
                          handleFileUpload(e, setImportApproval)
                        }
                        document={importApproval}
                        uploadDocumentInputRef={uploadImportApprovalInputRef}
                        handleDocumentDelete={() =>
                          handleDocumentDelete(
                            setImportApproval,
                            uploadImportApprovalInputRef
                          )
                        }
                        error={fileError}
                        requiredStar
                      />
                    </GridColumn>
                  </GridRow>
                </Grid>
              </Segment>
              {/* Footer Section */}
           {renderFooter()}
            </Form>
          )}
        </Formik>
      </div>
      {/* <VINVerification /> */}
    </div>
  );
};

const ComplianceApplicationForm = () => {
  const navigate = useNavigate();
  return (
    <>
      <div>
        <div className="application-address-back">
          <label
            className="back-text-color"
            onClick={() => navigate('/applications')}
          >
            <Icon
              name="arrow left"
              className="application-address-back-arrow"
            />
            Back to Applications
          </label>
        </div>
      </div>
      <CustomSegment title="Compliance Application" children={<AddForm />} />
    </>
  );
};

export default ComplianceApplicationForm;
