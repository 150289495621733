import React, { useState, useEffect } from 'react';
import UpdateComplianceApplication from './compliance/updateComplianceApplication';
import UpdateVehicleImportApplication from './vehicleImport/updateVehicleImportApplication';
import '../admin/dashboard/dashboard.scss';
import { COMPLIANCE_ID, VEHICLE_IMPORT_ID } from '../../utils/constant';

const ViewApplications = (props) => {
  const { selectedApp } = props;
  const [selectedFormType, setSelectedFormType] = useState(null);

  useEffect(() => {
    if (selectedApp && selectedApp.formTypeId) {
      setSelectedFormType(selectedApp.formTypeId);
    } else {
      setSelectedFormType(null);
    }
  }, [selectedApp]);

  if (selectedFormType === VEHICLE_IMPORT_ID) {
    return <UpdateVehicleImportApplication {...props} />;
  } else if (selectedFormType === COMPLIANCE_ID) {
    return <UpdateComplianceApplication {...props} />;
  } else {
    return null;
  }
};

export default ViewApplications;
