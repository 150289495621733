import React from 'react';
import {
  ModalHeader,
  ModalContent,
  ModalActions,
  Button,
  Modal,
  Grid,
  GridColumn,
  GridRow,
} from 'semantic-ui-react';

import './modal.scss';
import '../../components/styles.scss';
import { CustomInput } from '../forms/inputs/customInput';
import CustomDateComponent from '../customDateComponent';
import { Formik } from 'formik';

import dayjs from 'dayjs';
import { CustomDropdown } from '../customDropdown';
import { sourceType } from '../../utils';
import PdfUploadComponent from '../fileUpload/pdfUploadComponent';
import { confirmComplianceValue } from '../../validations/complianceForm/complianceValidation';

const ComplianceConfirmationModal = ({
  open,
  header,
  values,
  close,
  confirmation,
}) => {
  return (
    <>
      <Modal size="large" open={open} onClose={close}>
        <ModalHeader>{header}</ModalHeader>
        <ModalContent>
          <div className="confirm-vehicle-import-application">
            <div className="update-application">
              <div className="update-application-form-layout">
                <Formik initialValues={confirmComplianceValue(values)}>
                  <Grid className="update-application-form" stackable>
                    {/* custom input row 1 */}
                    <GridRow columns={4} style={{ paddingBottom: '0px' }}>
                      <GridColumn width={4}>
                        <CustomInput
                          id="chassisNo"
                          name="chassisNo"
                          labelText="Chassis Number"
                          placeholder="Enter chassis number / Search"
                          disabled={true}
                          requiredStar
                        />
                      </GridColumn>
                      <GridColumn width={4}>
                        <div className="date-of-arrival">
                          <div className="custom-label">
                            <label className="required-stars">
                              Date vehicle arrived in Australia
                            </label>
                          </div>
                          <div>
                            <div className="date-picker">
                              <CustomDateComponent
                                id="vehicleArrivedDate"
                                name="vehicleArrivedDate"
                                value={
                                  dayjs(values?.vehicleArrivedDate) || null
                                }
                                disabled={true}
                              />
                            </div>
                          </div>
                        </div>
                      </GridColumn>
                      <GridColumn width={4}>
                        <CustomInput
                          id="engineNo"
                          name="engineNo"
                          labelText="Engine number"
                          placeholder="Enter your vehicle’s engine number"
                          disabled={true}
                          requiredStar
                        />
                      </GridColumn>
                      <GridColumn width={4}>
                        <div>
                          <label className="required-stars">
                            Transmission Type
                          </label>
                          <div>
                            <CustomDropdown
                              id="transmissionType"
                              name="transmissionType"
                              className="custom-drop-down"
                              options={sourceType}
                              placeholder="Select"
                              disabled={true}
                            />
                          </div>
                        </div>
                      </GridColumn>
                    </GridRow>
                    {/* custom input row 2 */}
                    <GridRow columns={4} style={{ paddingBottom: '0px' }}>
                      <GridColumn width={4}>
                        <CustomInput
                          id="make"
                          name="make"
                          labelText="Make"
                          placeholder="Eg - Ford"
                          disabled={true}
                          requiredStar
                        />
                      </GridColumn>
                      <GridColumn width={4}>
                        <CustomInput
                          id="model"
                          name="model"
                          labelText="Model"
                          placeholder="Eg - Raptor"
                          disabled={true}
                          requiredStar
                        />
                      </GridColumn>
                      <GridColumn width={4}>
                        <CustomInput
                          id="odometerReading"
                          name="odometerReading"
                          labelText="Odometer reading"
                          placeholder="Current Odometer reading"
                          disabled={true}
                          requiredStar
                        />
                      </GridColumn>
                      <GridColumn width={4}>
                        <CustomInput
                          id="noOfDoors"
                          name="noOfDoors"
                          labelText="Number of doors"
                          placeholder="Enter your vehicle’s door count"
                          disabled={true}
                          requiredStar
                        />
                      </GridColumn>
                    </GridRow>
                    {/* custom input row 3 */}
                    <GridRow columns={2} style={{ paddingBottom: '0px' }}>
                      <GridColumn width={4}>
                        <CustomInput
                          id="totalNoOfSeats"
                          name="totalNoOfSeats"
                          labelText="Total No of Seats"
                          placeholder="Enter total no of seats"
                          disabled={true}
                          requiredStar
                        />
                      </GridColumn>
                      <GridColumn width={4}>
                        <CustomInput
                          id="notes"
                          name="notes"
                          labelText="Notes"
                          placeholder="Any comments for the reviewer"
                          disabled={true}
                        />
                      </GridColumn>
                    </GridRow>
                    {/* custom input row 5 */}
                    <GridRow columns={4} style={{ paddingBottom: '0px' }}>
                      <GridColumn width={4}>
                        <CustomInput
                          id="frontTyreSize"
                          name="frontTyreSize"
                          labelText="Front tyre size"
                          placeholder="Enter front tyre size"
                          disabled={true}
                          requiredStar
                        />
                      </GridColumn>
                      <GridColumn width={4}>
                        <CustomInput
                          id="frontTyreLoad"
                          name="frontTyreLoad"
                          labelText="Front tyre load & speed rating"
                          placeholder="Enter front tyre load & speed rating"
                          disabled={true}
                          requiredStar
                        />
                      </GridColumn>
                      <GridColumn width={4}>
                        <CustomInput
                          id="rearTyreSize"
                          name="rearTyreSize"
                          labelText="Rear tyre size"
                          placeholder="Enter rear tyre size"
                          disabled={true}
                          requiredStar
                        />
                      </GridColumn>
                      <GridColumn width={4}>
                        <CustomInput
                          id="rearTyreLoad"
                          name="rearTyreLoad"
                          labelText="Rear tyre load & speed rating"
                          placeholder="Enter rear tyre load & speed rating"
                          disabled={true}
                          requiredStar
                        />
                      </GridColumn>
                    </GridRow>
                    {/* custom input row 6 */}
                    <GridRow columns={1} style={{ paddingBottom: '0px' }}>
                      <GridColumn width={8}>
                        <PdfUploadComponent
                          id="approvalDocument"
                          name="approvalDocument"
                          label="Existing Vehicle import approval?"
                          type="import_approval"
                          document={values?.approvalDocument || null}
                          requiredStar
                          disabled={true}
                        />
                      </GridColumn>
                    </GridRow>
                  </Grid>
                </Formik>
              </div>
            </div>
          </div>
        </ModalContent>
        <ModalActions>
          <Button className="btn btn-primary cancel-button" onClick={close}>
            Cancel
          </Button>

          <Button className="close-button" onClick={confirmation}>
            Confirm
          </Button>
        </ModalActions>
      </Modal>
    </>
  );
};

export default ComplianceConfirmationModal;
