/*** TYPES ***/
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import {getCommonError } from '../../utils';

//types

const ADD_COMMENT_LOADING = 'ADD_COMMENT_LOADING';
const PATCH_COMMENT_LOADING = 'PATCH_COMMENT_LOADING';
/*** REDUCERS ***/
const initialState = {
  addCommentLoading: false,
  updateCommentLoading:false

};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_COMMENT_LOADING:
      return { ...state, addCommentLoading: action.payload };
      case PATCH_COMMENT_LOADING:
      return { ...state, updateCommentLoading: action.payload };
      
    default:
      return state;
  }
}



export function postApplicationComments(values) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
    try {
      dispatch({ type: ADD_COMMENT_LOADING, payload: true });
      const response=await axios.post(`/comments`, values);
      dispatch({ type: ADD_COMMENT_LOADING, payload: false });
      resolve(response.data);
      NotificationManager.success('Comment Added success', 'Success');
    } catch (error) {
      dispatch({ type: ADD_COMMENT_LOADING, payload: false });
      NotificationManager.error(getCommonError(error));
      reject(getCommonError(error));
      }
    });
  };
}

export function patchApplicationComment(commentId,values) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
    try {
      dispatch({ type: PATCH_COMMENT_LOADING, payload: true });
      const response=await axios.patch(`/comments/${commentId}`, values);
      dispatch({ type: PATCH_COMMENT_LOADING, payload: false });
      resolve(response.data);
      NotificationManager.success('Comment Updated success', 'Success');
    } catch (error) {
      dispatch({ type: PATCH_COMMENT_LOADING, payload: false });
      NotificationManager.error(getCommonError(error));
      reject(getCommonError(error));
      }
    });
  };
}

