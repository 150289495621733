import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { openCustomerSideBar } from '../store/modules/clientSide';
import { useDispatch } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';

const CustomButton = ({ setVisible = null, text ,isCustomer=false}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleNewClick = () => {
    if (setVisible) {
      setVisible(true);
    }
    if(isCustomer){
      dispatch(openCustomerSideBar(true));

      if(isMobile){
        navigate('/customer-form');
      }
    }
  };
  return (
    <Button className="custom-button-add" onClick={handleNewClick}>
      <Icon name="plus" />
      {text}
    </Button>
  );
};

export default CustomButton;
