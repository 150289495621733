import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import AppRouting from './routing';
import { store, persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import 'semantic-ui-css/semantic.min.css';
import SentryBoundary from './utils/errorBoundary';
import axios from 'axios';
import { BASE_URL } from './utils/constant';
import { initializedApp } from './store/actions';
import 'react-notifications/lib/notifications.css';
import NetworkDetectorHoc from './lib/networkDetectorHoc';
store.dispatch(initializedApp());

// let requestMethod = null;
axios.defaults.baseURL = BASE_URL;
axios.defaults.timeout = 60000;
axios.defaults.headers.common['Pragma'] = 'no-cache';


// axios.interceptors.response.use(
//   response => {
//     return response;
//   },
//   error => {
//     return Promise.reject(error);
//   }
// );

// axios.interceptors.response.use(undefined, (error) =>
//   Promise.reject({
//     msg: getApiError(error),
//     status: error.response.status || 500,
//   }),
// );
// const userLogOut = async () => {
//   await userAuth.signout(() => {
//     persistor.purge();
//   });
//   const pathname = window.location.pathname;
//   history.push({
//     pathname: '/',
//     state: {
//       redirectRoute: pathname,
//       logoutReason: 'Session Expired',
//     },
//   });
// };

const IndexApp = (props) => {
  const [view, setView] = useState(false);

  useEffect(() => {
    setView(true);
  }, []);

  if (view) {
    return (
      <>
        {/* <FullStory
          // org="key"
          debug={process.env.REACT_APP_ENV_VALUE !== 'production'}
        /> */}
        {props.children}
      </>
    );
  } else {
    return null;
  }
};

export default IndexApp;

// const AppWrapper = () => {
//   const states = useSelector((state)=>state);
//   const dispatch = useDispatch();

//   return <IndexApp states={states} dispatch={dispatch} />;
// };

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <SentryBoundary>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {/* <AppWrapper> */}
        <NetworkDetectorHoc>
        <AppRouting />
        </NetworkDetectorHoc>
        {/* </AppWrapper> */}
      </PersistGate>
    </Provider>
  </SentryBoundary>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
