import React from 'react'
import {
  ModalHeader,
  ModalContent,
  ModalActions,
  Button,
  Modal,
} from 'semantic-ui-react'

import './modal.scss';

const CustomModalInfo = ({open,onToggle,header="",content=''}) => {
  return (
    <>
      <Modal
        closeIcon
        size='small'
        open={open}
        onClose={onToggle}
      >
        <ModalHeader>{header}</ModalHeader>
        <ModalContent>
          <p>{content}</p>
        </ModalContent>
        <ModalActions>
          <Button className='close-button' onClick={onToggle}>
            Close
          </Button>
        </ModalActions>
      </Modal>
    </>
  )
}

export default CustomModalInfo;
