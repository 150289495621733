import React, { useEffect, useState } from 'react';
import { Grid, GridRow, GridColumn, Label, Icon } from 'semantic-ui-react';
import { useField } from 'formik';
import DeletionModal from '../modals/deletionModal';

const PdfUploadComponent = ({
  label,
  type,
  handleFileUpload = null,
  document,
  uploadDocumentInputRef = null,
  handleDocumentDelete,
  disabled,
  getValue,
  uploadError,
  requiredStar = false,
  onlyPdf = false,
  ...props
}) => {
  const [, meta, helpers] = useField(props);
  const { setValue } = helpers;

  const [deletionOpen, setDeletionOpen] = useState(false);

  useEffect(() => {
    if (document) {
      setValue(document);
    } else {
      setValue('');
    }
  }, [document, setValue]);

  const handleUploadDocumentButtonClick = () => {
    if (uploadDocumentInputRef.current) {
      uploadDocumentInputRef.current.click();
    }
  };

  const handleReplaceButtonClick = () => {
    if (uploadDocumentInputRef.current) {
      uploadDocumentInputRef.current.click();
    }
  };

  const handelDeletionClose = () => {
    setDeletionOpen(false);
  };

  const handelDeletion = () => {
    setDeletionOpen(false);
    handleDocumentDelete();
  };

  const handelDeletionModel = () => {
    setDeletionOpen(true);
  };

  const renderDocumentPreview = () => {
    let objectUrl;

    // Check if document.file is an actual File object or a URL encapsulated in an object
    if (document && document.file) {
      if (document.file instanceof File) {
        objectUrl = URL.createObjectURL(document.file);
      } else if (document.file.url) {
        objectUrl = document.file.url;
      }

      return (
        <div className="document-preview-container" key={objectUrl}>
          <embed
            src={objectUrl}
            type="application/pdf"
            width="180"
            height="180"
            key={objectUrl}
          />
          {!disabled && (
            <div className="document-actions">
              <Label
                className="document-delete-button"
                onClick={handelDeletionModel}
              >
                <Icon name="trash alternate outline" />
              </Label>
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <DeletionModal
        open={deletionOpen}
        header={`Delete ${label} Vehicle document`}
        content={`Are you sure want to delete this ${label} document?`}
        close={handelDeletionClose}
        confirmation={handelDeletion}
      />

      <GridColumn>
        <Grid>
          <GridRow columns={1}>
            <GridColumn>
              <label className={requiredStar ? 'required-stars' : ''}>
                {label}
                <span className="small-label-gray">
                  {' '}
                  {onlyPdf ? '(PDF only)' : '(PDF/ Image)'}
                </span>
              </label>
            </GridColumn>
          </GridRow>
          <GridRow columns={1}>
            <GridColumn>
              <input
                type="file"
                accept=".pdf"
                style={{ display: 'none' }}
                ref={uploadDocumentInputRef}
                onChange={handleFileUpload}
                onClick={(event) => {
                  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
                      event.target.setAttribute("accept", "application/pdf");
                  }
              }}
              />

              <div className="vehicle-doc-container">
                {document
                  ? renderDocumentPreview()
                  : !disabled && (
                      <label
                        className="label-blue"
                        onClick={handleUploadDocumentButtonClick}
                      >
                        <Icon name="upload" />
                        Upload
                      </label>
                    )}
              </div>
            </GridColumn>
          </GridRow>
          <GridRow>
            <GridColumn width={10}>
              {uploadError && ( // Check if there's an error and display it
                <div className="validation-error-msg">{uploadError}</div>
              )}

              {meta.touched && meta.error && (
                <div className="invalid-feedback">{meta.error}</div>
              )}
            </GridColumn>
          </GridRow>
          {document &&
            !disabled && ( // This line checks if `document` exists before rendering the 'Replace' button
              <GridRow columns={1} style={{ paddingTop: '0px' }}>
                <GridColumn>
                  <div className="replace-button">
                    <label
                      className="label-blue"
                      onClick={handleReplaceButtonClick}
                    >
                      <i className="sync icon"></i>
                      Replace
                    </label>
                  </div>
                </GridColumn>
              </GridRow>
            )}
        </Grid>
      </GridColumn>
    </>
  );
};

export default PdfUploadComponent;
