import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Grid,
  GridColumn,
  GridRow,
  List,
  ListItem,
  Popup,
} from 'semantic-ui-react';
import { getAllNotifications, updateNotificationIsRead, updateNotificationIsReadAll } from '../../store/modules/notifications';
import Avatar from 'react-avatar';
import { useNavigate } from 'react-router-dom';
import { SocketConnection } from '../../utils/socketConnection';
import { getAuthenticatedToken } from '../../utils/cacheStore';

const NotificationPanel = ({ open, onClose, indicator }) => {
  const dispatch = useDispatch();
  const notificationList = useSelector(
    (state) => state.notifications.notifications
    );

  const userData = useSelector((state) => state.auth.user);

  const navigate = useNavigate();
      useEffect(() => {
        dispatch(getAllNotifications());
      }, [dispatch, open]);
      useEffect(() => {
        const token= getAuthenticatedToken();
        let socket = SocketConnection(token);
        
        if (userData !== null && token !==null) {
          socket.on('formSubmitted', () => {
            indicator();
            dispatch(getAllNotifications());
          });
        }
        return () => {
          if (socket !== undefined) {
            socket.close();
            socket.removeAllListeners();
          }
        };
      }, [dispatch, indicator,userData]);


      const onNotificationClicked = (id,appId) => {
        navigate(`/notification-app/${appId}`)
        onClose();
        dispatch(updateNotificationIsRead(id))
      };


  const onMarkAll=()=>{
    dispatch(updateNotificationIsReadAll());
    onClose();
  }

  function formatTimeAgo(createdAt) {
    const notificationDate = new Date(createdAt);
    const currentDate = new Date();

    const timeDifference = currentDate.getTime() - notificationDate.getTime();
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 1) {
      const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      };
      return notificationDate.toLocaleDateString('en-US', options);
    } else if (days === 1) {
      const options = { hour: 'numeric', minute: 'numeric' };
      return `Yesterday at ${notificationDate.toLocaleTimeString(
        'en-US',
        options
      )}`;
    } else if (hours >= 1) {
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (minutes >= 1) {
      return `${minutes} min${minutes > 1 ? 's' : ''} ago`;
    } else {
      return 'Just now';
    }
  }

  return (
    <Popup
      trigger={<span />}
      open={open}
      onClose={onClose}
      on="click"
      position="bottom right"
      style={{
        transform: 'translate3d(-12px, -20px, 0px)',
        minWidth: '350px',
      }}
    >
      <Popup.Content>
        <div className="notification-popup-content">
          <Grid>
            <GridRow columns={2}>
              <GridColumn
                width={8}
                style={{
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'center',
                }}
              >
                <label className="header-text">
                  Notification{' '}
                  <span style={{ color: 'red' }}>
                    {notificationList.notReadNotifications}
                  </span>
                </label>
              </GridColumn>
              {notificationList.notReadNotifications >0 ?
              <GridColumn
                width={8}
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                }}
              >
                <div
                  className="hyperlink-text"
                  onClick={() => onMarkAll()}
                >
                  Mark all as read
                </div>
              </GridColumn>
               : null}
            </GridRow>

            <hr />

            <GridRow
              columns={1}
              style={{ paddingTop: '0px', paddingBottom: '0px' }}
            >
              <GridColumn width={16} style={{ padding: '0px' }}>
                <div className="notification-list-element">
                  <List selection>
                    {notificationList.data &&
                      notificationList.data.length > 0 &&
                      notificationList.data.map((notification, index) => (
                        <ListItem
                          onClick={() => {
                            onNotificationClicked(notification.id,notification.applicationId);
                          }}
                        >
                          <Grid>
                            <GridRow
                              columns={3}
                              style={{
                                paddingBottom: '0px',
                              }}
                            >
                              <GridColumn
                                width={3}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                <div className="cus-img">
                                  <Avatar
                                    name={notification.name}
                                    size="35"
                                    round={true}
                                  />
                                </div>
                              </GridColumn>
                              <GridColumn
                                width={11}
                                style={{
                                  paddingLeft: '0px',
                                  paddingRight: '0px',
                                }}
                              >
                                <label className="normal-text">
                                  {notification.message}
                                </label>
                              </GridColumn>
                              <GridColumn
                                width={2}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                {!notification.isRead ? (
                                  <div className="read-badge"></div>
                                ) : null}
                              </GridColumn>
                            </GridRow>
                            <GridRow
                              columns={1}
                              style={{
                                paddingTop: '0px',
                              }}
                            >
                              <GridColumn
                                width={16}
                                style={{
                                  display: 'flex',
                                  justifyContent: 'start',
                                  alignItems: 'center',
                                }}
                              >
                                <label className="time-label">
                                  {formatTimeAgo(notification.createdAt)}
                                </label>
                              </GridColumn>
                            </GridRow>
                          </Grid>
                        </ListItem>
                      ))}
                  </List>
                </div>
              </GridColumn>
            </GridRow>
          </Grid>
        </div>
      </Popup.Content>
    </Popup>
  );
};

export default NotificationPanel;
