import * as Yup from 'yup';
import { get } from '../../utils/lodash';

export const updateCustomerValidation = Yup.object().shape({
  name: Yup.string().required('Please enter your name'),
  email: Yup.string()
    .email('Please enter valid email address')
    .required('Please Enter your email address.'),
  contactNo: Yup.number().required('Please enter your contact number'),
  abn: Yup.string().required('Please enter ABN number'),
});

export const customerUpdateInitialValues = (data) => {
  let form = {};
  form.name = get(data, 'name', '');
  form.email = get(data, 'email', '');
  form.contactNo = get(data, 'contactNo', '');
  form.businessName = get(data, 'businessName', '');
  form.abn = get(data, 'abn', '');
  return form;
};
