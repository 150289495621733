import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Avatar from 'react-avatar';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Grid,
  Image,
  Label,
  List,
  ListContent,
  ListItem,
  Segment,
} from 'semantic-ui-react';
import { get, isEmpty } from '../../utils/lodash';
import { EmptyContainer } from '../../components/emptyContainer';
import emptyBoxIcon from '../../assets/icons/empty-folder.svg';
import {
  getApplicationType,
  getChassisVinNo,
  getUniqueVehicleNo,
} from '../../utils';
import '../../components/styles.scss';
import CustomPagination from '../../components/customPagination';
import { SearchAndDropDownComponent } from '../../components/customTopSegment';

const ApplicationList = ({
  applicationData,
  onApplicationClicked = null,
  applicationLayout = false,
  onSearchOnchange,
  onDashboardDropdown,
  loading,
  page,
  pageChange,
  dashboard = false,
}) => {
  const noData = isEmpty(applicationData.data);
  const navigate = useNavigate();
  const [isMobileApplicationView, setMobileApplicationView] = useState(false);

  const renderThreeRows = (item) => {
    const getUniqueChassisNo = getChassisVinNo(
      item.formTypeId,
      item.formResponses
    );
    const referenceNo = get(item, 'referenceNo', '-');
    return (
      <>
        <Grid.Row
          columns={2}
          style={{ paddingTop: '10px', paddingBottom: '5px' }}
        >
          <Grid.Column style={{ paddingRight: '0px' }} width={7}>
            <div className="vehicle-import">
              <span className="vehicle-import-bold">
                {getApplicationType(item.formTypeId)} :{' '}
              </span>
              {referenceNo ? referenceNo.toUpperCase() : '-'}
            </div>
          </Grid.Column>
          <Grid.Column style={{ paddingLeft: '0px' }} width={9}>
            <div className="create-on">
              <Label className="unique-no-badge" color="##EEEEEE" size="tiny">
                {getUniqueVehicleNo(item.formTypeId)}
              </Label>
              <span className="unique-on-bold">
                {getUniqueChassisNo ? getUniqueChassisNo.toUpperCase() : '-'}
              </span>
            </div>
          </Grid.Column>
        </Grid.Row>
        <div className="application-recent-layout">
          <Grid.Row style={{ paddingTop: '5px', paddingBottom: '5px' }}>
            <Grid.Column style={{ paddingRight: '0px' }} width={7}>
              <div className="vehicle-import">
                <span className="vehicle-import-bold">Created On :</span>
                <label className="createdAt">
                  {moment(item.createdAt).format('DD-MM-YYYY hh:mm a')}
                </label>
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row style={{ paddingTop: '5px', paddingBottom: '20px' }}>
            <Grid.Column style={{ paddingRight: '0px' }} width={7}>
              <div className="vehicle-import">
                <span className="vehicle-import-bold">Last Updated On :</span>
                <label className="createdAt">
                  {moment(item.updatedAt).format('DD-MM-YYYY hh:mm a')}
                </label>
              </div>
            </Grid.Column>
          </Grid.Row>
        </div>
      </>
    );
  };

  const renderOneRow = (item) => {
    const referenceNo = get(item, 'referenceNo', '-');
    return (
      <Grid.Row columns={2} style={{ paddingTop: '5px' }}>
        <Grid.Column style={{ paddingRight: '0px' }} computer={7} tablet={16} mobile={16}>
          <div className="vehicle-import">
            <span className="vehicle-import-bold">
              {getApplicationType(item.formTypeId)} :{' '}
            </span>
            {referenceNo ? referenceNo.toUpperCase() : '-'}
          </div>
        </Grid.Column>
        <Grid.Column style={{ paddingLeft: '0px' }} computer={9} tablet={16} mobile={16}> 
          <div className="create-on">
            <span className="create-on-bold">Created On : </span>
            {moment(item.createdAt).format('DD-MM-YYYY hh:mm a')}
          </div>
        </Grid.Column>
      </Grid.Row>
    );
  };

  if (isMobileApplicationView) return null;
  return (
    <div className="application-list-view">
      {!applicationLayout && !dashboard && (
        <SearchAndDropDownComponent
          onSearchOnchange={onSearchOnchange}
          noData={noData}
          onDashboardDropdown={onDashboardDropdown}
        />
      )}
      <Segment className="application-list-element" attached loading={loading}>
        {!noData ? (
          <List divided selection>
            {applicationData.data.map((item, index) => {
              return (
                <ListItem
                  className="application-list-item"
                  key={index}
                  onClick={() => {
                    if (isMobile) {
                      setMobileApplicationView(true);
                      const mobileClicked = true;
                      onApplicationClicked(item, mobileClicked);
                    } else {
                      onApplicationClicked(item);
                    }
                  }}
                >
                  <Grid>
                    <Grid.Row columns={3} style={{ paddingBottom: '0px' }}>
                      <Grid.Column
                        computer={2}
                        tablet={1}
                        mobile={2}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <div className="cus-img">
                          {get(item, 'userData.profilePic', '') === '' ? (
                            <Avatar
                              name={get(item, 'userData.name', '')}
                              size="35"
                              round={true}
                            />
                          ) : (
                            <Image
                              avatar
                              src={get(item, 'userData.profilePic', '')}
                            />
                          )}
                        </div>
                      </Grid.Column>
                      <Grid.Column
                      computer={10}
                       tablet={10}
                       mobile={8}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          paddingLeft: '10px',
                        }}
                      >
                        <div className="cus-name">
                          {get(item, 'userData.name')}
                        </div>
                      </Grid.Column>
                      <Grid.Column computer={4} tablet={4} mobile={1}>
                        <div className="button-padding-controller">
                          <ListContent floated="right">
                            <Button
                              className={
                                item.status === 'LODGED'
                                  ? 'lodged-button'
                                  : 'submitted-button'
                              }
                            >
                              {item.status}
                            </Button>
                          </ListContent>
                        </div>
                      </Grid.Column>
                    </Grid.Row>
                    {applicationLayout
                      ? renderThreeRows(item)
                      : renderOneRow(item)}
                  </Grid>
                </ListItem>
              );
            })}
          </List>
        ) : (
          <EmptyContainer
            classes={`no-data`}
            msg={'No Applications'}
            img={emptyBoxIcon}
            size="tiny"
          />
        )}
      </Segment>
      {/* Footer Section */}
      {!isEmpty(applicationData.data) && (
        <Segment
          className={
            applicationLayout
              ? 'application-list-footer-with-pagination'
              : 'application-list-footer'
          }
          attached="bottom"
        >
          {applicationLayout ? (
            <CustomPagination
              className="custom-pagination"
              handlePaginationChange={(page) => {
                pageChange(page);
              }}
              page={page}
              totalPages={applicationData.lastPage}
            />
          ) : (
            <div
              className="footer-content"
              onClick={() => navigate('/applications')}
            >
              View All Applications
            </div>
          )}
        </Segment>
      )}
    </div>
  );
};

ApplicationList.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
};

export default ApplicationList;
