import React from 'react';
import { Button, Container, Grid } from 'semantic-ui-react';
import { useNavigate } from 'react-router-dom';
import './auth.scss';
import { useDispatch, useSelector } from 'react-redux';
import { unsubscribeEmailService } from '../../store/modules/user';
import { UnAuthorizedAccess } from '../../components/emptyContainer';
import { isEmpty } from '../../utils/lodash';
import { EMAIL_BLOCKING } from '../../utils/constant';
const UnsubscribeEmail = () => {
  const navigate = useNavigate();
  const loading= useSelector((state)=>state.user.unsubscribeLoading);
  const userDetails = useSelector((state) => state.auth.user);
  const dispatch=useDispatch()

const handleSubmit=()=>{
  try {
    const data={
        email:userDetails.email,
        type:EMAIL_BLOCKING.UNSUBSCRIBE
    }
    dispatch(unsubscribeEmailService(data));
    navigate('/dashboard');
  } catch (err) {
    
  }
}
    if(isEmpty(userDetails)) return <UnAuthorizedAccess/>
  return (
    <Grid.Row className="auth">
      <Grid.Column mobile={16} tablet={16} computer={8} />
      <Grid.Column mobile={16} tablet={16} computer={8}>
        <Container className="right-heading-container">
          <label className="headerText">Unsubscribe Email Service</label>
          <br></br>
          <p>Are you sure to unsubscribe the email service?</p>
          <Button
                content={'Unsubscribe Email Service'}
                type="submit"
                className="btn btn-primary signin-button"
                loading={loading}
                disabled={loading}
                onClick={()=>handleSubmit()}
              />
        </Container>
      </Grid.Column>
      <Grid.Column mobile={16} tablet={5} computer={5} />
    </Grid.Row>
  );
};

export default UnsubscribeEmail;
