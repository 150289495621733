import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Grid, GridColumn, GridRow, Icon, Label } from 'semantic-ui-react';
import BluePlus from '../../assets/icons/menu/plus.svg';
import EmptyImage from '../../assets/icons/empty-folder.svg';
import { useField } from 'formik';
import DeletionModal from '../modals/deletionModal';

const ImageUploadComponent = ({
  images,
  setImages,
  label,
  inputRef,
  onInputChange,
  uploadErrors,
  smallContainer,
  disabled = false,
  getValue,
  requiredStar,
  ...props
}) => {
  const [, meta, helpers] = useField(props);
  const { setValue } = helpers;

  const [deletionOpen, setDeletionOpen] = useState(false);
  const [tempIndex, setTempIndex] = useState(null);

  useEffect(() => {
    if (images.length > 0) {
      setValue(images);
    } else {
      setValue('');
    }
  }, [images, setValue]);

  const handleAddImagesClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleDeleteImage = (index) => {
    const updatedImages = images.filter((_, i) => i !== index);
    setImages(updatedImages);
  };

  const handelDeletionClose = () => {
    setDeletionOpen(false);
    setTempIndex(null);
  };

  const handelDeletion = () => {
    setDeletionOpen(false);
    handleDeleteImage(tempIndex);
    setTempIndex(null);
  };

  const handelDeletionModel = (index) => {
    setDeletionOpen(true);
    setTempIndex(index);
  };

  return (
    <>
      <DeletionModal
        open={deletionOpen}
        header={`Delete ${label} Vehicle Photo`}
        content={`Are you sure want to delete this ${label} photo?`}
        close={handelDeletionClose}
        confirmation={handelDeletion}
      />
      <GridRow columns={1} style={{ paddingBottom: '0px' }}>
        <GridColumn>
          <Grid>
            <GridRow columns={2}>
              <GridColumn width={smallContainer ? 13 : 14}>
                <label className={requiredStar ? 'required-stars' : ''}>
                  {label}
                  <span className="small-label-gray"> </span>
                  {/* <span className="small-label-black">Optional</span> */}
                </label>
              </GridColumn>
              <GridColumn width={smallContainer ? 3 : 2}>
                {!isMobile && !disabled ? (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <img
                      src={BluePlus}
                      size="tiny"
                      style={{ paddingRight: '5px' }}
                      alt="menu icon"
                    />
                    <label
                      className="label-blue"
                      onClick={handleAddImagesClick}
                    >
                      Add More
                    </label>
                  </div>
                ) : (
                  !disabled && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'end',
                      }}
                    >
                      <img
                        src={BluePlus}
                        size="tiny"
                        style={{ paddingRight: '5px' }}
                        alt="menu icon"
                      />
                      <label
                        className="label-blue"
                        onClick={handleAddImagesClick}
                      >
                        Add More
                      </label>
                    </div>
                  )
                )}
              </GridColumn>
            </GridRow>
            <GridRow>
              <div className="vehicle-image-container">
                {images.length === 0 ? (
                  <div
                    className="image-item"
                    style={{ paddingLeft: '50px' }}
                    onClick={handleAddImagesClick}
                  >
                    <img
                      src={EmptyImage}
                      alt="Empty Folder"
                      className="empty-image"
                    />
                    {/* <label>Please add the images</label> */}
                  </div>
                ) : (
                  images.map((image, index) => (
                    <div key={index} className="image-item">
                      <div className="document-actions">
                        {!disabled && (
                          <Label
                            className="document-delete-button"
                            style={{
                              marginRight: images.length > 1 ? '15px' : '25px',
                            }}
                            onClick={() => handelDeletionModel(index)}
                          >
                            <Icon name="trash alternate outline" />
                          </Label>
                        )}
                      </div>
                      <img
                        src={
                          image.file
                            ? URL.createObjectURL(image.file)
                            : image.url
                        }
                        alt={`${label.replace(/\s+/g, '')}${index + 1}`}
                        className="vehicle-image"
                      />
                    </div>
                  ))
                )}
              </div>
              <input
                type="file"
                ref={inputRef}
                style={{ display: 'none' }}
                onChange={onInputChange}
                accept="image/*"
                multiple
              />
            </GridRow>
            <GridRow style={{ paddingTop: '0px' }}>
              <GridColumn width={10}>
                {uploadErrors &&
                  uploadErrors.map((error, index) => (
                    <div key={index} className="validation-error-msg">
                      {error}
                    </div>
                  ))}

                {meta.touched && meta.error && (
                  <div className="invalid-feedback">{meta.error}</div>
                )}
              </GridColumn>
            </GridRow>
          </Grid>
        </GridColumn>
      </GridRow>
    </>
  );
};

export default ImageUploadComponent;
