import React, { useState } from 'react';
import '../admin/dashboard/dashboard.scss';
import ApplicationList from './applicationList';
import { useDispatch } from 'react-redux';

const RecentApplicationList = (props) => {
  const [search, setSearchKeyword] = useState('');
  const [type, setType] = useState('');
  const dispatch = useDispatch();

  const onSearchOnchange = (e, { value }) => {
    setSearchKeyword(value);
    dispatch(props.getApplicationsFunctionProp(value, type));
  };

  const onDashboardDropdown = (value) => {
    setType(value);
    dispatch(props.getApplicationsFunctionProp(search, value));
  };

  return (
    <ApplicationList
      {...props}
      onSearchOnchange={onSearchOnchange}
      onDashboardDropdown={onDashboardDropdown}
    />
  );
};

export default RecentApplicationList;
