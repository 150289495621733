import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, GridRow } from 'semantic-ui-react';
import CustomTopSegment from '../../components/customTopSegment';
import { SelectApplicationContainer } from '../../components/emptyContainer';
import {
  getAllApplications,
  getApplications,
  getCustomerApplications,
} from '../../store/modules/applications';
import { find, isEmpty } from '../../utils/lodash';
import { useNavigate, useParams } from 'react-router-dom';
import { RenderApplicationPageApplications } from '../../components/ApplicationView';
import { applicationSelectionLoading } from '../../store/actions';
const Applications = () => {
  const [selectedApp, setSelectedApplication] = useState(null);
  const [search, setSearchKeyword] = useState('');
  const [type, setType] = useState('');
  const [status, setStatus] = useState('');
  const [isInActive, setInActive] = useState(false);
  const [page, setPage] = useState(1);
  const [mobileClick, setMobileClick] = useState(false);

  const initialPage = 1;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();

  const userDetails = useSelector((state) => state.auth.user);
  const isCustomer = userDetails.roleId === 2 ? true : false;

  const onSearchOnchange = (e, { value }) => {
    setSearchKeyword(value);
    dispatch(
      getApplications(value, type, status, isInActive, page, isCustomer)
    );
  };

  const onApplicationClicked = (id, mobileClickVal) => {
    setMobileClick(mobileClickVal);
    setSelectedApplication(id);
    navigate(`/application/${id.id}`);
    dispatch(applicationSelectionLoading());
  };

  const applicationViewLoading=useSelector((state)=>state.clientSide.applicationSelectionLoading);

  const onCustomApplicationTypeDropdownChange = (data) => {
    setType(data);
    setPage(initialPage);
    dispatch(
      getApplications(search, data, status, isInActive, initialPage, isCustomer)
    );
  };

  const onCustomApplicationStatusDropdownChange = (data) => {
    setStatus(data);
    setPage(initialPage);
    dispatch(
      getApplications(search, type, data, isInActive, initialPage, isCustomer)
    );
  };

  const onChangeToggle = () => {
    setInActive((prevIsInActive) => !prevIsInActive);
    setPage(initialPage);
    dispatch(
      getApplications(
        search,
        type,
        status,
        !isInActive,
        initialPage,
        isCustomer
      )
    );
  };

  const pageChange = (pageValue) => {
    setPage(pageValue);
    dispatch(
      getApplications(search, type, status, isInActive, pageValue, isCustomer)
    );
  };

  useEffect(() => {
    if (!isEmpty(userDetails)) {
      if (isCustomer) {
        dispatch(getCustomerApplications());
      } else {
        dispatch(getAllApplications());
      }
    }
  }, [dispatch, isCustomer, userDetails]);

  const applications = useSelector(
    (state) =>
      state.applications[isCustomer ? 'cxApplications' : 'allApplications']
  );

  const loading = useSelector(
    (state) =>
      state.applications[
        isCustomer ? 'cxApplicationLoading' : 'applicationLoading'
      ]
  );

  useEffect(() => {
    if (id) {
      const applicationFromParams = find(
        applications.data,
        (item) => item.id === Number(id)
      );
      if (applicationFromParams) {
        setSelectedApplication(applicationFromParams);
      }
    }
  }, [id, applications]);

  if (isEmpty(userDetails)) return null;

  const renderEmptyLayout = () => {
    return (
      <SelectApplicationContainer
        classes={`no-data`}
        msg={'At the moment, No applications to display!'}
      />
    );
  };

  const filterObject = {
    status,
    type,
    page,
    pageChange: pageChange,
  };

  return (
    <div>
      <CustomTopSegment
        title="Applications"
        isApplication
        onSearchOnchange={onSearchOnchange}
        onCustomTypeDropdownChange={onCustomApplicationTypeDropdownChange}
        onCustomStatusDropdownChange={onCustomApplicationStatusDropdownChange}
        onChangeToggle={onChangeToggle}
        status={status}
        type={type}
        isInActive={isInActive}
      />
      <Grid stackable style={isMobile ? { marginTop: '50px' } : {}}>
        <GridRow className="custom-row-app-home">
          {isEmpty(applications.data)
            ? renderEmptyLayout()
            : RenderApplicationPageApplications(
                onApplicationClicked,
                filterObject,
                applications,
                loading,
                getAllApplications,
                mobileClick,
                selectedApp,
                applicationViewLoading
              )}
        </GridRow>
      </Grid>
    </div>
  );
};

export default Applications;
