import React, { useEffect, useState } from 'react';
import { Grid, GridRow, GridColumn, Label, Icon } from 'semantic-ui-react';
import { useField } from 'formik';
import DeletionModal from '../modals/deletionModal';

const PdfAndImageUploadComponent = ({
  label,
  type,
  handleFileUpload = null,
  document,
  uploadDocumentInputRef = null,
  handleDocumentDelete,
  disabled,
  getValue,
  uploadError,
  requiredStar = false,
  onlyPdf = false,
  ...props
}) => {
  const [, meta, helpers] = useField(props);
  const { setValue } = helpers;

  const [deletionOpen, setDeletionOpen] = useState(false);

  useEffect(() => {
    if (document) {
      setValue(document);
    } else {
      setValue('');
    }
  }, [document, setValue]);

  const handleUploadDocumentButtonClick = () => {
    if (uploadDocumentInputRef.current) {
      uploadDocumentInputRef.current.click();
    }
  };

  const handleReplaceButtonClick = () => {
    if (uploadDocumentInputRef.current) {
      uploadDocumentInputRef.current.click();
    }
  };

  const handelDeletionClose = () => {
    setDeletionOpen(false);
  };

  const handelDeletion = () => {
    setDeletionOpen(false);
    handleDocumentDelete();
  };

  const handelDeletionModel = () => {
    setDeletionOpen(true);
  };

  function isImageType(fileType) {
    return fileType && fileType.startsWith('image/');
  }

  function isImageExtension(fileUrl) {
    return (
      fileUrl &&
      (fileUrl.endsWith('.jpeg') || fileUrl.endsWith('.JPEG') ||
        fileUrl.endsWith('.png') || fileUrl.endsWith('.PNG') ||
        fileUrl.endsWith('.jpg') ||   fileUrl.endsWith('.JPG') ||
        fileUrl.endsWith('.gif') || fileUrl.endsWith('.png')
        )
    );
  }

  const renderDocumentPreview = () => {
    let objectUrl;

    if (document && document.file) {
      if (document.file instanceof File) {
        objectUrl = URL.createObjectURL(document.file);
      } else if (document.file.url) {
        objectUrl = document.file.url;
      }
      return (
        <div className="document-preview-container" key={objectUrl}>
          {(document.file && isImageType(document.file.type)) ||
          isImageExtension(document.file.url) ? (
            <img
              src={objectUrl}
              alt="Preview"
              width="180"
              height="180"
              key={objectUrl}
            />
          ) : (
            <embed
              src={objectUrl}
              type="application/pdf"
              width="180"
              height="180"
              key={objectUrl}
            />
          )}

          {!disabled && (
            <div className="document-actions">
              <Label
                className="document-delete-button"
                onClick={handelDeletionModel}
              >
                <Icon name="trash alternate outline" />
              </Label>
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <DeletionModal
        open={deletionOpen}
        header={`Delete ${label} Vehicle document`}
        content={`Are you sure want to delete this ${label} document?`}
        close={handelDeletionClose}
        confirmation={handelDeletion}
      />

      <GridColumn>
        <Grid>
          <GridRow columns={1}>
            <GridColumn>
              <label className={requiredStar ? 'required-stars' : ''}>
                {label}
                <span className="small-label-gray">
                  {' '}
                  {onlyPdf ? '(PDF only)' : '(PDF/ Image)'}
                </span>
              </label>
            </GridColumn>
          </GridRow>
          <GridRow columns={1}>
            <GridColumn>
              <input
                type="file"
                accept=".pdf, .jpg, .jpeg, .png, .gif"
                style={{ display: 'none' }}
                ref={uploadDocumentInputRef}
                onChange={handleFileUpload}
              />

              <div className="vehicle-doc-container">
                {document
                  ? renderDocumentPreview()
                  : !disabled && (
                      <label
                        className="label-blue"
                        onClick={handleUploadDocumentButtonClick}
                      >
                        <Icon name="upload" />
                        Upload
                      </label>
                    )}
              </div>
            </GridColumn>
          </GridRow>
          <GridRow>
            <GridColumn width={10}>
              {uploadError && (
                <div className="validation-error-msg">{uploadError}</div>
              )}

              {meta.touched && meta.error && (
                <div className="invalid-feedback">{meta.error}</div>
              )}
            </GridColumn>
          </GridRow>
          {document && !disabled && (
            <GridRow columns={1} style={{ paddingTop: '0px' }}>
              <GridColumn>
                <div className="replace-button">
                  <label
                    className="label-blue"
                    onClick={handleReplaceButtonClick}
                  >
                    <i className="sync icon"></i>
                    Replace
                  </label>
                </div>
              </GridColumn>
            </GridRow>
          )}
        </Grid>
      </GridColumn>
    </>
  );
};

export default PdfAndImageUploadComponent;
