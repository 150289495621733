import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  useParams } from 'react-router-dom';
import { Grid, GridRow } from 'semantic-ui-react';
import CustomTopSegment from '../../../components/customTopSegment';
import {
  UnAuthorizedAccess,
} from '../../../components/emptyContainer';
import { getCustomerApplications, getOneCustomerApplication } from '../../../store/actions';
import { isEmpty } from '../../../utils/lodash';
import { RenderApplicationPageApplications } from '../../../components/ApplicationView';
import { isMobile } from 'react-device-detect';
const CustomerApplications = () => {
  const [selectedApp, setSelectedApplication] = useState(null);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [type, setType] = useState('');
  const [status, setStatus] = useState('');
  const [isInActive, setInActive] = useState(false);
  const dispatch = useDispatch();
  const [userId, setUserId] = useState('');
  const [inValidUserId, setInValidUserId] = useState(false);
  const[page,setPage]=useState(1);
  const {id} = useParams();
  const [mobileClick,setMobileClick]=useState(false);
  useEffect(() => {
    if (!isEmpty(id)) {
      if (id) {
        setUserId(id);
      } else {
        setInValidUserId(true);
      }
    }
  }, [id]);

  const getCxApplicationWithFilters = (values) => {
    dispatch(getOneCustomerApplication(userId, values));
  };

  const onSearchOnchange = (e, { value }) => {
    setSearchKeyword(value);
    const values = { searchKeyword: value, type, status, isInActive,page };
    getCxApplicationWithFilters(values);
  };

  const onCustomApplicationTypeDropdownChange = (data) => {
    setType(data);
    const values = { searchKeyword, type: data, status, isInActive,page };
    getCxApplicationWithFilters(values);
  };

  const onCustomApplicationStatusDropdownChange = (data) => {
    setStatus(data);
    const values = { searchKeyword, type, status: data, isInActive,page };
    getCxApplicationWithFilters(values);
  };

  const onChangeToggle = () => {
    setInActive((prevIsInActive) => !prevIsInActive);
    const values = { searchKeyword, type, status, isInActive: !isInActive,page };
    getCxApplicationWithFilters(values);
  };

  useEffect(() => {
    if (!isEmpty(userId)) {
      dispatch(getOneCustomerApplication(userId));
    }
  }, [dispatch, userId]);

  const pageChange = (pageValue) => {
    setPage(pageValue);
    const values = { searchKeyword, type, status, isInActive,page:pageValue };
    getCxApplicationWithFilters(values);
  };

  const onApplicationClicked = (id,mobileClickVal) => {
    setMobileClick(mobileClickVal);
    setSelectedApplication(id);
  };
  const applications = useSelector((state) => state.customers.cxApps);
  const loading = useSelector((state) => state.customers.cxApplicationLoading);
  
  const filterObject={
    status,
    type,
    page,
    pageChange:pageChange
  }

  if (inValidUserId) return <UnAuthorizedAccess />;

  return (
    <div>
       <CustomTopSegment
        title="Applications"
        isApplication
        onSearchOnchange={onSearchOnchange}
        onCustomTypeDropdownChange={onCustomApplicationTypeDropdownChange}
        onCustomStatusDropdownChange={onCustomApplicationStatusDropdownChange}
        onChangeToggle={onChangeToggle}
        status={status}
        type={type}
        isInActive={isInActive}
        isFromAdmin
      />
       <Grid stackable style={isMobile ? { marginTop: '50px' } : {}}>
        <GridRow className="custom-row-app-home">
          {RenderApplicationPageApplications( onApplicationClicked ,
              filterObject,
              applications,
              loading,
              getCustomerApplications,
              mobileClick,
              selectedApp)}
      
        </GridRow>
      </Grid>
    </div>
  );
};

export default CustomerApplications;
