import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Icon, Radio, Segment } from 'semantic-ui-react';
import {  isEmpty } from '../utils/lodash';
import PropTypes from 'prop-types';
import './styles.scss';
const CustomSegment = (props) => {
  const {
    title,
    subTitle,
    icon,
    rightHeader,
    extraMargin,
    extraBottomMargin,
    style,
    toggle,
    headerStyle,
    noPrint,
    backButtonRoute,
    objectiveModal = false,
    borderBottomRemoved,
    subChild = {},
    className,
    disabled,
  } = props;

  return (
    <Segment.Group
      raised
      style={{
        marginTop: extraMargin ? '1.714rem' : null,
        marginBottom: extraBottomMargin ? '1.714rem' : null,
        ...style,
      }}
      className={
        className
          ? className
          : `${title.replaceAll(' ', '_').replace('-', ' ')} ${
              noPrint ? 'no-print' : ''
            }`
      }
    >
      <Segment
        className={
          borderBottomRemoved
            ? `custom-segment-without-border-bottom${
                objectiveModal ? ' objective-modal-header' : ''
              }`
            : `custom-segment${
                objectiveModal ? ' objective-modal-header' : ''
              }`
        }
        disabled={disabled ? disabled : false}
      >
        <Fragment>
          {backButtonRoute ? (
            <Link to={backButtonRoute} className="back-button-segment">
              <Icon name="arrow left" />
            </Link>
          ) : null}
            <div className={subTitle ? '' : 'segment-title'}>
              {icon ? <Icon name={icon} className="segment-icon" /> : null}
              {title}
              <br></br>

              {subTitle ? (
                <label className="segment-sub-heading" style={headerStyle}>
                  {subTitle}
                </label>
              ) : null}
            </div>
        </Fragment>
        {rightHeader ? rightHeader : null}
        {toggle ? <Radio toggle /> : null}
      </Segment>
      {!isEmpty(subChild) && <Segment>{subChild}</Segment>}
      {props.children}
    </Segment.Group>
  );
};

CustomSegment.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export { CustomSegment };
