import * as Yup from 'yup';
import { get } from '../../utils/lodash';

export const createCustomerValidation = Yup.object().shape({
  name: Yup.string().required('Please enter your name'),
  email: Yup.string()
    .email('Please enter valid email address')
    .required('Please Enter your email address.'),
  contactNo: Yup.number().required('Please enter your contact number'),
});

export const createCustomerInitialValues = (data) => {
  let form = {};
  if (data === undefined) {
    form.name = '';
    form.email = '';
    form.contactNo = '';
    form.businessName = '';
  } else {
    form.name = get(data, 'name', '');
    form.email = get(data, 'email', '');
    form.contactNo = get(data, 'contactNo', '');
    form.businessName = get(data, 'businessName', '');
  }
  return form;
};
