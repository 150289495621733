import React from 'react';
import { Grid, Image, Segment} from 'semantic-ui-react';
import EmptyApplication from '../assets/icons/application/emptyApplication.svg'
import EmptyBox from '../assets/icons/empty-folder.svg'
import './styles.scss';



const SelectApplicationContainer = ({ msg }) => {
  return (
    <Grid.Column mobile={16} tablet={16} computer={16} textAlign="center">
     <div className="empty-container-applications">
      <Segment placeholder>
    <Image src={EmptyApplication} size={'medium'}  centered/>
    <label className="msg">{msg ? msg : ''}</label>
  </Segment>
  </div> 
    </Grid.Column>
  );
};

const EmptyContainer = ({ msg,img=null,size=null }) => {
  return (
    <Grid.Column mobile={16} tablet={16} computer={16} textAlign="center">
     <div className="empty-container-applications">
      <div >
    <Image src={EmptyBox} size={'tiny'}  centered/>
    <label className="msg">{msg ? msg : ''}</label>
  </div>
  </div> 
    </Grid.Column>
  );
};


const UnAuthorizedAccess = () => {
  return (
    <Grid.Column mobile={16} tablet={16} computer={16} textAlign="center">
      <div className="no-access">
        {/* <Image src={box} size="tiny" /> */}
        <label className="msg">No Access</label>
      </div>
    </Grid.Column>
  );
};

// const SuccessContainer = ({
//   surveyData,
//   msg,
//   header,
//   success,
//   button,
//   buttonRoute,
//   linkExpired,
//   description = '/',
//   overRideBtn = false,
// }) => {
//   return (
//     <Grid.Column mobile={16} tablet={16} computer={16} textAlign="center">
//       <div className="no-goals-container">
//         {linkExpired ? (
//           <img className="public-error-icon" alt="exclamation" src={exclamation} />
//         ) : success ? (
//           <img className="public-error-icon" alt="greenCorrect" src={greenCorrect} />
//         ) : (
//           <img className="public-error-icon" alt="redClose" src={redClose} />
//         )}

//         {msg ? (
//           <div className={'details-conatiner'}>
//             {header ? [<label className="msg-header">{header}</label>, <br />] : null}
//             {msg ? <label className="msg">{msg}</label> : null}
//             {description ? <label className="desc">{description}</label> : null}
//           </div>
//         ) : (
//           <div
//             className="msg survey-message"
//             dangerouslySetInnerHTML={{
//               __html: surveyData.successMessage
//                 ? surveyData.successMessage
//                 : 'Thank you for completing the survey, your responses have been submitted.',
//             }}
//           />
//         )}
//         {!isEmpty(surveyData) &&
//         surveyData.organisationId &&
//         surveyData.organisationId.id === 1094 ? (
//           <>
//             <label className="msg">
//               Thank you for informing the cultural analysis of {surveyData.name}.
//             </label>
//             <label className="msg">
//               Here is a link to all official photos of the festival, free for you to use on the agreement that you credit the photographer’s full name. Those names are written in the base folder titles.
//             </label>
//             <label className="msg">
//               <a
//                 href="https://www.dropbox.com/sh/dmsywppzlyj6aaf/AACJEk3INZFC3OTUjNnHqxL0a?dl=0"
//                 target="_blank"
//                 rel="noopener noreferrer"
//               >
//                 ANA2020 Official Photography
//               </a>
//             </label>
//           </>
//         ) : null}

//         {(success || overRideBtn) && button ? (
//           <Button
//             type="submit"
//             className="btn btn-primary genericVerify-button"
//             onClick={() => history.push(buttonRoute)}
//           >
//             {button}
//           </Button>
//         ) : null}
//       </div>
//     </Grid.Column>
//   );
// };

export { EmptyContainer, UnAuthorizedAccess, SelectApplicationContainer };

