import React from 'react'
import {
  ModalHeader,
  ModalContent,
  ModalActions,
  Button,
  Modal,
} from 'semantic-ui-react'


const CustomModalUpdate = ({open,onDeactivate,onToggle,header="",content='',activate=false}) => {
  return (
    <>
      <Modal
        size={'tiny'}
        open={open}
      >
        <ModalHeader>{header}</ModalHeader>
        <ModalContent>
          <p>{content}</p>
        </ModalContent>
        <ModalActions>
          <Button  onClick={onToggle}>
            Cancel
          </Button>
          <Button 
            negative={!activate}
            positive={activate}
           onClick={()=>{
            onDeactivate();
            onToggle()
              }}>
            Yes
          </Button>
        </ModalActions>
      </Modal>
    </>
  )
}

export default CustomModalUpdate;
