import React from 'react';
import './styles.scss';
import { Radio } from 'semantic-ui-react';
import { useField } from 'formik';
export const CustomToggle = ({  ...props }) => {
  const [, meta, helpers] = useField({ ...props });
  const { setValue } = helpers;
  const { value } = meta;
  return (
    <div className="toggle-customer-custom">
      {props.frontLabelText && (
        <label className="text-disabled">{props.frontLabelText}</label>
      )}
      <Radio
        toggle
        onChange={(e, data) => [
          setValue(data.checked),
          // onChangeToggle(data.checked),
        ]}
        checked={value}
        name={props.name}
      />
     
      {props.rearLabelText && (
        <label className="text-disabled">{props.rearLabelText}</label>
      )}
    </div>
  );
};
