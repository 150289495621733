import React from 'react';
import { Button, Container, Grid } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { CustomInput } from '../../components/forms/inputs/customInput';
import { Formik, Form } from 'formik';
import './auth.scss';
import { forgotPasswordValidation } from '../../validations/users/forgot-password-validation';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPasswordUser } from '../../store/modules/user';
const ForgotPassword = () => {

  const dispatch=useDispatch();
  const loading=useSelector((state)=>state.user.forgotPasswordLoading)

  const onSubmit = async (values) => {
    try {
      dispatch(forgotPasswordUser(values.email))
    } catch (error) {
      console.error('Form is not submitted');
    }
  }

  return (
    <Grid.Row className="auth">
      <Grid.Column mobile={16} tablet={16} computer={8} />
      <Grid.Column mobile={16} tablet={16} computer={8}>
        <Container className="right-heading-container">
          <label className="headerText">Forgot your password?</label>
          <label className="headerDescription">We'll help you back in.</label>
          <br></br>
          <label className="headerNotification">
            Enter the email address you used when you joined and we will email
            you instructions to reset your password.
          </label>
          <Formik
            initialValues={{ email: '' }}
            validationSchema={forgotPasswordValidation}
            onSubmit={(values) => onSubmit(values)}
          >
            {({  handleSubmit, }) => (
            <Form  onSubmit={handleSubmit} className={'form-group-conatiner'}>
              <label>Email</label>
              <CustomInput
                id="email"
                name="email"
                placeholder="Enter your email"
                className="forgotpass-inputs"
              />
              <Button
                content={'Reset Password'}
                type="submit"
                className="btn btn-primary signin-button"
                loading={loading}
                disabled={loading}
              />
              <Link to={'/'}>
                <label className={'goBackSignIn'}>Go back to Sign In</label>
              </Link>
            </Form>
              )}
          </Formik>
        </Container>
      </Grid.Column>
      <Grid.Column mobile={16} tablet={5} computer={5} />
    </Grid.Row>
  );
};

export default ForgotPassword;
