import React from 'react';
import { Search } from 'semantic-ui-react';
import { debounce } from '../utils/lodash';

const CustomSearchComponent = ({className, placeholder,onSearchOnchange }) => {
  return (
    <div className='override-custom-search-bar' >
      <Search className={className} showNoResults={false} placeholder={placeholder}  onSearchChange={debounce(onSearchOnchange, 500)}/>
    </div>
  );
};

export default CustomSearchComponent;
