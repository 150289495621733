import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Label, Segment } from 'semantic-ui-react';
import CustomTable from '../../../components/customTable';
import CustomTopSegment from '../../../components/customTopSegment';
import {
  getCustomerData,
  openCustomerUpdateDashboardSidebar,
} from '../../../store/actions';
import { getAllCustomers, updateCustomer } from '../../../store/modules/customers';
import { get } from '../../../utils/lodash';
import './customers.scss';
import { useNavigate } from 'react-router-dom';
import CustomModalUpdate from '../../../components/modals/customModalUpdate';
import { isCurrentUser } from '../../../utils';

const Customers = ({ setVisible = null }) => {
  const [page, setPage] = useState(1);
  const [searchKeyword, setKeyword] = useState('');
  const dispatch = useDispatch();
  const customersData = useSelector((state) => state.customers.customers);
  const userData = useSelector((state) => state.auth.user);
  const [open, setOpen] = useState(false);
  const navigate=useNavigate();
  const customersLoading=useSelector((state) => state.customers.customersLoading);

  const [selectedCustomer,setSelectedCustomer]=useState({});
    
  useEffect(() => {
    dispatch(getAllCustomers(page));
  }, [dispatch, page]);

  const handleViewClick = (customerId) => {
    if (setVisible) {
      setVisible(true);
    }
    dispatch(openCustomerUpdateDashboardSidebar(true, customerId));
    dispatch(getCustomerData(customerId));
    if(isMobile){
      navigate('/customer-form');
    }
  };

  const pageChange = (page) => {
    dispatch(getAllCustomers(page, searchKeyword));
    setPage(page);
  };

  const onSearchOnchange = (e, { value }) => {
    setKeyword(value);
    dispatch(getAllCustomers(page, value));
  };


  const onApplicationNavigation=(count,data)=>{
    if(count>=1){
      navigate(`/applications/customer/${data.id}`)
    }
  }

 const handleDeactivateCustomer=(data)=>{
    setSelectedCustomer(data)
    setOpen(true);
 }

  const getColumns = () => {
    return [
      {
        width: 3,
        title: <label className="column-header-customers">Name</label>,
        render: (data) => {
          return (
            <div className="customers-container">
              <label className={`name-table-col1 ${data.isDisabled && 'disabled'}`}>
                {get(data, 'name', '-')}
              </label>
                {data.isDisabled ? <div className='inactive-css'><Label >In Active</Label> </div>: null}
                {data.pendingStatus ? <div className='pending-status-css'><Label >Pending</Label> </div>: null}
            </div>
          );
        },
      },
      {
        width: 3,
        title: <label className="column-header-customers">Email</label>,
        render: (data) => {
          return (
             <label className={`name-table-col1 ${data.isDisabled && 'disabled'}`}>
            {get(data, 'email', '-')}
            </label>
          );
        },
      },
      {
        width: 2,
        title: (
          <label className="column-header-customers">Contact Number</label>
        ),
        render: (data) => {
          return (
             <label className={`name-table-col1 ${data.isDisabled && 'disabled'}`}>
              {get(data, 'contactNo', '-')}
            </label>
          );
        },
      },
      {
        width: 2,
        title: <label className="column-header-customers">Applications</label>,
        render: (data) => {
          const count=get(data,'applicationCount',0);
          return (
            <label className={`${count >0 ? "name-table-col1-link " : "name-table-col1-no-data"}${data.isDisabled ? ' name-table-col1-no-data-disabled' : ' '} `} onClick={()=>onApplicationNavigation(count,data)}>
              {count > 1 ? `${count} Applications` : `${count} Application`}
            </label>
          );
        },
      },
      
      {
        width: 3,
        title: <label className="column-header-customers">Joined On</label>,
        render: (data) => {
          const date = new Date(get(data, 'createdAt', '-'));
          return (
             <label className={`name-table-col1 ${data.isDisabled && 'disabled'}`}>
              {data.pendingStatus ? '-' :format(date, 'do MMM yyyy hh:mm a')}
            </label>
          );
        },
      },
      {
        width: 1,
        title: <label className="column-header-customers">Actions</label>,
        render: (data) => {
          return (
            <div>
              <Dropdown
                icon="ellipsis vertical"
                direction={isMobile ? 'right' : 'left'}
                inline
                selectOnBlur={false}
              >
                <Dropdown.Menu>
                  <Dropdown.Item>
                    <div onClick={() => handleViewClick(data)}>Edit</div>
                  </Dropdown.Item>
                  {!isCurrentUser(userData.id,data.id) ?
                  <Dropdown.Item>
                    <div onClick={() => handleDeactivateCustomer(data)}>{data.isDisabled ? 'Activate customer' : 'Deactivate customer'}</div>
                  </Dropdown.Item>
                  : null}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          );
        },
      },
    ];
  };

  const onDeactivate=async()=>{
    const data={
      isDisabled:!selectedCustomer.isDisabled
    }
    await dispatch(updateCustomer(selectedCustomer.id, data));
  }
  
  const onToggle=()=>{
    setOpen(!open);
  }

  return (
    <div>
      <CustomModalUpdate open={open} onDeactivate={onDeactivate} onToggle={onToggle}
        header={selectedCustomer.isDisabled ? 'Activate Customer' :'Deactivate Customer'}
        content={selectedCustomer.isDisabled  ?  `Reactivate the customer - ${get(selectedCustomer,'name','customer')} ?` :
        
        
        `Are you sure want to deactivate ${get(selectedCustomer,'name','customer')} from the customer list?`}
        activate={selectedCustomer.isDisabled}
      />
   
      <CustomTopSegment
        title="Customers"
        buttonText="Create Customer"
        placeholder="Search By Name"
        onSearchOnchange={onSearchOnchange}
        isCustomer
      />
      {isMobile && <br />}
      <div className="org-table-container">
        <Segment className="content-segment-custom" loading={customersLoading}>
          <CustomTable
            header
            columns={getColumns()}
            data={customersData.data}
            customClass="org-ref-table"
            pagination
            handlePaginationChange={(page) => pageChange(page)}
            page={page}
            noOfPages={customersData.lastPage}
            keyExtractor={(item, index) => item.id}
          />
        </Segment>
      </div>
    </div>
  );
};

export default Customers;
