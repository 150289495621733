import * as Yup from 'yup';
import { find, get, isEmpty } from '../../utils/lodash';

export const VehicleImportInitialValues = {
  vinNo: '',
  estimatedDate: '',
  make: '',
  model: '',
  powerSourceType: '',
  totalNoOfSeats: '',
  notes: '',
  isMoreThan25: false,
  engineBayImages: '',
  vinImages: '',
  interiorImages: '',
  exteriorImages: '',
  exportCertificate: '',
  auctionReport: '',
  vehicleImport: '',
};

export const confirmVehicleImportValue = (responses) => {
  if (!responses) {
    return {
      vinNo: '',
      estimatedDate: null,
      make: '',
      model: '',
      powerSourceType: '',
      totalNoOfSeats: '',
      notes: '',
      isMoreThan25: false,
      engineBayImages: '',
      vinImages: '',
      interiorImages: '',
      exteriorImages: '',
      exportCertificate: '',
      auctionReport: '',
      vehicleImport: '',
    };
  }

  return {
    vinNo: responses.vinNo || '',
    estimatedDate: responses.estimatedDate || '',
    make: responses.make || '',
    model: responses.model || '',
    powerSourceType: responses.powerSourceType || '',
    totalNoOfSeats: responses.totalNoOfSeats || '',
    notes: responses.notes || '',
    isMoreThan25: responses.isMoreThan25 || false,
    engineBayImages: responses.engineBayImages || '',
    vinImages: responses.vinImages || '',
    interiorImages: responses.interiorImages || '',
    exteriorImages: responses.exteriorImages || '',
    exportCertificate: responses.exportCertificate || '',
    auctionReport: responses.auctionReport || '',
    vehicleImport: responses.vehicleImport || '',
  };
};

export const VehicleImportValidationSchema = (isVehicleOld) =>
  Yup.object({
    vinNo: Yup.string().required('Vin No is required'),
    estimatedDate: Yup.string().required('Vehicle Arrived Date is required'),
    make: Yup.string().required('Make is required'),
    model: Yup.string().required('Model is required'),
    powerSourceType: Yup.string().required('Power Source Type is required'),
    totalNoOfSeats: Yup.string().required('Total No Of Seats is required'),
    notes: Yup.string(),
    isMoreThan25: Yup.boolean(),
    engineBayImages: isVehicleOld
      ? Yup.mixed().required('Engine images are required')
      : Yup.mixed(),
    vinImages: isVehicleOld
      ? Yup.mixed().required('Vin images are required')
      : Yup.mixed(),
    interiorImages: isVehicleOld
      ? Yup.mixed().required('Interior images are required')
      : Yup.mixed(),
    exteriorImages: Yup.mixed().required('Exterior images are required'),
    exportCertificate: Yup.mixed().required('Export certificate is required'),
    auctionReport: Yup.mixed().required('Auction report is required'),
    vehicleImport: Yup.mixed().required('Vehicle import is required'),
  });

export const updateVehicleImportValidationSchema = (responses,comments=[]) => {
  const vinNo = find(responses, (item) => item.attributeId === 1);
  const estimatedDate = find(responses, (item) => item.attributeId === 2);
  const make = find(responses, (item) => item.attributeId === 3);
  const model = find(responses, (item) => item.attributeId === 4);
  const powerSourceType = find(responses, (item) => item.attributeId === 5);
  const totalNoOfSeats = find(responses, (item) => item.attributeId === 6);
  const notes = find(responses, (item) => item.attributeId === 7);
  const isMoreThan25 = find(responses, (item) => item.attributeId === 8);
  const vinImages = find(responses, (item) => item.attributeId === 9);
  const engineBayImages = find(responses, (item) => item.attributeId === 10);
  const interiorImages = find(responses, (item) => item.attributeId === 11);
  const exteriorImages = find(responses, (item) => item.attributeId === 12);
  const exportCertificate = find(responses, (item) => item.attributeId === 13);
  const auctionReport = find(responses, (item) => item.attributeId === 14);
  const vehicleImport = find(responses, (item) => item.attributeId === 15);

  return {
    vinNo: get(vinNo, 'attributeValue', ''),
    estimatedDate: get(estimatedDate, 'attributeValue', ''),
    make: get(make, 'attributeValue', ''),
    model: get(model, 'attributeValue', ''),
    powerSourceType: get(powerSourceType, 'attributeValue', ''),
    totalNoOfSeats: get(totalNoOfSeats, 'attributeValue', ''),
    notes: get(notes, 'attributeValue', ''),
    isMoreThan25: get(isMoreThan25, 'attributeValue', ''),
    vinImages: get(vinImages, 'multipleValues', ''),
    engineBayImages: get(engineBayImages, 'multipleValues', ''),
    interiorImages: get(interiorImages, 'multipleValues', ''),
    exteriorImages: get(exteriorImages, 'multipleValues', ''),
    exportCertificate: get(exportCertificate, 'multipleValues', ''),
    auctionReport: get(auctionReport, 'multipleValues', ''),
    vehicleImport: get(vehicleImport, 'multipleValues', ''),
    comments: !isEmpty(comments) ? comments[0].commentText : ''
  };
};

export default VehicleImportValidationSchema;
