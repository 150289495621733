import { Container } from 'semantic-ui-react';
import styled from 'styled-components';

const CustomContainer = styled(Container)`
  width: auto;
  height: 510px;
  padding: 20px;
  flex-shrink: 0;
  border-radius: 4px;
  border: 0.5px solid #d6d6d6;
  background: #fff;
  box-shadow: 0px 2px 6px 0px rgba(210, 210, 210, 0.5);

  @media (max-width: 768px) {
    height: auto !important;
  }
`;

export default CustomContainer;
