import { Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Container, Form, Grid, Icon } from 'semantic-ui-react';
import { CustomInput } from '../../components/forms/inputs/customInput';
import { scrollToFirstErrorField } from '../../components/forms/utils/scrollToFirstErrorField';
import { loginUser } from '../../store/actions';
import {
  createErrorObject,
  signInInitialValues,
  validationSchema,
} from '../../validations/customers/signIn-validation';
import './auth.scss';
import { userAuth } from '../../utils';

export const SignIn = (props) => {
  let navigate = useNavigate();
  const [errorHandling, setErrorHandling] = useState(null);
  const auth = useSelector((state) => state.auth);
  const [isShownPass, setIsShownPass] = React.useState(false);
  const dispatch = useDispatch();

  const onSubmit = async (values) => {
    try {
      await dispatch(
        loginUser({
          email: values.email,
          password: values.password,
        })
      );
      userAuth.authenticate(() => {});
      navigate('/dashboard');
    } catch (err) {
      console.error('Error in login', err);
      setErrorHandling(err);
    }
  };

  return (
    <Grid.Row className={'signin-page-wrapper'}>
      <Container className="right-heading-container">
        <label className="headerText">Sign in to your Dashboard!</label>
        <label className="headerDescription">
          Welcome back! Please enter your details
        </label>
        <br></br>

        {errorHandling ? (
          <label className={'errorBox'}>
            <label className={'errorBoxContent'}>{errorHandling}</label>
          </label>
        ) : null}

        {auth.resetPasswordSuccess && (
          <label className={'successBox'}>
            <label className={'successBoxContent'}>
              Account created Successfully!
            </label>
          </label>
        )}
        <Formik
          onSubmit={(values) => {
            onSubmit(values);
          }}
          initialValues={signInInitialValues}
          validationSchema={validationSchema}
        >
          {({ handleSubmit, errors, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              {scrollToFirstErrorField(createErrorObject(errors), isSubmitting)}
              <CustomInput
                id="email"
                name="email"
                placeholder="Email"
                labelText="Email"
                type="text"
                // disabled={userLoginLoading}
              />
              <CustomInput
                id="password"
                name="password"
                placeholder="Password"
                labelText="Password"
                // disabled={userLoginLoading}
                type={isShownPass ? 'text' : 'password'}
                icon={
                  <Icon
                    name={isShownPass ? 'eye' : 'eye slash'}
                    onClick={() => {
                      setIsShownPass(!isShownPass);
                    }}
                    link
                  />
                }
              />
              <Link className="forgot" to="/forgot-password">
                Forgot password?
              </Link>{' '}
              <Button
                loading={auth.userLoading}
                disabled={auth.userLoading}
                content={'  Sign In'}
                type="submit"
                className="btn btn-primary signin-button"
                // onClick={()=> navigate(`/dashboard`)}
              />
            </Form>
          )}
        </Formik>

        {/* </div> */}
      </Container>
    </Grid.Row>
  );
};
