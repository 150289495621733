/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useField } from 'formik';
import { Dropdown } from 'semantic-ui-react';

export const CustomDropdown = ({
  labelText,
  getValue,
  requiredStar,
  className = null,
  disabled,
  options,
  ...props
}) => {
  const [, meta, helpers] = useField({ ...props });
  const { value } = meta;
  const { setValue } = helpers;
  return (
    <div className="formik-custom-field act-inputs-new">
      <label
        className={requiredStar ? 'required-stars' : ''}
        htmlFor={props.id || props.name}
      >
        {labelText}
      </label>
      <>
        <Dropdown
          selection
          scrolling
          options={options}
          onChange={(e, data) => {
            setValue(data.value);
            getValue && getValue(data.value);
          }}
          error={meta.touched && meta.error}
          className={className ? className : ''}
          disabled={disabled}
          value={value}
          {...props}
        />
      </>
      {/* )} */}
      {meta.touched && meta.error && (
        <div className="invalid-feedback">{meta.error}</div>
      )}{' '}
    </div>
  );
};
