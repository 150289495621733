import React, {useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {

  Icon,
  Menu,
  Segment,
  Sidebar,
  SidebarPushable,
  SidebarPusher,
} from 'semantic-ui-react';
import '../../components/styles.scss';
import {
  openCustomerSideBar,
  openCustomerUpdateDashboardSidebar,
} from '../../store/modules/clientSide';
import './sideBar.scss';
import { CustomerForm } from '../admin/customers/customerForm';

const SideBar = ({ component: Component }) => {
  const dispatch = useDispatch();
  const [sideBar, setSideBar] = useState(false);
  const [shouldValidate, setShouldValidate] = useState(true);

  const closeSideBar = () => {
    setShouldValidate(false);
    setSideBar(false);
    dispatch(openCustomerSideBar(false));
    dispatch(openCustomerUpdateDashboardSidebar(false));
  };

  const updateSelectedCustomer = useSelector(
    (state) => state.clientSide.isOpenCustomerUpdateDashboardSidebar
    );

  const setSideBarFunction=(val,la)=>{
    setSideBar(val);
  }

  return (
    <SidebarPushable>
      <Sidebar
        as={Menu}
        animation="overlay"
        icon="labeled"
        inverted
        vertical
        visible={sideBar}
        width="wide"
        direction="right"
        style={{ backgroundColor: '#ffffff' }}
        className="custom-sidebar"
      >
        <div className="advance-filter">
          <div className="filter-header">
            <label className="filter-text">
              {updateSelectedCustomer ? 'Update Customer' : 'Create Customer'}
            </label>
            <div className="filter-close">
              <Icon
                name="close"
                onClick={() => closeSideBar()}
                className="info-close"
              />
            </div>
          </div>
        </div>
        <CustomerForm
            shouldValidate={shouldValidate}
            updateSelectedCustomer={updateSelectedCustomer}
            sideBar={sideBar}
            closeSideBar={closeSideBar}
            setSideBarFunction={setSideBarFunction}
        />
      </Sidebar>
      <SidebarPusher dimmed={sideBar}>
        <Segment basic> {Component}</Segment>
      </SidebarPusher>
    </SidebarPushable>
  );
};

export default SideBar;
