import React from 'react';
import { Button, Grid, GridColumn, GridRow, Icon } from 'semantic-ui-react';
import { ADMIN_ROLE, STATUS } from '../../../utils/constant';

export const FooterAdmin = ({
  user = {},
  selectedApp = {},
  handleRemoveClick = null,
  removeLoading = false,
  onDownloadAllFiles = null,
  isDownloading = false,
  onClickStatusUpdate = null,
}) => {
  return (
    <Grid>
      <GridRow>
      <GridColumn computer={10} mobile={8} tablet={6} widescreen={10}>
          <div className="remove-button">
            {user.roleId === ADMIN_ROLE && !selectedApp.isInActive && (
              <>
                <Button
                  basic
                  color="red"
                  onClick={() => handleRemoveClick()}
                  disabled={removeLoading}
                  className="remove-button-text"
                >
                  Remove this Application
                </Button>

                <Button
                  basic
                  icon
                  color="red"
                  onClick={() => handleRemoveClick()}
                  disabled={removeLoading}
                  className="remove-button-icon"
                >
                  <Icon name="trash alternate" />
                </Button>
              </>
            )}
          </div>
        </GridColumn>
        <GridColumn computer={6} mobile={8} tablet={10}>
          <Grid>
            <GridRow>
              <GridColumn computer={8} mobile={3} tablet={8} style={{ padding: '0px' }}>
                <div className="download-button">
                  <Button
                    basic
                    onClick={onDownloadAllFiles}
                    disabled={isDownloading}
                    className="download-all-button"
                  >
                    {isDownloading ? 'Downloading...' : 'Download All Files'}
                  </Button>

                  <Button
                    basic
                    onClick={onDownloadAllFiles}
                    disabled={isDownloading}
                    className="download-icon-button"
                  >
                    <Icon name="download" />
                  </Button>
                </div>
              </GridColumn>
              <GridColumn computer={8} mobile={4} tablet={8} style={{ padding: '0px' }}>
                <div className="download-button">
                  <Button
                    basic
                    onClick={() => onClickStatusUpdate(STATUS.LODGED)}
                  >
                    Mark as Lodged
                  </Button>
                </div>
              </GridColumn>
            </GridRow>
          </Grid>
        </GridColumn>
      </GridRow>
    </Grid>
  );
};

export const FooterCustomer = ({
  onDownloadAllFiles = null,
  isDownloading = false,
}) => {
  return (
    <Grid stackable>
      <GridRow>
        <GridColumn width={6}></GridColumn>
        <GridColumn width={10}>
          <Grid>
            <GridRow>
              <GridColumn computer={6} mobile={4}  style={{ padding: '0px' }}></GridColumn>
              <GridColumn computer={5} mobile={5}   style={{ padding: '0px' }}></GridColumn>
              <GridColumn computer={5} mobile={6}   style={{ padding: '0px' }}>
                <div className="download-button">
                  <Button
                    basic
                    onClick={onDownloadAllFiles}
                    disabled={isDownloading}
                  >
                    {isDownloading ? 'Downloading...' : 'Download All Files'}
                  </Button>
                </div>
              </GridColumn>
            </GridRow>
          </Grid>
        </GridColumn>
      </GridRow>
    </Grid>
  );
};
