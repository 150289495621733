import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { useField } from 'formik';
import dayjs from 'dayjs';
const CustomDateComponent  = (props) =>  {
  const [, meta, helpers] = useField({ ...props });
  // const { value } = meta;
  const { setValue } = helpers;
  return (
    <div className="date-picker">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DatePicker']}>
          <DatePicker
            label="Date"
            value={props.value}
            onChange={(e) => {
              const formattedDate = dayjs(e).format('YYYY-MM-DD HH:mm:ss');
              props.onChange(formattedDate);
              setValue(formattedDate);
            }}
            format="DD-MM-YYYY"
            disabled={props.disabled}
            slotProps={{ textField: { size: 'small' } }}
          />
        </DemoContainer>
      </LocalizationProvider>
      {meta.touched && meta.error && (
        <div className="invalid-feedback">{meta.error}</div>
      )}{' '}
    </div>
  );
};

export default CustomDateComponent;
