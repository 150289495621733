/*** TYPES ***/
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { authMsg, getCommonError } from '../../utils';
import { PAGE_LIMIT, VEHICLE_IMPORT_ID } from '../../utils/constant';
const GET_ALL_APPLICATION = 'GET_ALL_APPLICATION';
const VEHICLE_POST_LOADING = 'VEHICLE_POST_LOADING';
const VEHICLE_PUT_LOADING = 'VEHICLE_PUT_LOADING';
const VEHICLE_DELETE_LOADING = 'VEHICLE_DELETE_LOADING';
const LOADING_GET_APPLICATIONS = 'LOADING_GET_APPLICATIONS';
const SET_ALL_VEHICLE_LOADING = 'SET_ALL_VEHICLE_LOADING';
const SET_ALL_VEHICLE_LIST = 'SET_ALL_VEHICLE_LIST';
const LOADING_APPLICATION_STATS = 'LOADING_APPLICATION_STATS';
const APPLICATION_STATS = 'APPLICATION_STATS';
const LOADING_GET_CX_APPLICATIONS = 'LOADING_GET_CX_APPLICATIONS';
const COMPLIANCE_POST_LOADING = 'COMPLIANCE_POST_LOADING';
const COMPLIANCE_POST = 'COMPLIANCE_POST';
const CX_APPLICATIONS = 'CX_APPLICATIONS';
const VEHICLE_IMPORT_POST = 'VEHICLE_IMPORT_POST';

const DOWNLOAD_ALL_APPLICATION_FILES = 'DOWNLOAD_ALL_APPLICATION_FILES';
const DOWNLOADED_DATA = 'DOWNLOADED_DATA';
const APPLICATION_DISABLED_LOADING = 'APPLICATION_DISABLED_LOADING';
const GET_APPLICATION_LOADING = 'GET_APPLICATION_LOADING';
const GET_APPLICATION_BY_ID = 'GET_APPLICATION_BY_ID';
const GET_ONE_APP_LOADING = 'GET_ONE_APP_LOADING';
const ONE_APP_DATA = 'ONE_APP_DATA';

/*** REDUCERS ***/
const initialState = {
  allApplications: [],
  vehicleImport: {},
  applicationLoading: false,
  vehiclesLoading: false,
  applicationPatch: {},
  vehiclePostLoading: false,
  vehicleSearch: [],
  vehicleAllLoading: false,
  applicationStats: {},
  applicationStatsLoading: false,
  compliance: {},
  compliancePostLoading: false,
  cxApplicationLoading: false,
  cxApplications: {},
  vehiclePost: {},
  downloadLoading: false,
  downloadedFiles: {},
  disableApplicationLoading: false,
  getApplicationById: {},
  getApplicationLoading: false,
  oneAppLoading: false,
  oneAppData: {},
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_APPLICATION:
      return { ...state, allApplications: action.payload };
    case LOADING_GET_APPLICATIONS:
      return { ...state, applicationLoading: action.payload };
    case GET_APPLICATION_BY_ID:
      return { ...state, getApplicationById: action.payload };
    case GET_APPLICATION_LOADING:
      return { ...state, getApplicationLoading: action.payload };
    case SET_ALL_VEHICLE_LOADING:
      return { ...state, vehicleAllLoading: action.payload };
    case VEHICLE_DELETE_LOADING:
      return { ...state, vehiclesLoading: action.payload };
    case VEHICLE_PUT_LOADING:
      return {
        ...state,
        applicationPatch: {
          [action.payload.type]: action.payload.status,
        },
      };
    case VEHICLE_POST_LOADING:
      return { ...state, vehiclePostLoading: action.payload };
    case SET_ALL_VEHICLE_LIST:
      return { ...state, allUserList: action.payload };
    case LOADING_APPLICATION_STATS:
      return { ...state, applicationStatsLoading: action.payload };
    case APPLICATION_STATS:
      return { ...state, applicationStats: action.payload };

    case COMPLIANCE_POST_LOADING:
      return { ...state, compliancePostLoading: action.payload };
    case COMPLIANCE_POST:
      return { ...state, compliance: action.payload };
    case VEHICLE_IMPORT_POST:
      return { ...state, vehiclePost: action.payload };
    case LOADING_GET_CX_APPLICATIONS:
      return { ...state, cxApplicationLoading: action.payload };
    case CX_APPLICATIONS:
      return { ...state, cxApplications: action.payload };

    case DOWNLOAD_ALL_APPLICATION_FILES:
      return { ...state, downloadLoading: action.payload };
    case DOWNLOADED_DATA:
      return { ...state, downloadedFiles: action.payload };
    case APPLICATION_DISABLED_LOADING:
      return { ...state, disableApplicationLoading: action.payload };
    case GET_ONE_APP_LOADING:
      return { ...state, oneAppLoading: action.payload };

    case ONE_APP_DATA:
      return { ...state, oneAppData: action.payload };

    default:
      return state;
  }
}

export function getApplications(
  searchKeyword = '',
  type = '',
  status = '',
  isInActive = false,
  page,
  isCustomer
) {
  return async (dispatch, getState) => {
    if (isCustomer) {
      dispatch(
        getCustomerApplications(searchKeyword, type, status, isInActive, page)
      );
    } else {
      dispatch(
        getAllApplications(searchKeyword, type, status, isInActive, page)
      );
    }
  };
}

export function getAllApplications(
  searchKeyword = '',
  type = 'all',
  status = 'all',
  isInActive = false,
  page = 1
) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: LOADING_GET_APPLICATIONS, payload: true });
      const response = await axios.get(`/application/all`, {
        params: {
          searchKeyword,
          status,
          type,
          isInActive: Boolean(isInActive),
          limit: PAGE_LIMIT,
          page,
        },
      });
      dispatch({ type: GET_ALL_APPLICATION, payload: response.data });
      dispatch({ type: LOADING_GET_APPLICATIONS, payload: false });
    } catch (error) {
      NotificationManager.error(getCommonError(error));
      dispatch({ type: LOADING_GET_APPLICATIONS, payload: false });
      console.error('Error in fetching all applications ,', error);
    }
  };
}

export function getCustomerApplications(
  searchKeyword = '',
  type = 'all',
  status = 'all',
  isInActive = false,
  page = 1
) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: LOADING_GET_CX_APPLICATIONS, payload: true });

      const response = await axios.get(`/application/customer/forms`, {
        params: {
          searchKeyword,
          status,
          type,
          isInActive,
          page,
          limit: PAGE_LIMIT,
        },
      });
      dispatch({ type: CX_APPLICATIONS, payload: response.data });
      dispatch({ type: LOADING_GET_CX_APPLICATIONS, payload: false });
    } catch (error) {
      dispatch({ type: LOADING_GET_CX_APPLICATIONS, payload: false });
      NotificationManager.error(getCommonError(error));
      console.error('Error in fetching all cx forms ,', error);
    }
  };
}

export function postVehicleImport(data) {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: VEHICLE_POST_LOADING, payload: true });
        const response = await axios.post(`/application/vehicle-import`, data);
        dispatch({ type: VEHICLE_IMPORT_POST, payload: response.data });
        resolve(response.data);
        authMsg('success', ['Added Successfully!', 'Vehicle Import']);
        dispatch({ type: VEHICLE_POST_LOADING, payload: false });
      } catch (error) {
        console.error('error: ', error);
        dispatch({ type: VEHICLE_POST_LOADING, payload: false });
        NotificationManager.error(getCommonError(error));
        reject(getCommonError(error));
      }
    });
  };
}

export function patchVehicleImport(id, data, applicationType) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: VEHICLE_PUT_LOADING,
        payload: {
          type:
            applicationType === VEHICLE_IMPORT_ID
              ? 'vehicleImportStatus'
              : 'complianceStatus',
          status: true,
        },
      });
      await axios.patch(`application/vehicle-import/${id}`, data);
      dispatch({
        type: VEHICLE_PUT_LOADING,
        payload: {
          type:
            applicationType === VEHICLE_IMPORT_ID
              ? 'vehicleImportStatus'
              : 'complianceStatus',
          status: false,
        },
      });
      dispatch(getAllApplications());
    } catch (error) {
      dispatch({
        type: VEHICLE_PUT_LOADING,
        payload: {
          type:
            applicationType === VEHICLE_IMPORT_ID
              ? 'vehicleImportStatus'
              : 'complianceStatus',
          status: false,
        },
      });
    }
  };
}

export function patchComplianceApplication(id, data, applicationType) {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: VEHICLE_PUT_LOADING,
        payload: {
          type:
            applicationType === VEHICLE_IMPORT_ID
              ? 'vehicleImportStatus'
              : 'complianceStatus',
          status: true,
        },
      });
      await axios.patch(`application/compliance/${id}`, data);
      dispatch({
        type: VEHICLE_PUT_LOADING,
        payload: {
          type:
            applicationType === VEHICLE_IMPORT_ID
              ? 'vehicleImportStatus'
              : 'complianceStatus',
          status: false,
        },
      });
      dispatch(getAllApplications());
    } catch (error) {
      dispatch({
        type: VEHICLE_PUT_LOADING,
        payload: {
          type:
            applicationType === VEHICLE_IMPORT_ID
              ? 'vehicleImportStatus'
              : 'complianceStatus',
          status: false,
        },
      });
    }
  };
}

export function patchApplicationActiveStatus(id) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: APPLICATION_DISABLED_LOADING, payload: true });
      await axios.patch(`application/disable/${id}`);
      dispatch({ type: APPLICATION_DISABLED_LOADING, payload: false });
      dispatch(getAllApplications());
      authMsg('success', ['inactivated successfully', 'Application Status']);
    } catch (error) {
      dispatch({ type: APPLICATION_DISABLED_LOADING, payload: false });
      NotificationManager.error(getCommonError(error));
    }
  };
}

export function deleteVehicleImport(id) {
  return async (dispatch, getState) => {
    try {
      await axios.delete(`/vehicle-import/${id}`);
      dispatch({ type: VEHICLE_DELETE_LOADING, payload: id });
    } catch (error) {
      dispatch({ type: VEHICLE_DELETE_LOADING, payload: false });
    }
  };
}

export function postComplianceApplication(data) {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: COMPLIANCE_POST_LOADING, payload: true });
        const response = await axios.post(`/application/compliance`, data);
        dispatch({ type: COMPLIANCE_POST, payload: response.data });
        resolve(response.data);
        authMsg('success', ['Added Successfully!', 'Compliance']);
        dispatch({ type: COMPLIANCE_POST_LOADING, payload: false });
      } catch (error) {
        dispatch({ type: COMPLIANCE_POST_LOADING, payload: false });
        NotificationManager.error(getCommonError(error));
        reject(getCommonError(error));
      }
    });
  };
}

export function getAllVehicleImport(page = 1, search, limit = 15) {
  return async (dispatch) => {
    try {
      let order = 'asc';
      dispatch({ type: SET_ALL_VEHICLE_LOADING, payload: true });
      const params = {
        limit,
        page,
        order,
      };
      if (search) {
        params.keyword = search.keyword;
      }
      const response = await axios.get(`/vehicle-import`, {
        params,
      });
      dispatch({ type: SET_ALL_VEHICLE_LIST, payload: response.data });
      dispatch({ type: SET_ALL_VEHICLE_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: SET_ALL_VEHICLE_LOADING, payload: false });
    }
  };
}

export function getApplicationStats(fromDate, toDate) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: LOADING_APPLICATION_STATS, payload: true });
      const response = await axios.get(`/application-stats`, {
        params: {
          fromDate,
          toDate,
        },
      });
      dispatch({ type: APPLICATION_STATS, payload: response.data });
      dispatch({ type: LOADING_APPLICATION_STATS, payload: false });
    } catch (error) {
      NotificationManager.error(getCommonError(error));
      dispatch({ type: LOADING_APPLICATION_STATS, payload: false });
    }
  };
}

export function getOneApplication(appId) {
  return async (dispatch, getState) => {
    try {
      dispatch({ type: GET_ONE_APP_LOADING, payload: true });
      const response = await axios.get(`/application/${appId}`);
      dispatch({ type: ONE_APP_DATA, payload: response.data });
      dispatch({ type: GET_ONE_APP_LOADING, payload: false });
    } catch (error) {
      NotificationManager.error(getCommonError(error));
      dispatch({ type: GET_ONE_APP_LOADING, payload: false });
    }
  };
}

export function downloadAllTheFiles(id, formTypeId) {
  return async (dispatch, getState) => {
    return new Promise(async (resolve, reject) => {
      try {
        dispatch({ type: DOWNLOAD_ALL_APPLICATION_FILES, payload: true });
        const response = await axios.get(
          `application/download/${id}`,
          {
            params: {
              formTypeId,
            },
            responseType: 'blob',
          }
        );

        //auto download
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        a.href = url;
        a.download = 'download.zip';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        dispatch({ type: DOWNLOADED_DATA, payload: response.data });
        resolve(response.data);
        dispatch({ type: DOWNLOAD_ALL_APPLICATION_FILES, payload: false });
      } catch (error) {
        dispatch({ type: DOWNLOAD_ALL_APPLICATION_FILES, payload: false });
        NotificationManager.error(getCommonError(error));
        reject(getCommonError(error));
      }
    });
  };
}
