import axios from 'axios';

/*** TYPES ***/
const GET_ALL_NOTIFICATIONS = 'GET_ALL_NOTIFICATIONS';
const GET_NOTIFICATIONS_LOADING = 'GET_NOTIFICATIONS_LOADING';
const UPDATE_NOTIFICATION_IS_READ = 'UPDATE_NOTIFICATION_IS_READ';
const UPDATE_NOTIFICATION_IS_READ_ALL = 'UPDATE_NOTIFICATION_IS_READ_ALL';

/*** REDUCERS ***/
const initialState = {
  notifications: [],
  notificationsLoading: false,
  notificationIsReadLoading:false,
  notificationAllIsReadLoading:false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_NOTIFICATIONS_LOADING:
      return { ...state, notificationsLoading: action.payload };
    case GET_ALL_NOTIFICATIONS:
      return { ...state, notifications: action.payload };

    case UPDATE_NOTIFICATION_IS_READ:
      return { ...state, notificationIsReadLoading: action.payload };
      case UPDATE_NOTIFICATION_IS_READ_ALL:
      return { ...state, notificationAllIsReadLoading: action.payload }; 
    default:
      return state;
  }
}

export function getAllNotifications() {
  return async (dispatch) => {
    dispatch({ type: GET_NOTIFICATIONS_LOADING, payload: true });
    try {
      let params = {
        limit: 20,
      };
      const res = await axios.get(`/notification/all`, {
        params,
      });
      dispatch({ type: GET_ALL_NOTIFICATIONS, payload: res.data });
      dispatch({ type: GET_NOTIFICATIONS_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: GET_NOTIFICATIONS_LOADING, payload: false });
    }
  };
}

export function updateNotificationIsReadAll() {
  return async (dispatch) => {
    dispatch({ type: UPDATE_NOTIFICATION_IS_READ_ALL, payload: true });
    try {
      await axios.put(`/notification/read-all`)
      dispatch({ type: UPDATE_NOTIFICATION_IS_READ_ALL, payload: false });
    } catch (error) {
      dispatch({ type: UPDATE_NOTIFICATION_IS_READ_ALL, payload: false });
    }
  };
}

export function updateNotificationIsRead(id) {
  return async (dispatch) => {
    dispatch({ type: UPDATE_NOTIFICATION_IS_READ, payload: true });
    try {
      await axios.patch(`/notification/${id}`);
      dispatch({ type: UPDATE_NOTIFICATION_IS_READ, payload: false });
    } catch (error) {
      dispatch({ type: UPDATE_NOTIFICATION_IS_READ, payload: false });
    }
  };
}