import React from 'react';
import { Pagination } from 'semantic-ui-react';

const CustomPagination = ({
  page,
  totalPages,
  handlePaginationChange,
  className,
}) => (
  <Pagination
    className={className}
    activePage={page}
    size="tiny"
    page={page}
    onPageChange={(e, { activePage }) =>
      handlePaginationChange ? handlePaginationChange(activePage) : false
    }
    totalPages={totalPages}
    pointing
    secondary
    firstItem={null}
    lastItem={null}
    siblingRange={1}
  />
);

export default CustomPagination;
