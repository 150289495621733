import { get } from "../../utils/lodash";
import * as Yup from 'yup';

export const settingsInitialUserData= userDetails=> {
    return {
      name: get(userDetails,'name',''),
      email: get(userDetails,'email',''),
      contactNo: get(userDetails,'contactNo',''),
      abn:get(userDetails,'abn','1'),
      address:get(userDetails,'address',''),
      isUnsubscribed:get(userDetails,'isUnsubscribed',''),
    };
  };


export const userSettingsValidationSchema = Yup.object().shape({
  address: Yup.string().required('Please enter your password'),
});