import moment from 'moment';
import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, GridColumn, GridRow } from 'semantic-ui-react';
import ApplicationLogo from '../../../assets/icons/dashboard/applicationLogo.svg';
import CustomersLogo from '../../../assets/icons/dashboard/customersLogo.svg';
import HiLogo from '../../../assets/icons/dashboard/hiLogo.svg';
import CardBoxComponent from '../../../components/cardBoxComponent';
import { getApplicationStats } from '../../../store/modules/applications';
import { getCustomerStats } from '../../../store/modules/customers';
import { find, isEmpty } from '../../../utils/lodash';
import { isMobile } from 'react-device-detect';
import { get } from '../../../utils/lodash';
import './dashboard.scss';
import { getMsg } from '../../../utils';
const Widgets = (props) => {
  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.auth.user);
  const isAdmin =
    userDetails !== undefined && userDetails.roleId === 1 ? true : false;

  useEffect(() => {
    if (isAdmin) {
      const startDate = moment().startOf('week').format('YYYY-MM-DD HH:mm:ss');
      const endDate = moment().endOf('week').format('YYYY-MM-DD HH:mm:ss');

      const promises = [
        dispatch(getCustomerStats(startDate, endDate)),
        dispatch(getApplicationStats(startDate, endDate)),
      ];

      Promise.all(promises);
    }
  }, [dispatch, isAdmin]);

  const appStats = useSelector((state) => state.applications);
  const customerStats = useSelector((state) => state.customers.customerStats);
  const customerStatsLoading = useSelector(
    (state) => state.customers.customerStatsLoading
  );

  const getValues = useCallback(() => {
    const submittedValue = find(
      appStats.applicationStats,
      (item) => item.status === 'SUBMITTED'
    );
    const lodged = find(
      appStats.applicationStats,
      (item) => item.status === 'LODGED'
    );
    const drafted = find(
      appStats.applicationStats,
      (item) => item.status === 'DRAFTED'
    );
    return {
      submittedValue: submittedValue !== undefined ? submittedValue.count : 0,
      lodged: lodged !== undefined ? lodged.count : 0,
      drafted: drafted !== undefined ? drafted.count : 0,
    };
  }, [appStats]);

  const getDateValues = (values, data) => {
    dispatch(getApplicationStats(data.startDate, data.endDate));
  };

  const getUserDateValues = (values, data) => {
    dispatch(getCustomerStats(data.startDate, data.endDate));
  };

  const getCustomersCount = useCallback(() => {
    return isEmpty(customerStats) ? 0 : customerStats.count;
  }, [customerStats]);

  useEffect(() => {
    if (appStats.applicationStats) {
      getValues();
    }
  }, [getValues, appStats.applicationStats]);

  useEffect(() => {
    if (!isEmpty(customerStats)) {
      getCustomersCount();
    }
  }, [getCustomersCount, customerStats]);

  const widget1 = [
    {
      title: 'Date',
      value: moment().format('Do   MMM YYYY'),
      value2: '',
    },
    {
      title: 'Time',
      value: moment().format('LT'),
    },
  ];

  const widget2 = [
    {
      title: 'Submitted',
      value: getValues().submittedValue,
      // value2: '+0.12%',
    },
    {
      title: 'Lodged',
      value: getValues().lodged,
      // value2: '+0.12%',
    },
  ];

  const widget3 = [
    {
      title: 'Received',
      value: getCustomersCount(),
      // value2: '+0.12%',
    },
  ];

  return (
    <>
      {!isMobile ? (
        <div>
          <Grid>
            <GridRow
              style={{ display: 'flex', justifyContent: 'space-between'}}
            >
              <GridColumn
                computer={5}
                mobile={16}
                tablet={8}
              >
                <div className="welcome-controller">
                  <CardBoxComponent
                    {...props}
                    isFilter={false}
                    widgetData={widget1}
                    logo={HiLogo}
                  />
                </div>
              </GridColumn>
              <GridColumn
               computer={5}
               mobile={16}
               tablet={8}
              >
                <div className="application-controller">
                  <CardBoxComponent
                    isFilter={true}
                    title={'Application'}
                    {...props}
                    widgetData={widget2}
                    logo={ApplicationLogo}
                    getDateValues={(e, data) => getDateValues(e, data)}
                    loading={appStats.applicationStatsLoading}
                  />
                </div>
              </GridColumn>
              <GridColumn
              computer={5}
              mobile={16}
              tablet={8}
              >
                <div className="customer-controller">
                  <CardBoxComponent
                    isFilter={true}
                    {...props}
                    title={'Customers'}
                    widgetData={widget3}
                    isAddNewButton={true}
                    logo={CustomersLogo}
                    getDateValues={(e, data) => getUserDateValues(e, data)}
                    loading={customerStatsLoading}
                  />
                </div>
              </GridColumn>
            </GridRow>
          </Grid>
        </div>
      ) : (
        <div>
          <Grid>
            <GridRow>
              <GridColumn 
              width={2}
                mobile={16}
                tablet={16}
              >
                <label className="card-header-text">{getMsg()}</label>
                <br />
                <label className="card-header-sub-text">
                  Welcome back {get(userDetails, 'name', '')}!
                </label>
              </GridColumn>
            </GridRow>
            <GridRow>
                <GridColumn   
                  mobile={16}
                  tablet={8}
                >
                  <div className="application-controller">
                    <CardBoxComponent
                      isFilter={true}
                      title={'Application'}
                      {...props}
                      widgetData={widget2}
                      logo={ApplicationLogo}
                      getDateValues={(e, data) => getDateValues(e, data)}
                      loading={appStats.applicationStatsLoading}
                    />
                  </div>
                </GridColumn>
                <GridColumn
                mobile={16}
                tablet={8}
                >
                  <div className="customer-controller">
                    <CardBoxComponent
                      isFilter={true}
                      {...props}
                      title={'Customers'}
                      widgetData={widget3}
                      isAddNewButton={false}
                      logo={CustomersLogo}
                      getDateValues={(e, data) => getUserDateValues(e, data)}
                      loading={customerStatsLoading}
                    />
                  </div>
                </GridColumn>
            </GridRow>
          </Grid>
        </div>
      )}
    </>
  );
};

export default Widgets;
