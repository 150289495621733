import React from 'react';
import { Grid, GridColumn, GridRow } from 'semantic-ui-react';

import Widgets from './widgets';
import { useSelector } from 'react-redux';
import { SelectApplicationContainer } from '../../../components/emptyContainer';
import { getAllApplications } from '../../../store/actions';
import { isEmpty } from '../../../utils/lodash';
import { RenderDashboardApplicationsView } from '../../../components/ApplicationView';
const AdminLayout = (props) => {
  const loading = useSelector((state) => state.applications.applicationLoading);
  const applications = useSelector(
    (state) => state.applications.allApplications
  );
  const renderEmptyLayout = () => {
    return (
      <SelectApplicationContainer
        classes={`no-data`}
        msg={'At the moment, No applications to display!'}
      />
    );
  };

  return (
    <Grid stackable className="dashboard-parent-component-css">
      <GridRow>
        <label className="main-title">Dashboard</label>
      </GridRow>
      <GridRow>
        <GridColumn width={16}>
          <Widgets {...props} userDetails={props.userDetails} />
        </GridColumn>
      </GridRow>
      <GridRow>
        {isEmpty(applications.data)
          ? renderEmptyLayout()
          : RenderDashboardApplicationsView(
              loading,
              applications,
              props.onApplicationClicked,
              getAllApplications,
              props.mobileClick,
              props.selectedApp,
              props.applicationViewLoading,
            )}
      </GridRow>
    </Grid>
  );
};

export default AdminLayout;
